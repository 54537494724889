import { MatDateFormats } from '@angular/material/core';

/**
 * The format configuration is implemented for the moment.js format structure.
 */
export const MY_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: ['l'],
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

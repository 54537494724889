import { NgModule, Optional, SkipSelf } from '@angular/core';
import { ContentService } from './services/content/content.service';
import { KeepAliveService } from './services/keep-alive/keep-alive.service';
import { PatientService } from './services/patient/patient.service';
import { MessagingService } from './services/messaging/messaging.service';

import { PatientGoalService } from './services/patient-goal/patient-goal.service';
import { ProgressResolverService } from './resolvers/progress.resolver';
import { TherapyResolverService } from './resolvers/therapy.resolver';
import { QuestionnaireResolverService } from './resolvers/questionnaire.resolver';
import { SettingsResolverService } from './resolvers/settings.resolver';
import { PatientStepsService } from './services/patient-steps/patient-steps.service';

@NgModule({
  providers: [
    MessagingService,
    ContentService,
    ProgressResolverService,
    TherapyResolverService,
    QuestionnaireResolverService,
    KeepAliveService,
    PatientService,
    PatientGoalService,
    PatientStepsService,
    SettingsResolverService,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    if (parentModule) {
      throw new Error(
        `${CoreModule.name} is already loaded. Import it in the AppModule only`
      );
    }
  }
}

<div class="container-tree">
  <img
    class="leaf{{ level }}"
    src="../assets/backgrounds/leafg{{ level - 1 }}.svg"
    alt=""
  />
  <button mat-stroked-button class="mdnra-button">
    <img class="leaf" src="../assets/backgrounds/one-leaf-gray.svg" alt="" />
    {{ leaves }}
    <i class="fas fa-info-circle"></i>
  </button>
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'mdnra-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss'],
})
export class ImprintComponent {
  @Input() popUp: boolean = false;
  @Output() imprintEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {}

  goBack(isPopup: boolean) {
    if (isPopup) {
      this.imprintEvent.emit(false);
    } else {
      this.router.navigate(['..'], { relativeTo: this.route });
    }
  }
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'mdnra-countdown-timer-modal',
  templateUrl: './countdown-timer-modal.component.html',
  styleUrls: ['./countdown-timer-modal.component.scss'],
})
export class CountdownTimerModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { title: string; timer$: Observable<number> }
  ) {}
}

<mdnra-complete-view
  [title]="
    'HOME.YOUR_MILESTONE_END_TITLE' | translate : { level: data.level.level }
  "
  [description]="
    data.level.level === 6
      ? ('HOME.YOUR_MILESTONE_END' | translate)
      : ('HOME.YOUR_PROGRESS_DESC'
        | translate
          : { milestone: data.level.level, pointsLeft: data.missingLeaves })
  "
  [redirectLabel]="'COMMON.CLOSE'"
  [receivedPoints]="data.receivedLeaves"
  [totalPoints]="data.totalScore"
  (redirectPressed)="close()"
  (closePressed)="close()"
>
</mdnra-complete-view>

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopupDialogModel } from './popup-dialog.model';

@Component({
  selector: 'mdnra-popup-dialog',
  templateUrl: './popup-dialog.component.html',
})
export class PopupDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: PopupDialogModel) {}
}

<section class="section">
  <div class="powered-by-wrapper mb-3">
    <img
      class="median-logo"
      src="../../../../assets/other//median-logo.svg"
      alt="median-logo"
    />
  </div>
  <h2>{{ "REGISTRATION.INTRO" | translate }}</h2>
  <p>{{ "REGISTRATION.INTRO_DESC" | translate }}</p>
  <div class="powered-by-wrapper">
    <img
      class="tree leaf"
      src="../../../../assets/backgrounds/leafg1.svg"
      alt=""
    />
  </div>
</section>

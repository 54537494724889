<div class="set-goal-dialog">
  <mdnra-button-link (click)="closeDialog()" class="p-0">
    <i class="fas fa-long-arrow-left"></i>
    {{ "COMMON.BACK" | translate }}
  </mdnra-button-link>
  <h2 class="mt-4 mb-2">
    {{ "HOME.YOUR_WEEKLY_GOAL.MODAL.TITLE" | translate }}
  </h2>
  <p class="mb-4">
    {{ "HOME.YOUR_WEEKLY_GOAL.MODAL.DESCRIPTION" | translate }}
  </p>
  <h3>
    {{ "HOME.YOUR_WEEKLY_GOAL.MODAL.SUBTITLE" | translate }}
  </h3>
  <p [innerHTML]="data?.goal" class="mb-4"></p>
  <button
    (click)="complete(true, data.goalId)"
    class="goal-button"
    mat-flat-button
  >
    {{ "HOME.YOUR_WEEKLY_GOAL.MODAL.COMPLETE_BUTTON" | translate }}
  </button>
  <button
    (click)="complete(false, data.goalId)"
    class="goal-button"
    mat-flat-button
  >
    {{ "HOME.YOUR_WEEKLY_GOAL.MODAL.INCOMPLETE_BUTTON" | translate }}
  </button>
</div>

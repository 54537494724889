<div class="form-wrapper" *ngIf="!imprint && !privacy">
  <div *ngIf="!isRegistrationProcess">
    <mdnra-registration-intro></mdnra-registration-intro>
  </div>

  <ng-container [ngSwitch]="currentStep | async">
    <mdnra-registration-terms
      *ngSwitchCase="'termsStep'"
      [token]="registrationData.token"
      [data]="registrationData.terms"
      (changeStep)="saveTermsStep($event)"
      (privacyEvent)="setPrivacy($event)"
      (updateStepData)="registrationData.terms = $event"
    ></mdnra-registration-terms>

    <mdnra-registration-password
      *ngSwitchCase="'passwordStep'"
      [token]="registrationData.token"
      [data]="registrationData.pass"
      (changeStep)="savePassStep($event)"
      (updateStepData)="registrationData.pass = $event"
      [hasBackButton]="true"
    ></mdnra-registration-password>

    <mdnra-registration-birthday
      *ngSwitchCase="'birthdayStep'"
      [token]="registrationData.token"
      [data]="registrationData.birth"
      (changeStep)="saveBirthdayStep($event)"
      (updateStepData)="registrationData.birth = $event"
      [hasBackButton]="true"
    ></mdnra-registration-birthday>

    <mdnra-registration-contact
      *ngSwitchCase="'contactStep'"
      [token]="registrationData.token"
      [data]="registrationData.contact"
      (changeStep)="saveContactStep($event)"
      (updateStepData)="registrationData.contact = $event"
      [hasBackButton]="true"
    ></mdnra-registration-contact>

    <mdnra-registration-check-email
      *ngSwitchCase="'checkEmailStep'"
      [email]="registrationData.contact.email"
    ></mdnra-registration-check-email>
  </ng-container>

  <div class="powered-by-wrapper">
    <a class="imprint" mat-button color="primary" (click)="imprint = true">
      {{ "REGISTRATION.IMPRINT" | translate }}
    </a>
  </div>

  <button
    *ngIf="!isRegistrationProcess"
    class="generic-button"
    mat-flat-button
    type="button"
    (click)="startRegistrationProcess()"
    [disabled]="!isTokenValid"
  >
    {{ "REGISTRATION.TO_REGISTER" | translate }}
  </button>
</div>

<div [hidden]="!privacy">
  <mdnra-data-protection [popUp]="true" (privacyEvent)="setPrivacy($event)">
  </mdnra-data-protection>
</div>

<div [hidden]="!imprint">
  <mdnra-imprint [popUp]="true" (imprintEvent)="setImprint($event)">
  </mdnra-imprint>
</div>

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PatientService } from 'app/core/services/patient/patient.service';
import { ProgressTreeLevel } from 'app/core/services/patient/progress-tree-level.model';
import { MilestoneLeavesModel } from './milestone-leaves.model';
import { NewMilestoneComponent } from './new-milestone/new-milestone.component';

@Injectable({
  providedIn: 'root',
})
export class MilestonesService {
  constructor(private readonly patientService: PatientService) {}

  getMilestone(leaves: number, levels: ProgressTreeLevel[]): ProgressTreeLevel {
    const matchingMilestoneLevel = levels.find((level) => {
      return level.levelStart <= leaves && level.levelEnd >= leaves;
    });

    if (matchingMilestoneLevel && leaves >= 0) return matchingMilestoneLevel;

    // Quick fix to show the possible max milestone when the leaves exceed the end of the max level
    return levels[levels.length - 1];
  }

  isLevelComplete(
    totalLeaves: number,
    newLeaves: number,
    levels: ProgressTreeLevel[]
  ): boolean {
    const prevMilestone = this.getMilestone(totalLeaves - newLeaves, levels);
    const currentMilestone = this.getMilestone(totalLeaves, levels);
    if (prevMilestone && currentMilestone) {
      return prevMilestone.level < currentMilestone.level;
    }
    return false;
  }

  getMissingLeavesToReachMilestone(
    totalLeaves: number,
    currentLevel: ProgressTreeLevel
  ): number {
    return currentLevel.levelEnd - totalLeaves;
  }

  openNewMilestoneDialog(
    data: MilestoneLeavesModel,
    dialog: MatDialog,
    withoutRedirect: boolean = false
  ) {
    const newLeaves = data.leavesDelta;
    const totalLeaves = data.leaves;

    if (newLeaves) {
      this.patientService.getTreeProgress().subscribe((progressTree) => {
        const currentLevel = this.getMilestone(
          totalLeaves,
          progressTree.levels
        );
        if (this.isLevelComplete(totalLeaves, newLeaves, progressTree.levels)) {
          dialog.open(NewMilestoneComponent, {
            width: '100%',
            height: '100%',
            maxWidth: '100vw',
            data: {
              receivedLeaves: newLeaves,
              level: currentLevel,
              missingLeaves: this.getMissingLeavesToReachMilestone(
                totalLeaves,
                currentLevel!
              ),
              totalScore: totalLeaves,
              closeOnly: withoutRedirect,
            },
          });
        }
      });
    }
  }
}

import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { fromEvent, merge, Subscription, timer } from 'rxjs';
import { map, startWith, switchAll } from 'rxjs/operators';

@Component({
  selector: 'mdnra-close-button',
  templateUrl: './close-button.component.html',
  styleUrls: ['./close-button.component.scss'],
})
export class CloseButtonComponent implements OnInit, OnDestroy {
  @Output()
  closePressed = new EventEmitter<void>();

  public isHighlighted: boolean = true;
  public highlightSubscription: Subscription = new Subscription();

  constructor(private readonly elementRef: ElementRef) {}

  ngOnInit(): void {
    const element = this.elementRef.nativeElement;
    this.highlightSubscription = merge(
      fromEvent(element, 'mousedown'),
      fromEvent(element, 'mousemove'),
      fromEvent(element, 'keypress'),
      fromEvent(element, 'scroll'),
      fromEvent(element, 'touchstart')
    )
      .pipe(
        startWith(true),
        map(() => ((this.isHighlighted = true), timer(3000))),
        switchAll(),
        map(() => (this.isHighlighted = false))
      )
      .subscribe();
  }

  closePressedHandler(): void {
    this.closePressed.emit();
  }

  ngOnDestroy(): void {
    this.highlightSubscription.unsubscribe();
  }
}

import {
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import Wad from 'web-audio-daw';

export const FIRST_STEP_WITH_VOICE: number = 10;
export const ONE_SECOND_IN_MILISECOND: number = 1000;
export const AUDIO_PATH: string = '../../../assets/audio';
export const AUDIO_PREFIX: string = 'Countdown_F-';

@Component({
  selector: 'mdnra-voice-timer',
  templateUrl: './voice-timer.component.html',
  styleUrls: ['./voice-timer.component.scss'],
})
export class VoiceTimerComponent implements OnInit {
  @HostBinding('style.background-color')
  defaultBackgroundColor: string = '#159887';

  @Input()
  backgroundColor: string = '#159887';

  @HostBinding('style.color')
  defaultColor: string = '#ffffff';

  @Input()
  color: string = '#ffffff';

  @HostBinding('style.top')
  defaultTop: number | string = 0;

  @HostBinding('style.left')
  defaultLeft: number | string = 'unset';

  @HostBinding('style.bottom')
  defaultBottom: number | string = 'unset';

  @HostBinding('style.right')
  defaultRight: number | string = 0;

  @Input()
  position: {
    top?: number | undefined;
    right?: number | undefined;
    bottom?: number | undefined;
    left?: number | undefined;
  } = {};


  @Input() set counter(value: number) {
    this.countdownValue = value;
    this.playVoice(this.countdownValue);
  }

  public countdownValue: number = 0;

  constructor() {}

  ngOnInit(): void {
    this.defaultBackgroundColor = this.backgroundColor;
    this.defaultColor = this.color;

    if (Object.keys(this.position).length) {
      this.setTimerPosition();
    }
  }

  private calculatePosition(
    firstPos: number | undefined,
    secondPos: number | undefined
  ): number | string {
    let pos = firstPos || (secondPos !== undefined ? 'unset' : 0);
    return pos !== 'unset' ? pos + 'px' : pos;
  }

  private setTimerPosition() {
    this.defaultTop = this.calculatePosition(
      this.position?.top,
      this.position?.bottom
    );

    this.defaultLeft = this.calculatePosition(
      this.position?.left,
      this.position?.right
    );

    this.defaultBottom = this.calculatePosition(
      this.position?.bottom,
      this.position?.top
    );

    this.defaultRight = this.calculatePosition(
      this.position?.right,
      this.position?.left
    );
  }

  private playVoice(currentValue: number) {
    if (currentValue && currentValue <= FIRST_STEP_WITH_VOICE) {
      let fileNumber = currentValue < FIRST_STEP_WITH_VOICE ? '0' : '';
      fileNumber += currentValue;
      // @ts-ignore
      const voice = new Wad({ source: `${AUDIO_PATH}/${AUDIO_PREFIX + fileNumber}.mp3` });
      voice.play();
    }
  }
}

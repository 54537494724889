import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { PatientGoalService } from 'app/core/services/patient-goal/patient-goal.service';
import { ProgressTreeModel } from 'app/core/services/patient/progress-tree.model';
import { MilestonesService } from '../../milestones/milestones.service';
import { PatientService } from 'app/core/services/patient/patient.service';
import { HttpStatusCode } from '@angular/common/http';

const { required, maxLength } = Validators;

@Component({
  selector: 'mdnra-weekly-goal-set-dialog',
  templateUrl: './weekly-goal-set-dialog.component.html',
  styleUrls: ['./weekly-goal-set-dialog.component.scss'],
})
export class WeeklyGoalSetDialogComponent implements OnInit {
  public editorOptions = {
    toolbar: [[{ list: 'ordered' }, { list: 'bullet' }]],
  };
  //TODO: Type form controls
  public ambitionControl: UntypedFormControl = new UntypedFormControl('', [
    required,
    maxLength(280),
  ]);
  public typeControl: UntypedFormControl = new UntypedFormControl('WEEKLY', [
    required,
  ]);
  public idControl: UntypedFormControl = new UntypedFormControl(null);

  public goalForm: UntypedFormGroup = new UntypedFormGroup({
    ambition: this.ambitionControl,
    type: this.typeControl,
    id: this.idControl,
  });

  public submittingGoal: boolean = false;
  public openDialog: boolean = false;
  public currentGoal: string | undefined;

  public responseData?: ProgressTreeModel;

  constructor(
    public dialogRef: MatDialogRef<WeeklyGoalSetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { goal: string },
    private readonly milestonesService: MilestonesService,
    private readonly dialog: MatDialog,
    private readonly goalService: PatientGoalService,
    private readonly patientService: PatientService
  ) {}

  ngOnInit(): void {
    this.currentGoal = this.data?.goal?.replace(/<[^>]+>/g, '');
  }

  send() {
    const newGoalBody = {
      type: this.goalForm.value.type,
      ambition: this.goalForm.value.ambition,
    };

    this.submittingGoal = true;

    this.goalService.getCurrentGoal().subscribe({
      next: (response) => {
        const body = {
          ...newGoalBody,
        };
        if (response.statusCode === HttpStatusCode.NoContent) {
          this.goalService.createGoal(newGoalBody).subscribe((data) => {
            this.responseData = data;
            this.openDialog = true;
            this.submittingGoal = false;
            this.milestonesService.openNewMilestoneDialog(data, this.dialog);
          });
        } else {
          this.goalService
            .updateGoal(response.data.id, body)
            .subscribe((data) => {
              this.responseData = data;
              this.submittingGoal = false;
              this.closeDialog();
              this.milestonesService.openNewMilestoneDialog(data, this.dialog);
            });
        }
      },
    });
  }

  closeDialog() {
    if (this.responseData?.leaves) {
      this.patientService.updateTreeProgress(this.responseData);
    }
    this.dialogRef.close({
      event: 'close',
      data: this.goalForm.value.ambition,
    });
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URL } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ResetPasswordModel } from './reset-password.model';
import { ResetPasswordValidationModel } from 'app/authentication/reset/reset-password/reset-password-validation.model';

@Injectable({
  providedIn: 'root',
})
export class ResetPasswordService {
  constructor(private http: HttpClient) {}

  reset(body: ResetPasswordModel): Observable<void> {
    let url = `${API_URL}auth/reset-password`;
    return this.http.post<void>(url, body);
  }

  validateBirthDate(dateOfBirth: string, token: string): Observable<void> {
    const url = `${API_URL}auth/reset-password/validate-date-of-birth`;
    const body = {
      token,
      dateOfBirth,
    };
    return this.http.post<void>(url, body);
  }

  validatePassword(
    data: ResetPasswordValidationModel,
    token: string
  ): Observable<void> {
    const url = `${API_URL}auth/reset-password/validate-password`;
    const body = {
      token,
      newPassword: data.password,
      newPasswordMatch: data.confirmPassword,
    };
    return this.http.post<void>(url, body);
  }
}

import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  AuthenticationService,
  LOCAL_STORAGE_KEY,
} from 'app/core/services/authentication/authentication.service';
import { SessionStorageService } from 'app/shared/services/session-storage.service';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

const { parse } = JSON;
@Component({
  selector: 'mdnra-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  account$ = new BehaviorSubject<any>(
    parse(localStorage.getItem(LOCAL_STORAGE_KEY) as string) || {}
  );
  //TODO: Type form controls
  userControl = new UntypedFormControl();
  passControl = new UntypedFormControl();
  loginForm = new UntypedFormGroup({
    user: this.userControl,
    pass: this.passControl,
  });
  hide = true;
  displayError = false;
  loginError = false;
  loginScreen = false;
  imprint = false;
  dataProtection = false;

  public errorMessage: string = '';
  public isLoginDisabled: boolean = false;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
    private readonly sessionStorageService: SessionStorageService,
    private readonly translateService: TranslateService,
    @Inject(DOCUMENT) public document: Document
  ) {}

  ngOnInit(): void {
    this.loginScreen = window.history.state.loginScreen;
  }

  login(): void {
    this.isLoginDisabled = true;
    this.authenticationService
      .login(this.userControl.value?.trim(), this.passControl.value)
      .pipe(
        finalize(() => {
          this.isLoginDisabled = false;
        })
      )
      .subscribe({
        next: () => {
          let redirectUrl = '/home';
          if (this.authenticationService.redirectUrl) {
            redirectUrl = this.authenticationService.redirectUrl;
            this.authenticationService.redirectUrl = null;
          }
          this.router.navigateByUrl(redirectUrl);
        },
        error: (error) => {
          this.displayError = true;
          this.loginError = true;
          this.errorMessage = this.translateService.instant(
            error.status === 401
              ? 'LOGIN.LOGIN_UNAUTHORIZED'
              : 'LOGIN.SERVICE_UNAVAILABLE'
          );
        },
      });
  }

  toLogin(): void {
    this.loginScreen = true;
    this.sessionStorageService.setItem('shouldDisplayWeeklyGoalModal', true);
  }

  correctInput() {
    this.loginError = false;
  }

  setImprint(imprint: boolean): void {
    this.imprint = imprint;
  }

  setDataProtection(dataProtection: boolean): void {
    this.dataProtection = dataProtection;
  }
}

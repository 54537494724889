import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL } from 'environments/environment';
import { Observable } from 'rxjs';
import { RegistrationRequest } from '../../../authentication/registration/registration.dto';

@Injectable({ providedIn: 'root' })
export class RegistrationService {
  constructor(private http: HttpClient) {}

  register(data: RegistrationRequest): Observable<void> {
    const url = `${API_URL}registration`;
    const body = data;
    return this.http.post<void>(url, body);
  }

  verifyInvite(token: string): Observable<void> {
    const url = `${API_URL}registration/verify-invite`;
    const body = {
      token,
    };
    return this.http.post<void>(url, body);
  }

  validateBirthDate(dateOfBirth: string, token: string): Observable<void> {
    const url = `${API_URL}registration/validate-date-of-birth`;
    const body = {
      token,
      dateOfBirth,
    };
    return this.http.post<void>(url, body);
  }

  validateEmail(username: string, token: string): Observable<void> {
    const url = `${API_URL}registration/validate-username`;
    const body = {
      token,
      username,
    };
    return this.http.post<void>(url, body);
  }

  validatePhone(token: string, phoneNumber?: string): Observable<void> {
    const url = `${API_URL}registration/validate-phone-number`;
    const body = {
      token,
      phoneNumber,
    };
    return this.http.post<void>(url, body);
  }

  validatePassword(
    password: string,
    confirmPassword: string,
    token: string
  ): Observable<void> {
    const url = `${API_URL}registration/validate-password`;
    const body = {
      token,
      password,
      passwordMatch: confirmPassword,
    };
    return this.http.post<void>(url, body);
  }

  validateAGB(
    data: boolean,
    token: string,
    analytics: boolean
  ): Observable<void> {
    const url = `${API_URL}registration/validate-agb`;
    const body = {
      token,
      agreedDataProtection: data,
      agreedCrashlytics: analytics,
    };
    return this.http.post<void>(url, body);
  }

  activate(token: string): Observable<void> {
    const url = `${API_URL}registration/verify-email`;
    const body = {
      token,
    };
    return this.http.post<void>(url, body);
  }

  resendWithEmail(username: string): Observable<void> {
    const url = `${API_URL}registration/resend/with-username`;
    const body = {
      username,
    };
    return this.http.post<void>(url, body);
  }

  resendWithToken(token: string): Observable<void> {
    const url = `${API_URL}registration/resend/with-token`;
    const body = {
      token,
    };
    return this.http.post<void>(url, body);
  }
}

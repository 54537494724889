/**
 * utilities not included in module
 */
import { HttpErrorResponse } from '@angular/common/http';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { AuthorizedApiService } from 'app/core/services/authentication/authorized-api.service';
import { Observable, of, throwError, timer } from 'rxjs';
import { catchError, filter, tap, map, switchMap, retry } from 'rxjs/operators';
import { RegistrationService } from '../core/services/registration/registration.service';

export const commonApiErrorHandler = <T extends AuthorizedApiService>(
  thisArg: T,
  errorResponse: HttpErrorResponse,
  pushToMessagingService = true
) => {
  thisArg.goToLoginOnError(errorResponse);
  pushToMessagingService &&
    thisArg.messagingService.pushServerError(errorResponse);
  return throwError(() => errorResponse);
};

export const commonAuthorizationHeader = <T extends AuthorizedApiService>(
  thisArg: T
) => thisArg.getHeaderConfig();

export const retryStrategy =
  ({
    maxRetryAttempts = 10,
    delay = 3000,
    excludedStatusCodes = [400, 404, 422, 504],
  }: {
    maxRetryAttempts?: number;
    delay?: number;
    excludedStatusCodes?: number[];
  } = {}) =>
  (error: HttpErrorResponse, retryCount: number) => {
    if (
      retryCount > maxRetryAttempts ||
      excludedStatusCodes.some((e) => e === error.status)
    ) {
      return throwError(() => error);
    }

    return timer(retryCount * delay);
  };

export const matchValues =
  (
    matchTo: AbstractControl
  ): ((control: AbstractControl) => ValidationErrors | null) =>
  (control: AbstractControl): ValidationErrors | null =>
    matchTo.value === control.value ? null : { match: false };

export const passwordValidator =
  (
    registrationService: RegistrationService,
    confirmControl: AbstractControl,
    token: string
  ) =>
  (control: AbstractControl): Observable<ValidationErrors | null> => {
    return timer(400).pipe(
      switchMap(() =>
        registrationService.validatePassword(
          control.value,
          confirmControl.value,
          token
        )
      ),
      retry({ delay: retryStrategy() }),
      map(() => null),
      catchError(({ error }) => {
        return of(
          error?.fields?.password ? { [error.fields.password[0]]: true } : null
        );
      })
    );
  };

export const passwordConfirmValidator =
  (
    registrationService: RegistrationService,
    passwordControl: AbstractControl,
    token: string
  ) =>
  (control: AbstractControl): Observable<ValidationErrors | null> =>
    timer(400).pipe(
      switchMap(() =>
        registrationService.validatePassword(
          passwordControl.value,
          control.value,
          token
        )
      ),
      retry({ delay: retryStrategy() }),
      map(() => null),
      catchError(({ error }) => {
        return of(
          error?.fields?.passwordMatch
            ? { [error.fields.passwordMatch[0]]: true }
            : null
        );
      })
    );

export const birthDateValidator =
  (registrationService: RegistrationService, token: string) =>
  (control: AbstractControl): Observable<ValidationErrors | null> =>
    timer(400).pipe(
      switchMap(() =>
        registrationService.validateBirthDate(control.value, token)
      ),
      retry({ delay: retryStrategy() }),
      map(() => null),
      catchError(({ error }) =>
        of(
          error?.fields?.dateOfBirth
            ? { [error.fields.dateOfBirth[0]]: true }
            : null
        )
      )
    );

export const phoneValidator =
  (registrationService: RegistrationService, token: string) =>
  (control: AbstractControl): Observable<ValidationErrors | null> => {
    if (control.untouched) {
      return of(null);
    }
    return timer(400).pipe(
      filter(() => control.value),
      switchMap(() =>
        registrationService.validatePhone(token, control.value?.trim())
      ),
      retry({ delay: retryStrategy() }),
      map(() => null),
      catchError(({ error }) =>
        of(
          error?.fields?.phoneNumber
            ? {
                [error.fields.phoneNumber[0]]: true,
              }
            : null
        )
      )
    );
  };

export const termsValidator =
  (
    registrationService: RegistrationService,
    dataControl: AbstractControl,
    analyticsControl: AbstractControl,
    token: string
  ) =>
  (): Observable<ValidationErrors | null> => {
    let lastTermsValue = '';
    const controlValue = [dataControl.value, analyticsControl.value].join();
    return timer(400).pipe(
      filter(() => controlValue !== lastTermsValue),
      tap(() => (lastTermsValue = controlValue)),
      switchMap(() =>
        registrationService.validateAGB(
          dataControl.value,
          token,
          analyticsControl.value
        )
      ),
      retry({ delay: retryStrategy() }),
      map(() => null),
      catchError(({ error }) => {
        const errorKeys = Object.keys(error?.fields || {});
        return of(
          ['agreedAgb', 'agreedDataProtection'].some((k) =>
            errorKeys.includes(k)
          )
            ? { terms: true }
            : null
        );
      })
    );
  };

export const emailValidator =
  (registrationService: RegistrationService, token: string) =>
  (control: AbstractControl): Observable<ValidationErrors | null> => {
    let lastEmailValue = '';
    const controlValue = control.value?.trim();
    return timer(400).pipe(
      filter(() => controlValue !== lastEmailValue),
      tap(() => (lastEmailValue = controlValue)),
      switchMap(() =>
        registrationService.validateEmail(control.value?.trim(), token)
      ),
      retry({ delay: retryStrategy() }),
      map(() => null),
      catchError(({ error }) =>
        of(
          error?.fields?.username ? { [error.fields.username[0]]: true } : null
        )
      )
    );
  };

export const tokenValidator =
  (registrationService: RegistrationService) =>
  (control: AbstractControl): Observable<ValidationErrors | null> =>
    registrationService.verifyInvite(control.value).pipe(
      retry({ delay: retryStrategy() }),
      map(() => null),
      catchError(() => of({ invalidToken: true }))
    );

export const max3Validator = (
  control: AbstractControl
): ValidationErrors | null =>
  (control.value as boolean[]).filter((v) => !!v).length > 3
    ? { max3: true }
    : null;

export const atLeast1Validator =
  (...controlNames: string[]) =>
  (control: AbstractControl): ValidationErrors | null =>
    controlNames.some((controlName) => {
      const controlValue = control.get(controlName)?.value;
      return Array.isArray(controlValue)
        ? controlValue.some((v) => !!v)
        : !!controlValue;
    })
      ? null
      : { atLeast1: true };

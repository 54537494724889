<div class="form-wrapper">
  <ng-container [ngSwitch]="currentStep | async">
    <mdnra-reset-birthday
      *ngSwitchCase="'birthdayStep'"
      [token]="resetData.token"
      (changeStep)="saveBirthdayStep($event)"
    ></mdnra-reset-birthday>

    <mdnra-reset-password
      *ngSwitchCase="'passwordStep'"
      [token]="resetData.token"
      [birthday]="resetData.birthday"
      [errors]="errors"
      (changeStep)="savePassStep($event)"
    ></mdnra-reset-password>
  </ng-container>
</div>

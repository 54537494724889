import {
  CommonModule,
  LOCATION_INITIALIZED,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HttpErrorResponse,
} from '@angular/common/http';
import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  Event,
  GuardsCheckEnd,
  GuardsCheckStart,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  ResolveEnd,
  ResolveStart,
  Router,
} from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { JwtModule } from '@auth0/angular-jwt';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { IS_PRODUCTION } from 'environments/environment';
import { AppComponent } from './app.component';
import { AuthenticationModule } from './authentication/authentication.module';
import { CoreModule } from './core/core.module';
import { LoaderService } from './loader.service';
import { RoutingModule } from './routing.module';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { MedianAppApiModel } from './shared/dto/median-app-api.dto';
import { SharedModule } from './shared/shared.module';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

declare const MedianAppApi: MedianAppApiModel;

function isLoadedInMedian(): boolean {
  return !!(typeof MedianAppApi !== 'undefined' && MedianAppApi?.TWT);
}

export const createTranslateLoader = (http: HttpClient): TranslateHttpLoader =>
  new TranslateHttpLoader(http, './assets/i18n/', '.json?cb=' + Date.now());

export const tokenGetter = (): null => null;

export function translationInitializerFactory(
  translate: TranslateService,
  injector: Injector
) {
  return () =>
    new Promise<void>((resolve) => {
      const locationInitialized = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve()
      );
      locationInitialized.then(() => {
        translate.addLangs(['de']);
        const defaultLang = 'de';

        // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang(defaultLang);

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        translate.use(defaultLang).subscribe({
          next: () => {
            if (!IS_PRODUCTION) {
              console.info(
                `Successfully initialized '${defaultLang}' language.`
              );
            }
          },
          error: () => {
            if (!IS_PRODUCTION) {
              console.error(
                `Problem with '${defaultLang}' language initialization.`
              );
            }
          },
          complete: () => {
            resolve();
          },
        });
      });
    });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: IS_PRODUCTION,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    CoreModule,
    AuthenticationModule,
    SharedModule,
    CommonModule,
    HttpClientModule,
    JwtModule.forRoot({ config: { tokenGetter } }),
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,

    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatButtonModule,
    MatIconModule,
    RoutingModule,
    SharedModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    {
      provide: LOCALE_ID,
      useValue: 'de-DE',
    },
    LoaderService,
    {
      provide: APP_INITIALIZER,
      useFactory: translationInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
  ],
  exports: [SharedModule],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(router: Router, loaderService: LoaderService) {
    if (isLoadedInMedian()) {
      MedianAppApi?.TWT?.hideTabbar();
    }

    router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof GuardsCheckStart:
        case event instanceof ResolveStart:
        case event instanceof NavigationStart:
          loaderService.loading = true;
          break;
        case event instanceof GuardsCheckEnd:
        case event instanceof ResolveEnd:
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError:
          // probably error from resolver
          if (
            event instanceof NavigationError &&
            event.error instanceof HttpErrorResponse &&
            !!event.error.status
          ) {
            setTimeout(() => router.navigateByUrl('/'));
          }
          loaderService.loading = false;
          break;
        default:
          break;
      }
    });
  }
}

import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  standalone: true,
  selector: 'mdnra-button-link',
  template: `
    <a [routerLink]="route" *ngIf="type === 'link'" [ngClass]="color">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
    <button *ngIf="type === 'button'" [ngClass]="color">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>
    <ng-template #content>
      <ng-content></ng-content>
    </ng-template>
  `,
  styleUrls: ['./button-link.component.scss'],
  imports: [RouterLink, NgIf, NgTemplateOutlet, NgClass],
})
export class MdnraButtonLinkComponent {
  @Input() route: string | string[];
  @Input() type: 'button' | 'link' = 'button';
  @Input() color: 'copper' | 'white' = 'copper';
}

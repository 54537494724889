import { Component } from '@angular/core';

@Component({
  selector: 'mdnra-registration-intro',
  templateUrl: './registration-intro.component.html',
  styleUrls: ['./registration-intro.component.scss'],
})
export class RegistrationIntroComponent {
  constructor() {}
}

<div *ngIf="!imprint" class="form-wrapper">
  <div class="main-form">
    <ng-container *ngIf="expired$ | async; else successTpl">
      <section class="section6 birth-date">
        <a mat-button (click)="resend()">{{
          "REGISTRATION.RESEND" | translate
        }}</a>
        <ng-container *ngIf="resendError">
          <div class="resendError">
            {{ "COMMON.SERVER_ERROR" | translate }}
          </div>
        </ng-container>
        <ng-container *ngIf="resendSuccess">
          <div class="resendSuccess">
            {{ "REGISTRATION.EMAIL_RESENT" | translate }}
          </div>
        </ng-container>

        <div class="bottom">
          <button
            class="next-button"
            mat-flat-button
            type="button"
            routerLink="../login"
          >
            {{ "REGISTRATION.TO_LOGIN" | translate }}
          </button>
          <!--    <div class="powered-by-wrapper">
                        <img class="median-logo" src="../../assets/other/median-logo.svg" alt="">
                    </div>-->
        </div>
      </section>
    </ng-container>

    <ng-template #successTpl>
      <section class="section6 birth-date">
        <h2>{{ "REGISTRATION.VERIFIED" | translate }}</h2>
        <p>{{ "REGISTRATION.VERIFIED_DESC" | translate }}</p>

        <div class="bottom">
          <div class="powered-by-wrapper">
            <a
              class="imprint"
              mat-button
              color="primary"
              (click)="imprint = true">
              {{ "REGISTRATION.IMPRINT" | translate }}
            </a>
          </div>
          <button
            class="generic-button next-button"
            mat-flat-button
            type="button"
            routerLink="../login"
          >
            {{ "REGISTRATION.TO_LOGIN" | translate }}
          </button>
        </div>
      </section>
    </ng-template>
  </div>
</div>
<div *ngIf="imprint">
  <mdnra-imprint
    [popUp]="true"
    (imprintEvent)="setImprint($event)"
  ></mdnra-imprint>
</div>

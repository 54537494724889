<section class="section5">
  <h2>{{ "REGISTRATION.CONTACT" | translate }}</h2>
  <p>{{ "REGISTRATION.CONTACT_DESC" | translate }}</p>

  <div class="contact-middle" [formGroup]="contactForm">
    <mat-form-field appearance="fill" class="pass-field">
      <mat-label>{{ "REGISTRATION.EMAIL" | translate }}</mat-label>
      <input
        matInput
        formControlName="email"
        autocomplete="off"
        (change)="updateData()"
      />
      <mat-error *ngIf="emailControl.invalid">{{
        getErrorMessage("email")
      }}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" class="pass-field">
      <mat-label>{{ "REGISTRATION.PHONE" | translate }}</mat-label>
      <input
        matInput
        formControlName="phone"
        autocomplete="off"
        (change)="updateData()"
      />
      <mat-error *ngIf="phoneControl.invalid">{{
        getErrorMessage("phone")
      }}</mat-error>
    </mat-form-field>
    <div class="bottom">
      <div
        [class]="
          hasBackButton ? 'bottom-buttons hasBackButton' : 'bottom-buttons'
        "
      >
        <button
          *ngIf="hasBackButton"
          mat-stroked-button
          type="button"
          (click)="previousStep()"
          class="button back"
        >
          {{ "COMMON.BACK" | translate }}
        </button>
        <button
          class="button next"
          mat-flat-button
          [disabled]="emailControl.invalid || phoneControl.invalid"
          type="button"
          (click)="nextStep()"
        >
          {{ "COMMON.NEXT" | translate }}
        </button>
      </div>
    </div>
  </div>
</section>

import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'mdnra-card-layout',
  templateUrl: 'card-layout.component.html',
  styleUrls: ['card-layout.component.scss'],
})
export class CardLayoutComponent {
  @Input()
  title = '';

  @Input()
  description = '';

  @Input()
  detailLink = '';

  @Input()
  detailLinkLabel = '';

  @Input()
  icon = '';

  @Input()
  colorScheme = 'home';

  @Input()
  header = '';

  @Input()
  footer = '';

  @Input()
  imgUrl= '';

  @Input()
  iconBackground = '';

  @Input()
  mainPage = false;

  @Input()
  exercise = false;

  @Input()
  openedFromLibrary = false;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {}

  navigate(): void {
    this.router.navigate([this.detailLink], {
      relativeTo: this.route,
      state: { openedFromLibrary: this.openedFromLibrary },
    });
  }
}

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { TranslateModule } from '@ngx-translate/core';
import { ActivateComponent } from './activate/activate.component';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { AuthenticationGuard } from './authentication.guard';

import { ForgotComponent } from './forgot/forgot.component';
import { LoginComponent } from './login/login.component';
import { RegistrationBirthdayComponent } from './registration/registration-birthday/registration-birthday.component';
import { RegistrationCheckEmailComponent } from './registration/registration-check-email/registration-check-email.component';
import { RegistrationContactComponent } from './registration/registration-contact/registration-contact.component';
import { RegistrationIntroComponent } from './registration/registration-intro/registration-intro.component';
import { RegistrationPasswordComponent } from './registration/registration-password/registration-password.component';
import { RegistrationTermsComponent } from './registration/registration-terms/registration-terms.component';
import { RegistrationComponent } from './registration/registration.component';
import { RegistrationService } from '../core/services/registration/registration.service';
import { ResetBirthdayComponent } from './reset/reset-birthday/reset-birthday.component';
import { ResetPasswordComponent } from './reset/reset-password/reset-password.component';
import { ResetComponent } from './reset/reset.component';
import { ShareAuthComponent } from './share-auth/share-auth.component';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { AuthorizedApiService } from 'app/core/services/authentication/authorized-api.service';
import { SharedModule } from 'app/shared/shared.module';
import { MY_DATE_FORMATS } from 'app/shared/utilities/date';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { EmailConfirmGuard } from './confirmEmail.guard';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatMomentDateModule,
    HttpClientModule,
    ReactiveFormsModule,
    TranslateModule,
    AuthenticationRoutingModule,
    MatIconModule,
    MatCardModule,
  ],
  exports: [MatDatepickerModule],
  providers: [
    AuthenticationService,
    AuthenticationGuard,
    EmailConfirmGuard,
    AuthorizedApiService,
    RegistrationService,
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
  ],
  declarations: [
    LoginComponent,
    RegistrationComponent,
    ForgotComponent,
    ActivateComponent,
    ResetComponent,
    ShareAuthComponent,
    RegistrationIntroComponent,
    RegistrationTermsComponent,
    RegistrationPasswordComponent,
    RegistrationBirthdayComponent,
    RegistrationContactComponent,
    RegistrationCheckEmailComponent,
    ResetBirthdayComponent,
    ResetPasswordComponent,
    ConfirmEmailComponent,
  ],
})
export class AuthenticationModule {}

import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { MessagingService } from 'app/core/services/messaging/messaging.service';

const { required } = Validators;

@Component({
  selector: 'mdnra-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss'],
})
export class ForgotComponent {
  public requestSent: boolean = false;
  public requestFailed: boolean = false;

  public emailControl = new FormControl<string | null>(null, [
    required,
    Validators.email,
  ]);

  public forgotForm = new FormGroup<{ email: FormControl<string | null> }>({
    email: this.emailControl,
  });

  constructor(
    private readonly authService: AuthenticationService,
    private readonly messagingService: MessagingService,
    private readonly router: Router
  ) {}

  forgot(): void {
    if (!this.emailControl.value) return;
    this.authService.forgot(this.emailControl.value).subscribe({
      next: () => {
        this.requestSent = true;
      },
      error: (error) => {
        this.messagingService.pushServerError(error);
        this.requestFailed = true;
      },
    });
  }

  backToLogin() {
    this.router.navigateByUrl('/login', { state: { loginScreen: true } });
  }
}

import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MedianAppApiModel } from 'app/shared/dto/median-app-api.dto';
import { MedianAppApiService } from 'app/shared/services/median-app-api.service';
import { SessionStorageService } from 'app/shared/services/session-storage.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { API_URL, environment } from '../../../../environments/environment';

export const LOCAL_STORAGE_TOKEN_KEY = 'mdnra-token';
export const LOCAL_STORAGE_KEY = 'mdnra-account';

const { parse, stringify } = JSON;

declare const MedianAppApi: MedianAppApiModel;

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  account$ = new BehaviorSubject<any>(
    parse(localStorage.getItem(LOCAL_STORAGE_KEY) as string) || {}
  );

  public redirectUrl: string | null = null;

  private apiUrl: string = environment.API_URL;
  private loginMedian: string = environment.MEDIAN_LOGIN;

  constructor(
    private readonly jwtHelper: JwtHelperService,
    private readonly sessionStorageService: SessionStorageService,
    private readonly medianAppApiService: MedianAppApiService,
    private readonly http: HttpClient,
    private readonly router: Router
  ) {}

  forgot(email: string): Observable<void> {
    const url = `${API_URL}auth/reset-request`;
    const body = {
      email,
    };
    return this.http.post<void>(url, body);
  }

  reset(
    token: string,
    dateOfBirth: string,
    newPassword: string,
    newPasswordMatch: string
  ): Observable<void> {
    const url = `${API_URL}auth/reset-password`;
    const body = {
      token,
      dateOfBirth,
      newPassword,
      newPasswordMatch,
    };
    return this.http.post<void>(url, body);
  }

  _loginWithMedian(match: any): Observable<{ token: string }> {
    const httpOptions = {
      headers: new HttpHeaders({
        'median-token': match,
      }),
    };
    return this.http.post<{ token: string }>(
      this.apiUrl + this.loginMedian,
      'null',
      httpOptions
    );
  }

  loginWithMedian(match: any) {
    return this._loginWithMedian(match).subscribe((response) => {
      this.sessionStorageService.setItem('medianParent', true);
      this.authByToken(response.token, '/home');
    });
  }

  private _login(
    username: string,
    password: string
  ): Observable<{ token: string }> {
    const url = `${API_URL}auth/signin`;

    const body = {
      username,
      password,
    };
    return this.http.post<{ token: string }>(url, body);
  }

  login(user: string, pass: string, remember?: boolean): Observable<any> {
    return this._login(user, pass).pipe(
      tap(({ token }) => {
        this.decodeToken(token);
      })
    );
  }

  decodeToken(token: string) {
    const decodedToken = this.jwtHelper.decodeToken(token);
    localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
    localStorage.setItem(LOCAL_STORAGE_KEY, stringify(decodedToken));
    this.account$.next(decodedToken);
  }

  updateRedirectUrl(redirectUrl: string) {
    if (this.redirectUrl) {
      redirectUrl = this.redirectUrl;
      this.redirectUrl = null;
    }
  }

  authByToken(token: string, redirectUrl: string) {
    this.decodeToken(token);
    this.updateRedirectUrl(redirectUrl);
    this.router.navigate([redirectUrl], {
      queryParams: { parent: 'median' },
    });
  }

  queueLogout(time: number): void {
    try {
      const data = parse(localStorage.getItem(LOCAL_STORAGE_KEY)!);
      data.queuedLogout = time;
      localStorage.setItem(LOCAL_STORAGE_KEY, stringify(data));
      this.account$.next(data);
    } catch (e) {
      console.warn('Invalid login data in storage!');
    }
  }

  dequeueLogout(): void {
    try {
      const data = parse(localStorage.getItem(LOCAL_STORAGE_KEY)!);
      localStorage.setItem(LOCAL_STORAGE_KEY, stringify(data));
      this.account$.next(data);
    } catch (e) {
      console.warn('Invalid login data in storage!');
    }
  }

  private clearStorage(): void {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
    localStorage.removeItem('feedbackEnabled');
    localStorage.removeItem('dialogFinishedTherapyDisplayed');
  }

  private logoutAtIntegrationMode(leaveMyMedianHomeOnly: boolean): void {
    this.clearStorage();
    if (leaveMyMedianHomeOnly) {
      this.medianAppApiService.medianLeave(MedianAppApi);
    } else {
      this.medianAppApiService.medianLogout(MedianAppApi);
    }
  }

  private logoutMyMedianHome(): void {
    this.clearStorage();
    this.account$.next(false);
  }

  logout(): void {
    if (this.medianAppApiService.getMedianParent()) {
      this.logoutAtIntegrationMode(false);
    } else {
      this.logoutMyMedianHome();
    }
  }

  leave(): void {
    if (this.medianAppApiService.getMedianParent()) {
      this.logoutAtIntegrationMode(true);
    } else {
      this.logoutMyMedianHome();
      this.router.navigateByUrl('/');
    }
  }

  postLoginMedianToken(token: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'median-token': token,
      }),
    };
    return this.http.post<any>(
      this.apiUrl + this.loginMedian,
      'null',
      httpOptions
    );
  }

  checkToken(token: string) {
    return this.jwtHelper.decodeToken(token);
  }
}

export type PopupDialogModel = {
  title: string;
  message: string;
  message2?: string;
  secondaryButtonText?: string;
  primaryButtonText?: string;
  baseButtonText?: string;
  hasCloseButton?: boolean;
};

export enum PopupDialogCloseReturnEnum {
  SECONDARY = 'SECONDARY',
  PRIMARY = 'PRIMARY',
  BASE = 'BASE',
  CLOSE = 'CLOSE',
}

export type PopupDialogCloseReturnModel =
  keyof typeof PopupDialogCloseReturnEnum;

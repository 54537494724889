import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthorizedApiService } from 'app/core/services/authentication/authorized-api.service';
import { API_URL } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountDto } from './account.dto';
import { AccountModel } from './account.model';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(private http: HttpClient, private token: AuthorizedApiService) {}

  getAccount(): Observable<AccountModel> {
    let url = `${API_URL}account`;
    const options = { headers: this.token.getHeaderConfig() };
    return this.http.get<AccountDto>(url, options).pipe(
      map((response) => {
        return response;
      })
    );
  }

  updateAccount(body: AccountModel): Observable<void> {
    let url = `${API_URL}account`;
    const options = { headers: this.token.getHeaderConfig() };
    return this.http.put<void>(url, body, options);
  }

  confirmEmail(token: string): Observable<any> {
    let url = `${API_URL}account/confirm`;
    return this.http.post<void>(url, { token });
  }

  resetPassword(
    password: string,
    passwordMatch: string,
    oldPassword: string
  ): Observable<void> {
    const headers = this.token.getHeaderConfig();
    const body = {
      password,
      passwordMatch,
      oldPassword,
    };
    return this.http.post<void>(API_URL + 'account/password', body, {
      headers,
    });
  }
}

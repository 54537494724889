import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ProgressTreeLevel } from 'app/core/services/patient/progress-tree-level.model';

@Component({
  selector: 'mdnra-new-milestone',
  templateUrl: './new-milestone.component.html',
  styleUrls: ['./new-milestone.component.scss'],
})
export class NewMilestoneComponent {
  constructor(
    private readonly router: Router,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      level: ProgressTreeLevel;
      missingLeaves: number;
      receivedLeaves: number;
      totalScore: number;
      closeOnly?: boolean;
    }
  ) {}

  close(): void {
    if (!this.data.closeOnly) {
      this.router.navigate(['/home']);
    }
    this.dialog.closeAll();
  }
}

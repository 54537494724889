import { Injectable } from '@angular/core';
import { AbstractStorage } from './abstract-storage';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService extends AbstractStorage {
  constructor() {
    super(window.sessionStorage);
  }
}

import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Observable, of, timer } from 'rxjs';
import { catchError, map, retry, switchMap } from 'rxjs/operators';
import { ResetPasswordService } from '../core/services/reset-password/reset-password.service';
import { retryStrategy } from './common';
import { HttpErrorResponse } from '@angular/common/http';

export const birthDateValidator =
  (resetPasswordService: ResetPasswordService, token: string) =>
  (control: AbstractControl): Observable<ValidationErrors | null> =>
    resetPasswordService
      .validateBirthDate(control.value.format('YYYY-MM-DD'), token)
      .pipe(
        retry({
          delay: retryStrategy({
            excludedStatusCodes: [400, 404, 422, 504, 0],
          }),
        }),
        map(() => null),
        catchError((error: HttpErrorResponse) => {
          if (error?.error?.fields?.dateOfBirth)
            return of({ [error.error.fields.dateOfBirth.pop()]: true });
          return of();
        })
      );

export const passwordValidator =
  (
    resetPasswordService: ResetPasswordService,
    confirmControl: AbstractControl,
    token: string
  ) =>
  (control: AbstractControl): Observable<ValidationErrors | null> =>
    timer(400).pipe(
      switchMap(() =>
        resetPasswordService.validatePassword(
          {
            password: control.value,
            confirmPassword: confirmControl.value,
          },
          token
        )
      ),
      retry({ delay: retryStrategy() }),
      map(() => null),
      catchError(({ error }) => {
        return of(
          error?.fields?.newPassword
            ? { [error.fields.newPassword[0]]: true }
            : null
        );
      })
    );

export const passwordConfirmValidator =
  (
    resetPasswordService: ResetPasswordService,
    passwordControl: AbstractControl,
    token: string
  ) =>
  (control: AbstractControl): Observable<ValidationErrors | null> =>
    timer(400).pipe(
      switchMap(() =>
        resetPasswordService.validatePassword(
          {
            password: passwordControl.value,
            confirmPassword: control.value,
          },
          token
        )
      ),
      retry({ delay: retryStrategy() }),
      map(() => null),
      catchError(({ error }) => {
        return of(
          error?.fields?.newPasswordMatch
            ? { [error.fields.newPasswordMatch[0]]: true }
            : null
        );
      })
    );

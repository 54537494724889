import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, retry, switchMap } from 'rxjs/operators';
import { retryStrategy } from '../common';
import { RegistrationService } from '../../core/services/registration/registration.service';

@Component({
  selector: 'mdnra-register',
  templateUrl: 'activate.component.html',
  styleUrls: ['../registration/registration.component.scss', './activate.component.scss'],
})
export class ActivateComponent {
  public resendError: boolean = false;
  public resendSuccess: boolean = false;
  imprint = false;

  expired$ = this.route.queryParams.pipe(map(({ expired }) => expired));
  #token$ = this.route.queryParams.pipe(map(({ token }) => token));

  constructor(
    private readonly route: ActivatedRoute,
    private readonly registrationService: RegistrationService
  ) {}

  resend(): void {
    this.#token$
      .pipe(
        switchMap((token) => this.registrationService.resendWithToken(token)),
        retry({ delay: retryStrategy() }),
        map(() => true)
      )
      .subscribe({
        next: () => {
          this.resendSuccess = true;
        },
        error: () => {
          this.resendError = true;
        },
      });
  }

  setImprint(imprint: boolean): void {
    this.imprint = imprint;
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MessagingService } from 'app/core/services/messaging/messaging.service';
import { ResetPasswordService } from '../../../core/services/reset-password/reset-password.service';
import { birthDateValidator } from '../../reset.common';
import { ResetBirthdayValidationModel } from './reset-birthday-validation.model';

@Component({
  selector: 'mdnra-reset-birthday',
  templateUrl: './reset-birthday.component.html',
  styleUrls: ['./reset-birthday.component.scss'],
})
export class ResetBirthdayComponent implements OnInit {
  @Input()
  token: string = '';

  @Output()
  changeStep: EventEmitter<ResetBirthdayValidationModel> =
    new EventEmitter<ResetBirthdayValidationModel>();

  public startDate: Date = new Date(1970, 1, 1);
  //TODO: Type form controls
  public dateOfBirthControl = new UntypedFormControl(null, {
    validators: [Validators.required],
  });
  public birthdayForm: UntypedFormGroup = this.formBuilder.group({
    birthday: this.dateOfBirthControl,
  });

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly messagingService: MessagingService,
    private readonly resetPasswordService: ResetPasswordService
  ) {}

  ngOnInit() {
    this.dateOfBirthControl.setAsyncValidators([
      birthDateValidator(this.resetPasswordService, this.token),
    ]);
  }

  nextStep() {
    const formattedBirthday =
      this.dateOfBirthControl.value.format('YYYY-MM-DD');
    this.changeStep.emit({
      birthday: formattedBirthday,
    });
  }

  getErrorMessage(): string {
    if (this.dateOfBirthControl?.errors) {
      return this.messagingService.getControlErrorMessage(
        this.dateOfBirthControl.errors
      );
    }
    return '';
  }

  validate(val: EventTarget | null): void {
    const value = (val as HTMLInputElement)?.value;
    const regexPattern = /^[0-9]{2}\.[0-9]{2}\.(19|20)[0-9]{2}$/i;

    if (!value) {
      this.dateOfBirthControl.setErrors({ birthdayRequired: false });
      return;
    }

    if (!regexPattern.test(value)) {
      this.dateOfBirthControl.setErrors({ birthdayPattern: false });
      return;
    }
  }
}

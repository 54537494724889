import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'mdnra-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent implements OnInit {
  @HostBinding('style.color') colorBtn: string = '#b46547';

  @Input()
  color: string = '#b46547';

  @Output()
  closePressed: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {
    this.colorBtn = this.color;
  }

  closePressedHandler(): void {
    this.closePressed.emit();
  }
}

import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { PatientService } from '../services/patient/patient.service';
import { ProgressTreeDto } from '../services/patient/progress-tree.dto';

@Injectable({
  providedIn: 'root',
})
export class ProgressResolverService
  
{
  constructor(private readonly patientService: PatientService) {}
  resolve(): Observable<ProgressTreeDto> {
    return this.patientService.getTreeProgress();
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';

import { MenuService } from './menu.service';

@Component({
  selector: 'mdnra-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  constructor(
    private readonly authService: AuthenticationService,
    private readonly menuService: MenuService
  ) {}

  ngOnInit(): void {
    this.menuService.isMenuVisibleSubject.next(true);
  }

  logout(): void {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this.menuService.isMenuVisibleSubject.next(false);
  }
}

<div *ngIf="!openDialog; else completeView" class="d-flex flex-column h-100">
  <div mat-dialog-title>
    <button
      mat-button
      class="ps-0 fs-6 mb-2"
      color="primary"
      [mat-dialog-close]="false"
    >
      <mat-icon fontSet="fas" fontIcon="fa-long-arrow-left"></mat-icon>
      <span>
        {{ "COMMON.BACK" | translate }}
      </span>
    </button>
    <h2>
      {{ "HOME.YOUR_WEEKLY_GOAL.DIALOG.TITLE" | translate }}
    </h2>
    <p>
      {{ "HOME.YOUR_WEEKLY_GOAL.DIALOG.DESCRIPTION" | translate }}
    </p>
  </div>

  <div mat-dialog-content class="mh-100">
    <form id="ambition" [formGroup]="goalForm" (ngSubmit)="send()">
      <div
        class="text-area-header d-flex justify-content-between align-items-center"
      >
        <p>
          {{ "HOME.YOUR_WEEKLY_GOAL.DIALOG.HEADER" | translate }}
        </p>
        <i class="far fa-bullseye-arrow"></i>
      </div>
      <input formControlName="id" type="hidden" autofocus="true" />

      <quill-editor
        class="d-block"
        formControlName="ambition"
        ngDefaultControl
        [modules]="editorOptions"
        [(ngModel)]="currentGoal"
        placeholder="{{
          'HOME.YOUR_WEEKLY_GOAL.DIALOG.EMPTY_MESSAGE' | translate
        }}"
        [styles]="{ height: '200px' }"
      ></quill-editor>
    </form>
  </div>
  <div
    mat-dialog-actions
    class="justify-content-between align-items-center p-4"
  >
    <div>
      <button
        mat-stroked-button
        [mat-dialog-close]="false"
        type="button"
        class="mdnra-button"
      >
        {{ "HOME.YOUR_WEEKLY_GOAL.DIALOG.DONT_SET_GOAL" | translate }}
      </button>
    </div>
    <button
      mat-flat-button
      color="primary"
      class="mdnra-button"
      type="submit"
      form="ambition"
      [disabled]="goalForm.invalid || submittingGoal"
    >
      {{ "HOME.YOUR_WEEKLY_GOAL.DIALOG.SET_GOAL" | translate }}
    </button>
  </div>
</div>

<ng-template #completeView>
  <mdnra-complete-view
    [title]="'HOME.YOUR_WEEKLY_GOAL.SET.TITLE'"
    [description]="'HOME.YOUR_WEEKLY_GOAL.SET.DESCRIPTION'"
    [receivedPoints]="responseData!.leavesDelta"
    [redirectLabel]="'COMMON.TO_HOME'"
    [totalPoints]="responseData!.leaves"
    (closePressed)="closeDialog()"
    (redirectPressed)="closeDialog()"
  >
  </mdnra-complete-view>
</ng-template>

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mdnra-scales',
  templateUrl: './scales.component.html',
  styleUrls: ['./scales.component.scss'],
})
export class ScalesComponent {
  @Input()
  scales: { name: string; color: string }[] = [];

  @Output()
  selected: EventEmitter<number> = new EventEmitter();

  public selectedLevel: number = -1;

  constructor() {}

  getLevel(idx: number, score: number): number {
    return this.scales.length * 2 - (2 * idx + score) + 1;
  }

  public onSelect(idx: number, score: number): void {
    this.selectedLevel = this.getLevel(idx, score);
    this.selected.emit(this.selectedLevel);
  }
}

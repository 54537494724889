import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Route,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { MessagingService } from 'app/core/services/messaging/messaging.service';
import {
  ConfigService,
  OTP_CONFIG_NAME,
} from 'app/core/services/config/config.service';
import { SessionStorageService } from 'app/shared/services/session-storage.service';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { IS_SHARING_DEVICE_KEY } from './share-auth/share-auth.component';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';

@Injectable()
export class AuthenticationGuard {
  #canLoadUrl: string | null = null;
  shareRedirectUrl: string = '';

  constructor(
    private readonly sessionStorage: SessionStorageService,
    private readonly configService: ConfigService,
    private readonly messagingService: MessagingService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private location: Location
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const url: string = state.url;
    if (url.startsWith('/start/')) return of(true);

    this.setShareRedirectUrl();
    return this.checkLogin(url, route.data?.authUrl || ['login']);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): Observable<boolean> {
    const url = `${this.location.path()}`;
    if (url.startsWith('/start/')) return of(true);

    this.setShareRedirectUrl();
    return this.checkLogin(this.#canLoadUrl || url, route.data?.authUrl);
  }

  checkLogin(url: string, authUrl: string[]): Observable<boolean> {
    return this.authenticationService.account$.pipe(
      take(1),
      map(({ exp, ...other }) => {
        if (exp && +new Date(exp * 1000) < +new Date()) {
          this.authenticationService.logout();
          other = {};
        } else {
          this.authenticationService.dequeueLogout();
        }
        // TODO: check something else, like account id, when it will be present
        if (Object.keys(other).length) {
          return true;
        }
        // store redirectUrl
        this.authenticationService.redirectUrl = url;

        setTimeout(() => {
          if (this.shareRedirectUrl) {
            window.location.href = this.shareRedirectUrl;
          } else {
            this.router.navigate(authUrl);
          }
        });
        return false;
      })
    );
  }

  private setShareRedirectUrl() {
    if (this.sessionStorage.getItem(IS_SHARING_DEVICE_KEY)) {
      this.configService.getItem(OTP_CONFIG_NAME).subscribe({
        next: (redirectUrl) => {
          this.shareRedirectUrl = redirectUrl;
        },
        error: (error) => {
          this.messagingService.pushServerError(error);
        },
      });
    }
  }
}

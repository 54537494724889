import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { retryStrategy } from './common';
import { AccountService } from 'app/core/services/account/account.service';

@Injectable()
export class EmailConfirmGuard {
  constructor(
    private readonly accountService: AccountService,
    private readonly router: Router
  ) {}
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    if (route.queryParams.expired) {
      return of(true);
    }
    return this.accountService.confirmEmail(route.queryParams.token).pipe(
      retry({ delay: retryStrategy({ maxRetryAttempts: 3 }) }),
      map(() => true),
      catchError(() => {
        return of(
          this.router.parseUrl(
            `/email-confirm?token=${route.queryParams.token}&expired=1`
          )
        );
      })
    );
  }
}

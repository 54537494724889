import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CountUnreadMessageModal } from 'app/core/services/patient/count-unread-message.modal';

@Component({
  selector: 'mdnra-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnChanges {
  public unreadMessageCount: number;

  constructor(private router: Router, private activeRoute: ActivatedRoute) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.numberUnreadMessages) {
      this.unreadMessageCount =
        changes.numberUnreadMessages.currentValue?.unreadMessageCount;
    }
  }

  @Input()
  hasX = false;

  @Input()
  marginSmall = false;

  @Input()
  hasBack = false;

  @Input()
  hasMedianLogo = false;

  @Input()
  hasMessagesIcon = false;

  @Input()
  numberUnreadMessages: CountUnreadMessageModal | null;

  @Input()
  title = '';

  @Input()
  description = '';

  @Input()
  imgLink = '';

  @Input()
  icon = '';

  @Input()
  colorScheme = 'home';

  @Input()
  backUrl = '';

  goBack(): void {
    this.router.navigate([this.backUrl ? this.backUrl : '.'], {
      relativeTo: this.activeRoute.parent,
    });
  }

  goToChat(): void {
    this.router.navigate(['./more/help-contact']);
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessagingService } from 'app/core/services/messaging/messaging.service';
import {
  LOCAL_STORAGE_KEY,
  LOCAL_STORAGE_TOKEN_KEY,
} from './authentication.service';

type HeaderConfig = { authorization: string; 'X-Request-Id'?: string };

@Injectable()
export class AuthorizedApiService {
  constructor(
    readonly router: Router,
    readonly messagingService: MessagingService
  ) {}

  getHeaderConfig(): HeaderConfig {
    let token = this.getToken();
    let uuid = this.getUuid();

    const headerConfig: HeaderConfig = { authorization: '' };

    if (token && !token?.toString().startsWith('Bearer')) {
      token = 'Bearer ' + token;
    }
    headerConfig.authorization = token;

    if (uuid) {
      headerConfig['X-Request-Id'] = uuid;
    }

    return headerConfig;
  }

  goToLoginOnError(error: HttpErrorResponse): void {
    if (error.status === 401) {
      setTimeout(() => {
        this.router.navigateByUrl('/login', { skipLocationChange: true });
      });
    }
  }

  private getToken(): string {
    return localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) as string;
  }

  private getUuid(): string | null {
    const userObject = localStorage.getItem(LOCAL_STORAGE_KEY) as string;

    const parsedUserObject = JSON.parse(userObject);

    if (!parsedUserObject) return null;

    return parsedUserObject['patient-uuid'];
  }
}

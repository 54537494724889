<mdnra-main-layout
  [title]="'HOME.TITLE' | translate"
  [description]="'HOME.DESCRIPTION' | translate"
  [colorScheme]="'home' | translate"
  [hasMedianLogo]="true"
  [marginSmall]="true"
  [numberUnreadMessages]="numberUnreadMessages$ | async"
  [hasMessagesIcon]="isFeedbackEnabled"
>
  <div slot="header-content">
    <div class="tree-wrapper">
      <mdnra-tree
        class="tree"
        [forHome]="true"
        routerLink="milestones"
      ></mdnra-tree>
      <p class="tree-description">{{ treeMessage }}</p>
    </div>
    <div class="header-menu-wrapper">
      <button
        mat-flat-button
        class="header-menu-button therapy"
        routerLink="/therapy"
        routerLinkActive="is-active"
      >
        <mat-icon
          class="menu-icon"
          fontSet="far"
          fontIcon="fa-walking"
        ></mat-icon>
        <span>{{ "MENU.THERAPY" | translate }}</span>
      </button>
      <button
        mat-flat-button
        class="header-menu-button wellbeing"
        routerLink="/wellbeing"
        routerLinkActive="is-active"
      >
        <mat-icon
          class="menu-icon"
          fontSet="far"
          fontIcon="fa-smile"
        ></mat-icon>
        <span>{{ "MENU.WELLBEING" | translate }}</span>
      </button>
      <button
        mat-flat-button
        class="header-menu-button knowledge"
        routerLink="/knowledge"
        routerLinkActive="is-active"
      >
        <mat-icon
          class="menu-icon"
          fontSet="far"
          fontIcon="fa-lightbulb-on"
        ></mat-icon>
        <span>{{ "MENU.KNOWLEDGE" | translate }}</span>
      </button>
    </div>
    <i class="far fa-angle-down"></i>
  </div>
  <div slot="main-content">
    <mdnra-weekly-goal-card></mdnra-weekly-goal-card>
    <mdnra-enter-steps-card
      [therapyStartDay]="therapyStartDay"
    ></mdnra-enter-steps-card>
  </div>
</mdnra-main-layout>

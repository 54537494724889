<div class="form-wrapper">
  <form
    (ngSubmit)="forgot()"
    class="main-form"
    [formGroup]="forgotForm"
    autocomplete="off"
  >
    <section *ngIf="requestSent === false" class="section">
      <h2>{{ "REGISTRATION.FORGOT_PASS" | translate }}</h2>
      <p>{{ "REGISTRATION.FORGOT_PASS_DESC" | translate }}</p>
      <div class="forgot-pass-middle pass-field">
        <mat-form-field appearance="fill" class="pass-field">
          <mat-label>{{ "REGISTRATION.EMAIL" | translate }}</mat-label>
          <input matInput formControlName="email" autocomplete="off" />

          <mat-error *ngIf="emailControl.errors?.['required']">{{
            "REGISTRATION.ERRORS.EMAIL.GENERAL_NOT_BLANK" | translate
          }}</mat-error>

          <mat-error *ngIf="emailControl.errors?.['email']">{{
            "REGISTRATION.ERRORS.EMAIL.GENERAL_USERNAME_INVALID_FORMAT"
              | translate
          }}</mat-error>
        </mat-form-field>
        <h5 *ngIf="requestFailed">
          {{ "REGISTRATION.ERRORS.EMAIL.EMAIL_NOT_SENT_DESC" | translate }}
        </h5>

        <div class="bottom">
          <div class="bottom-buttons">
            <button
              mat-stroked-button
              type="button"
              (click)="backToLogin()"
              class="next-button back"
            >
              {{ "COMMON.BACK" | translate }}
            </button>
            <button
              type="submit"
              mat-flat-button
              color="primary"
              class="next-button send-mail"
              [disabled]="emailControl.invalid"
            >
              {{ "REGISTRATION.SEND_MAIL" | translate }}
            </button>
          </div>
        </div>
      </div>
    </section>

    <section *ngIf="requestSent === true" class="section narrow">
      <h2>{{ "REGISTRATION.FORGOT_PASS" | translate }}</h2>
      <p [innerHtml]="'LOGIN.FORGOT_PASS_DESC' | translate"></p>
      <a class="link forgot-email" mat-button type="submit" (click)="forgot()">
        <h5>{{ "REGISTRATION.SEND_AGAIN" | translate }}</h5>
      </a>
      <div class="bottom">
        <button
          class="next-button"
          mat-flat-button
          type="button"
          routerLink="../login"
        >
          {{ "REGISTRATION.TO_LOGIN" | translate }}
        </button>
      </div>
    </section>
  </form>
</div>

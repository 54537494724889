import {
  Component,
  ContentChild,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Video } from 'app/modules/knowledge/video.model';
import { Audio } from 'app/modules/wellbeing/audio.model';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';

@Component({
  selector: 'mdnra-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent {
  @Input()
  slidesData: (Audio | Video)[] = [];
  @Input()
  hasAutoWidth: boolean;

  @ContentChild(TemplateRef) slideTemplate!: TemplateRef<any>;

  public customOptions: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    margin: 16,
    stagePadding: 1,
    nav: true,
    navText: [
      '<i class="fas fa-long-arrow-left"></i>',
      '<i class="fas fa-long-arrow-right"></i>',
    ],
    responsive: {
      0: {
        items: 2,
      },
      426: {
        items: 3,
      },
      569: {
        items: 4,
      },
      768: {
        items: 5,
      },
    },
  };
}

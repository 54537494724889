declare var require: any;
 
declare var require: any;

export const IS_PRODUCTION = false;
export const API_URL =
  window.location.href.match('^((http|https)?://)?(www.)?([^/]+)*')?.[0] +
  '/api/';
export const MEDIAN_LOGIN = 'median/login';

export const environment = {
  API_URL:
    window.location.href.match('^((http|https)?://)?(www.)?([^/]+)*')?.[0] +
    '/api/',
  MEDIAN_LOGIN: 'median/login',
  APP_VERSION: require('../../package.json').version,
};

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessagingService } from 'app/core/services/messaging/messaging.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { retryStrategy } from '../common';
import { BirthdayStepModel } from './registration-birthday/registration-birthday.model';
import { ContactStepModel } from './registration-contact/registration-contact.model';
import { PasswordStepModel } from './registration-password/registration-password.model';
import { TermsStepModel } from './registration-terms/registration-terms.model';
import { Direction, RegistrationModel } from './registration.model';
import { RegistrationService } from '../../core/services/registration/registration.service';

type Step =
  | 'introStep'
  | 'termsStep'
  | 'passwordStep'
  | 'birthdayStep'
  | 'contactStep'
  | 'checkEmailStep';

@Component({
  selector: 'mdnra-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  private step: BehaviorSubject<Step> = new BehaviorSubject<Step>('introStep');
  public currentStep: Observable<Step> = this.step.asObservable();

  public privacy: boolean = false;
  public imprint: boolean = false;

  public isRegistrationProcess: boolean = false;

  public registrationData: RegistrationModel = {
    token: '',
    terms: {
      isPrivacyPolicy: false,
      isAnalytics: false,
    },
    pass: {
      password: '',
      confirmPassword: '',
    },
    birth: {
      birthday: '',
    },
    contact: {
      phone: '',
      email: '',
    },
  };

  private stepNames: Step[] = [
    'introStep',
    'termsStep',
    'passwordStep',
    'birthdayStep',
    'contactStep',
    'checkEmailStep',
  ];

  public isTokenValid = false;
  public registerToken: string = '';

  constructor(
    private readonly route: ActivatedRoute,
    private readonly registrationService: RegistrationService,
    private readonly messagingService: MessagingService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.registrationData.token = params.token;

      if (params.token) {
        this.registrationService
          .verifyInvite(params.token)
          .pipe(retry({ delay: retryStrategy() }))
          .subscribe({
            next: () => {
              this.isTokenValid = true;
            },
            error: (error) => {
              this.messagingService.pushServerError(error);
            },
          });
      }
    });
  }

  startRegistrationProcess() {
    this.isRegistrationProcess = true;
    this.changeStep(this.stepNames[0], Direction.FORWARD);
  }

  saveTermsStep(event: TermsStepModel) {
    this.registrationData.terms.isPrivacyPolicy = event.data.isPrivacyPolicy;
    this.registrationData.terms.isAnalytics = event.data.isAnalytics;
    this.changeStep(this.stepNames[1], event.direction);
  }

  savePassStep(event: PasswordStepModel) {
    this.registrationData.pass.password = event.data.password;
    this.registrationData.pass.confirmPassword = event.data.confirmPassword;
    this.changeStep(this.stepNames[2], event.direction);
  }

  saveBirthdayStep(event: BirthdayStepModel) {
    this.registrationData.birth.birthday = event.data.birthday;
    this.changeStep(this.stepNames[3], event.direction);
  }

  saveContactStep(event: ContactStepModel) {
    this.registrationData.contact.email = event.data.email?.trim();
    this.registrationData.contact.phone = event.data.phone?.trim();
    if (event.direction === Direction.FORWARD) {
      this.register();
    }
    this.changeStep(this.stepNames[4], event.direction);
  }

  changeStep(currentStep: Step, direction: Direction) {
    const stepIdx = this.stepNames.indexOf(currentStep);
    if (direction === Direction.BACK && stepIdx > 0) {
      this.step.next(this.stepNames[stepIdx - 1]);
    }
    if (direction === Direction.FORWARD && stepIdx < this.stepNames.length) {
      this.step.next(this.stepNames[stepIdx + 1]);
    }
  }

  private register() {
    const registerRequest = {
      token: this.registrationData.token,
      password: this.registrationData.pass.password,
      passwordMatch: this.registrationData.pass.confirmPassword,
      dateOfBirth: this.registrationData.birth.birthday,
      username: this.registrationData.contact.email,
      phoneNumber: this.registrationData.contact.phone,
      agreedDataProtection: this.registrationData.terms.isPrivacyPolicy,
      agreedCrashlytics: this.registrationData.terms.isAnalytics,
    };
    this.registrationService.register(registerRequest).subscribe(() => {
      this.changeStep(this.stepNames[4], Direction.FORWARD);
    });
  }

  setImprint(imprint: boolean): void {
    this.imprint = imprint;
  }

  setPrivacy(privacy: boolean): void {
    this.privacy = privacy;
  }
}

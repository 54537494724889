import { Injectable, NgModule } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterModule,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { ActivateComponent } from './activate/activate.component';
import { retryStrategy } from './common';
import { ForgotComponent } from './forgot/forgot.component';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { RegistrationService } from '../core/services/registration/registration.service';
import { ResetComponent } from './reset/reset.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { EmailConfirmGuard } from './confirmEmail.guard';

@Injectable()
export class CanActivateActivate {
  constructor(
    private readonly registrationService: RegistrationService,
    private readonly router: Router
  ) {}
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    if (route.queryParams.expired) {
      return of(true);
    }

    return this.registrationService.activate(route.queryParams.token).pipe(
      retry({ delay: retryStrategy() }),
      map(() => true),
      catchError((error) => {
        if (
          error.status === 400 &&
          error.error.fields['token'].includes('GENERAL_INVALID_TOKEN')
        ) {
          return of(
            this.router.parseUrl(
              `/verify?token=${route.queryParams.token}&expired=1`
            )
          );
        }
        return of(this.router.parseUrl('/login'));
      })
    );
  }
}

@NgModule({
  imports: [
    RouterModule.forChild([
      { path: 'login', component: LoginComponent },
      // TODO: activate guard to check token beforehand?
      { path: 'register/:token', component: RegistrationComponent },
      { path: 'register', component: RegistrationComponent },
      {
        path: 'verify',
        canActivate: [CanActivateActivate],
        component: ActivateComponent,
      },
      {
        path: 'email-confirm',
        canActivate: [EmailConfirmGuard],
        component: ConfirmEmailComponent,
      },
      { path: 'forgot', component: ForgotComponent },
      { path: 'reset', component: ResetComponent },
    ]),
  ],
  exports: [RouterModule],
  providers: [CanActivateActivate],
})
export class AuthenticationRoutingModule {}

<mat-card
  class="card-layout"
  [ngClass]="colorScheme"
  [class.icon-background]="iconBackground"
  [class.main-page]="mainPage"
  (click)="navigate()"
>
  <div class="wrapper">
    <i *ngIf="icon" class="card-icon far {{ icon }}"></i>
    <h6 *ngIf="header !== ''" class="header">
      <span>{{ header }}</span>
    </h6>
    <h3 *ngIf="title !== ''" class="title">
      <span [innerHTML]="title"></span>
    </h3>
    <div
      *ngIf="description !== ''"
      class="description"
      [innerHTML]="description"
    ></div>
    <button
      mat-stroked-button
      class="mdnra-button card-button"
      *ngIf="detailLinkLabel"
      [routerLink]="detailLink"
      (click)="navigate()"
    >
      {{ detailLinkLabel }}
    </button>
    <h5 *ngIf="footer !== ''" class="footer">
      <span>{{ footer }}</span>
    </h5>
    <i
      *ngIf="iconBackground"
      class="card-icon-background fal {{ iconBackground }}"
    ></i>
    <img
      *ngIf="imgUrl"
      class="card-image"
      src="{{ imgUrl }}"
    />
  </div>
</mat-card>

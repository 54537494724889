import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  public isMenuVisibleSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
}

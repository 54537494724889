<div class="scales-container">
  <div
    *ngFor="
      let scale of scales;
      let idx = index;
      first as isFirst;
      last as isLast
    "
  >
    <div class="pain-level">
      <div
        class="pain-name"
        [ngStyle]="{ 'background-color': scale.color }"
        [ngClass]="{ first: isFirst, last: isLast }"
      >
        {{ scale.name | translate }}
      </div>
      <div class="pain-level-scores">
        <div class="pain-level-score" *ngFor="let score of [1, 2]">
          <div class="line"></div>
          <button
            class="pain-level-button"
            [ngClass]="{
              'selected-value': getLevel(idx, score) === selectedLevel
            }"
            (click)="onSelect(idx, score)"
          >
            {{ getLevel(idx, score) }}
          </button>
        </div>

        <div class="pain-level-scores last" *ngIf="isLast">
          <div class="pain-level-score">
            <div class="line"></div>
            <button
              class="pain-level-button"
              [ngClass]="{ 'selected-value': 0 === selectedLevel }"
              (click)="onSelect(scales.length, 1)"
            >
              0
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProgressTreeModel } from 'app/core/services/patient/progress-tree.model';
import { API_URL } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthorizedApiService } from '../authentication/authorized-api.service';
import { ProgressTreeDto } from '../patient/progress-tree.dto';
import { PatientStepsNewModel } from './patient-steps-new.model';
import { PatientStepsDto } from './patient-steps.dto';
import { PatientStepsModel } from './patient-steps.model';

@Injectable({
  providedIn: 'root',
})
export class PatientStepsService {
  constructor(
    private readonly http: HttpClient,
    private readonly token: AuthorizedApiService
  ) {}

  getCurrentSteps(): Observable<PatientStepsModel> {
    let url = `${API_URL}steps`;
    const options = { headers: this.token.getHeaderConfig() };
    return this.http.get<PatientStepsDto>(url, options).pipe(
      map((response) => {
        return response;
      })
    );
  }

  addSteps(body: PatientStepsNewModel): Observable<ProgressTreeModel> {
    let url = `${API_URL}steps`;
    const options = { headers: this.token.getHeaderConfig() };
    return this.http.post<ProgressTreeDto>(url, body, options);
  }
}

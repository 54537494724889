<ng-container *ngIf="!data.succeeded">
  <mdnra-complete-view
    (closePressed)="closeDialog()"
    (redirectPressed)="redirectSetGoal()"
    [description]="'HOME.YOUR_WEEKLY_GOAL.FINISHED.INCOMPLETE.DESC'"
    [disableAnimation]="true"
    [redirectLabel]="'HOME.YOUR_WEEKLY_GOAL.FINISHED.SET_NEW_GOAL'"
    [title]="'HOME.YOUR_WEEKLY_GOAL.FINISHED.INCOMPLETE.TITLE'"
    [totalPoints]="data.score"
  >
  </mdnra-complete-view>
</ng-container>
<ng-container *ngIf="data.succeeded">
  <mdnra-complete-view
    (closePressed)="closeDialog()"
    (redirectPressed)="redirectSetGoal()"
    [description]="'HOME.YOUR_WEEKLY_GOAL.FINISHED.COMPLETE.DESC'"
    [receivedPoints]="data.scoreDelta"
    [redirectLabel]="'HOME.YOUR_WEEKLY_GOAL.FINISHED.SET_NEW_GOAL'"
    [title]="'HOME.YOUR_WEEKLY_GOAL.FINISHED.COMPLETE.TITLE'"
    [totalPoints]="data.score"
  >
  </mdnra-complete-view>
</ng-container>

import { Injectable } from '@angular/core';
import { MedianAppApiModel } from '../dto/median-app-api.dto';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class MedianAppApiService {
  constructor(private sessionStorageService: SessionStorageService) {}

  getMedianParent(): boolean {
    return !!this.sessionStorageService.getItem('medianParent');
  }

  medianToggleFullscreen(medianAppApi: MedianAppApiModel) {
    medianAppApi.TWT.toggleFullscreen();
  }

  medianEnableFullscreen(medianAppApi: MedianAppApiModel) {
    medianAppApi.TWT.enableFullscreen();
  }

  medianDisableFullscreen(medianAppApi: MedianAppApiModel) {
    medianAppApi.TWT.disableFullscreen();
  }

  medianHideTabbar(medianAppApi: MedianAppApiModel) {
    medianAppApi.TWT.hideTabbar();
  }

  medianShowTabbar(medianAppApi: MedianAppApiModel) {
    medianAppApi.TWT.showTabbar();
  }

  medianHideHeaderbar(medianAppApi: MedianAppApiModel) {
    medianAppApi.TWT.hideHeaderbar();
  }

  medianShowHeaderbar(medianAppApi: MedianAppApiModel) {
    medianAppApi.TWT.showHeaderbar();
  }

  medianLogout(medianAppApi: MedianAppApiModel) {
    medianAppApi.TWT.logout();
  }

  medianLeave(medianAppApi: MedianAppApiModel) {
    medianAppApi.TWT.leave();
  }

  isLoadedInMedian(medianAppApi: MedianAppApiModel): boolean {
    return !!(typeof medianAppApi !== 'undefined' && medianAppApi?.TWT);
  }
}

<vg-player>
  <vg-overlay-play></vg-overlay-play>
  <video
    #media
    [vgMedia]="$any(media)"
    id="singleVideo"
    preload="auto"
    [loop]="loop"
    [autoplay]="autoplay"
    playsinline
    (seeked)="onVideoEndReached()"
  >
    <source [src]="videoSource" type="video/mp4" />
  </video>
</vg-player>


<owl-carousel-o
  [options]="customOptions"
  #owlCar
  class="carousel"
  [ngClass]="{ 'auto-width': hasAutoWidth }"
>
  <ng-container *ngFor="let slide of slidesData">
    <ng-template carouselSlide [id]="'id: ' + slide.id" dotContent="content">
      <ng-template
        [ngTemplateOutlet]="slideTemplate"
        [ngTemplateOutletContext]="{ $implicit: slide }"
      ></ng-template>
    </ng-template>
  </ng-container>
</owl-carousel-o>

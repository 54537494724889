<section class="section">
  <h2>{{ "REGISTRATION.TERMS_TITLE" | translate }}</h2>
  <p>{{ "REGISTRATION.TERMS_DESC" | translate }}</p>
  <div class="middle" [formGroup]="termsForm">
    <div class="checkbox-wrapper">
      <mat-checkbox
        class="checkbox"
        formControlName="isPrivacyPolicy"
        (change)="updateData()"
      >
      </mat-checkbox>
      <p class="checkbox-desc">
        {{ "REGISTRATION.PRIVACY_POLICY.0" | translate
        }}<a class="link data" (click)="displayPrivacy()">{{
          "REGISTRATION.PRIVACY_POLICY.1" | translate
        }}</a
        >{{ "REGISTRATION.PRIVACY_POLICY.2" | translate }}
      </p>
    </div>
    <div class="checkbox-wrapper">
      <mat-checkbox
        class="checkbox"
        formControlName="isAnalytics"
        (change)="updateData()"
      >
      </mat-checkbox>
      <p class="checkbox-desc">
        {{ "REGISTRATION.ANALYTICS" | translate }}
      </p>
    </div>
    <div class="bottom">
      <button
        class="next-button"
        mat-flat-button
        [disabled]="
          termsForm.controls['isPrivacyPolicy'].invalid || termsForm.pending
        "
        type="button"
        (click)="nextStep()"
      >
        {{ "COMMON.NEXT" | translate }}
      </button>
    </div>
  </div>
</section>

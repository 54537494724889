import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { BackButtonComponent } from './component/back-button/back-button.component';
import { CloseButtonComponent } from './component/close-button/close-button.component';
import { CountdownTimerModalComponent } from './component/countdown-timer-modal/countdown-timer-modal.component';
import { GotoButtonComponent } from './component/goto-button/goto-button.component';
import { ItemsIteratorComponent } from './component/items-iterator/items-iterator.component';
import { PopupDialogComponent } from './component/popup-dialog/popup-dialog.component';
import { ReleaseInfoComponent } from './component/release-info/release-info.component';
import { ScalesComponent } from './component/scales/scales.component';
import { SliderComponent } from './component/slider/slider.component';
import { VideoPlayerComponent } from './component/video-player/video-player.component';
import { VoiceTimerComponent } from './component/voice-timer/voice-timer.component';
import { CarouselComponent } from './component/carousel/carousel.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { SectionLayoutComponent } from './layout/section-layout/section-layout.component';
import { CardLayoutComponent } from './layout/card-layout/card-layout.component';
import { CardPreviousTrainingLayoutComponent } from './layout/card-previous-training-layout/card-previous-training-layout.component';
import { CardListComponent } from './layout/card-list/card-list.component';
import { QuestionLayoutComponent } from './layout/question-layout/question-layout.component';
import { MenuComponent } from './layout/menu/menu.component';
import { CompleteViewComponent } from './layout/complete-view/complete-view.component';
import { TreeComponent } from './layout/tree/tree.component';
import { ImprintComponent } from './layout/imprint/imprint.component';
import { DataProtectionComponent } from './layout/data-protection/data-protection.component';
import { HomeTreeComponent } from './layout/tree/home-tree/home-tree.component';
import { ProgressTreeComponent } from './layout/tree/progress-tree/progress-tree.component';
import { CarouselItemComponent } from './component/carousel/carousel-item/carousel-item.component';
import { MdnraButtonLinkComponent } from './component/button-link/button-link.component';

@NgModule({
  declarations: [
    BackButtonComponent,
    CloseButtonComponent,
    PopupDialogComponent,
    CountdownTimerModalComponent,
    GotoButtonComponent,
    ItemsIteratorComponent,
    ReleaseInfoComponent,
    ScalesComponent,
    SliderComponent,
    VideoPlayerComponent,
    VoiceTimerComponent,
    CarouselComponent,
    MainLayoutComponent,
    SectionLayoutComponent,
    CardLayoutComponent,
    CardPreviousTrainingLayoutComponent,
    CardListComponent,
    QuestionLayoutComponent,
    MenuComponent,
    CompleteViewComponent,
    TreeComponent,
    ImprintComponent,
    DataProtectionComponent,
    HomeTreeComponent,
    ProgressTreeComponent,
    CarouselItemComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forChild(),
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    FormsModule,
    MatSliderModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    CarouselModule,
    MdnraButtonLinkComponent,
  ],
  exports: [
    BackButtonComponent,
    CloseButtonComponent,
    PopupDialogComponent,
    CountdownTimerModalComponent,
    GotoButtonComponent,
    ItemsIteratorComponent,
    ReleaseInfoComponent,
    ScalesComponent,
    SliderComponent,
    VideoPlayerComponent,
    VoiceTimerComponent,
    CarouselComponent,
    CarouselItemComponent,
    MainLayoutComponent,
    SectionLayoutComponent,
    CardLayoutComponent,
    CardPreviousTrainingLayoutComponent,
    CardListComponent,
    QuestionLayoutComponent,
    MenuComponent,
    CompleteViewComponent,
    TreeComponent,
    ImprintComponent,
    DataProtectionComponent,
  ],
})
export class SharedModule {}

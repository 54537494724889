import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { birthDateValidator } from 'app/authentication/common';
import { MessagingService } from 'app/core/services/messaging/messaging.service';
import { Direction } from '../registration.model';
import { RegistrationService } from '../../../core/services/registration/registration.service';
import {
  BirthdayStepModel,
  RegistrationBirthdayModel,
} from './registration-birthday.model';

@Component({
  selector: 'mdnra-registration-birthday',
  templateUrl: './registration-birthday.component.html',
  styleUrls: ['./registration-birthday.component.scss'],
})
export class RegistrationBirthdayComponent implements OnInit {
  @Input()
  token: string = '';

  @Input()
  hasBackButton: boolean = false;

  @Input()
  data: RegistrationBirthdayModel = {
    birthday: '',
  };

  @Output()
  updateStepData: EventEmitter<RegistrationBirthdayModel> =
    new EventEmitter<RegistrationBirthdayModel>();

  @Output()
  changeStep: EventEmitter<BirthdayStepModel> =
    new EventEmitter<BirthdayStepModel>();

  public startDate: Date = new Date(1970, 1, 1);
  //TODO: Type form controls
  public dateOfBirthControl = new UntypedFormControl(this.data.birthday, {
    validators: [Validators.required],
  });
  public birthdayForm: UntypedFormGroup = this.formBuilder.group({
    birthday: this.dateOfBirthControl,
  });

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly registrationService: RegistrationService,
    private readonly messagingService: MessagingService
  ) {}

  ngOnInit(): void {
    this.dateOfBirthControl.setAsyncValidators([
      birthDateValidator(this.registrationService, this.token),
    ]);
  }

  updateData(eventTarget: EventTarget | null) {
    this.validate(eventTarget);

    this.updateStepData.emit({
      birthday: this.dateOfBirthControl.value,
    });
  }

  previousStep() {
    this.changeStep.emit({
      data: {
        birthday: this.dateOfBirthControl.value,
      },
      direction: Direction.BACK,
    });
  }

  nextStep() {
    this.changeStep.emit({
      data: {
        birthday: this.dateOfBirthControl.value.format('YYYY-MM-DD'),
      },
      direction: Direction.FORWARD,
    });
  }

  getErrorMessage(): string {
    if (this.dateOfBirthControl?.errors) {
      return this.messagingService.getControlErrorMessage(
        this.dateOfBirthControl.errors
      );
    }
    return '';
  }

  validate(val: EventTarget | null): void {
    const value = (val as HTMLInputElement)?.value;
    const regexPattern = /^[0-9]{2}\.[0-9]{2}\.(19|20)[0-9]{2}$/i;

    if (!value) {
      this.dateOfBirthControl.setErrors({ birthdayRequired: false });
      return;
    }

    if (!regexPattern.test(value)) {
      this.dateOfBirthControl.setErrors({ birthdayPattern: false });
      return;
    }
  }
}

<div class="d-flex justify-content-between align-items-center">
  <h2 *ngIf="data.title" mat-dialog-title class="fw-bold">{{ data.title }}</h2>
  <button
    *ngIf="data.hasCloseButton"
    mat-icon-button
    class="me-2"
    [mat-dialog-close]="'CLOSE'"
  >
    <mat-icon
      class="fs-3"
      fontSet="fal"
      fontIcon="fa-times"
      color="primary"
    ></mat-icon>
  </button>
</div>

<p *ngIf="data.message" class="mx-4">
  {{ data.message }}
</p>

<p *ngIf="data.message2" class="mx-4">
  {{ data.message2 }}
</p>

<div mat-dialog-actions class="justify-content-between">
  <div>
    <button
      *ngIf="data.secondaryButtonText"
      mat-button
      [mat-dialog-close]="'SECONDARY'"
      class="button-grey"
    >
      {{ data.secondaryButtonText }}
    </button>
  </div>

  <button
    *ngIf="data.baseButtonText"
    mat-button
    [mat-dialog-close]="'BASE'"
    class="ms-0"
  >
    <span class="fw-bold">
      {{ data.baseButtonText }}
    </span>
  </button>

  <button
    *ngIf="data.primaryButtonText"
    mat-button
    color="primary"
    class="ms-0"
    [mat-dialog-close]="'PRIMARY'"
  >
    <span class="fw-bold">
      {{ data.primaryButtonText }}
    </span>
  </button>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { API_URL } from '../../../../environments/environment';

import { AuthorizedApiService } from 'app/core/services/authentication/authorized-api.service';
import { Observable } from 'rxjs';
import { OtpAuthResponse } from './dto/otp-auth-response.dto';
import { OtpCreateResponse } from './dto/otp-create-response.dto';
import { OtpUsedResponse } from './dto/otp-used-response.dto';

export const SESSION_STORAGE_OPT_CODE = 'share.optCode';
export const SESSION_STORAGE_OPT_SECRET = 'share.optSecret';
export const TIME_OF_EMIT_FIRST_VALUE = 1000;
export const TIME_OF_EMIT_SUBSEQUENT_VALUE = 1000;

@Injectable({ providedIn: 'root' })
export class ShareOnOtherDeviceService {
  constructor(private http: HttpClient, private token: AuthorizedApiService) {}

  generateOtpCode(): Observable<OtpCreateResponse> {
    const url = `${API_URL}otps`;
    const body = '';
    const options = { headers: this.token.getHeaderConfig() };

    return this.http.post<OtpCreateResponse>(url, body, options);
  }

  confirmSharing(otpId: string, secret: string): Observable<void> {
    const url = `${API_URL}otps/${otpId}/${secret}/confirm`;
    const body = '';
    const options = { headers: this.token.getHeaderConfig() };

    return this.http.post<void>(url, body, options);
  }

  isOptCodeUsed(otpId: string, secret: string): Observable<OtpUsedResponse> {
    const url = `${API_URL}otps/${otpId}/${secret}`;
    const options = { headers: this.token.getHeaderConfig() };

    return this.http.get<OtpUsedResponse>(url, options);
  }

  shareAuth(otpId: string, secret: string): Observable<OtpAuthResponse> {
    const url = `${API_URL}otps/${otpId}/${secret}/auth`;
    const options = {};

    return this.http.get<OtpAuthResponse>(url, options);
  }
}

<section class="section">
  <h2>{{ "REGISTRATION.BIRTH_DATE" | translate }}</h2>
  <p>{{ "REGISTRATION.BIRTH_DATE_DESC" | translate }}</p>

  <div class="birth-date-middle" [formGroup]="birthdayForm">
    <mat-form-field appearance="fill" class="pass-field">
      <mat-label>{{ "REGISTRATION.BIRTH_DATE" | translate }}</mat-label>
      <input
        matInput
        [matDatepicker]="picker"
        formControlName="birthday"
        autocomplete="off"
        [placeholder]="'COMMON.DATE_FORMAT' | translate"
        (blur)="validate($event.target)"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker
        #picker
        startView="multi-year"
        [startAt]="startDate"
      ></mat-datepicker>
      <mat-error *ngIf="dateOfBirthControl.invalid">
        {{ getErrorMessage() }}
      </mat-error>
    </mat-form-field>
    <div class="bottom">
      <div
        [class]="
          hasBackButton ? 'bottom-buttons hasBackButton' : 'bottom-buttons'
        "
      >
        <button
          *ngIf="hasBackButton"
          mat-stroked-button
          type="button"
          (click)="previousStep()"
          class="button back"
        >
          {{ "COMMON.BACK" | translate }}
        </button>
        <button
          class="button next"
          mat-flat-button
          [disabled]="dateOfBirthControl.invalid"
          type="button"
          (click)="nextStep()"
        >
          {{ "COMMON.NEXT" | translate }}
        </button>
      </div>
    </div>
  </div>
</section>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { termsValidator } from 'app/authentication/common';
import { Direction } from '../registration.model';
import { RegistrationService } from '../../../core/services/registration/registration.service';
import {
  RegistrationTermsModel,
  TermsStepModel,
} from './registration-terms.model';
const { requiredTrue } = Validators;

@Component({
  selector: 'mdnra-registration-terms',
  templateUrl: './registration-terms.component.html',
  styleUrls: ['./registration-terms.component.scss'],
})
export class RegistrationTermsComponent implements OnInit {
  @Input()
  token: string = '';

  @Input()
  data: RegistrationTermsModel = {
    isPrivacyPolicy: false,
    isAnalytics: false,
  };

  @Output()
  changeStep: EventEmitter<TermsStepModel> = new EventEmitter<TermsStepModel>();

  @Output()
  updateStepData: EventEmitter<RegistrationTermsModel> =
    new EventEmitter<RegistrationTermsModel>();

  @Output()
  privacyEvent = new EventEmitter<boolean>();
  //TODO: Type form controls
  public imprint: boolean = false;
  public parivacyPolicyControl = new UntypedFormControl(null, [requiredTrue]);
  public analyticsControl = new UntypedFormControl(null);

  public termsForm: UntypedFormGroup = this.formBuilder.group({
    isPrivacyPolicy: this.parivacyPolicyControl,
    isAnalytics: this.analyticsControl,
  });

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly registrationService: RegistrationService
  ) {}

  ngOnInit(): void {
    this.parivacyPolicyControl.setValue(this.data.isPrivacyPolicy);
    this.analyticsControl.setValue(this.data.isAnalytics);
    this.parivacyPolicyControl.setAsyncValidators([
      termsValidator(
        this.registrationService,
        this.parivacyPolicyControl,
        this.analyticsControl,
        this.token
      ),
    ]);
  }

  displayPrivacy() {
    this.privacyEvent.emit(true);
  }

  updateData() {
    this.updateStepData.emit({
      isPrivacyPolicy: this.parivacyPolicyControl.value,
      isAnalytics: this.analyticsControl.value,
    });
  }

  nextStep() {
    this.changeStep.emit({
      data: {
        isPrivacyPolicy: this.parivacyPolicyControl.value,
        isAnalytics: this.analyticsControl.value,
      },
      direction: Direction.FORWARD,
    });
  }
}

<mdnra-home-tree
  *ngIf="forHome && milestone; else progressTree"
  [leaves]="leaves"
  [level]="milestone.level"
></mdnra-home-tree>

<ng-template #progressTree>
  <h5 class="x-out-of">
    <img class="leaf" src="../assets/backgrounds/one-leaf.svg" alt="" />{{
      leaves
    }}/{{ milestoneMaxLeaves }}
  </h5>
  <mdnra-progress-tree
    *ngIf="milestone"
    [percentage]="percentage"
    [level]="milestone.level"
    [disableAnimation]="disableAnimation"
  ></mdnra-progress-tree>
</ng-template>

import { Component, Input } from '@angular/core';

@Component({
  selector: 'mdnra-items-iterator',
  templateUrl: './items-iterator.component.html',
})
export class ItemsIteratorComponent {
  @Input()
  currentIndex: number = 1;

  @Input()
  total: number = 10;

  @Input()
  keyTranslation: string = '';

  constructor() {}
}

<section class="section">
  <h2>{{ "REGISTRATION.ENTER_PASSWORD" | translate }}</h2>
  <p>{{ "REGISTRATION.PASSWORD_DESC" | translate }}</p>

  <div class="pass-middle" [formGroup]="passwordForm">
    <mat-form-field class="pass-field" appearance="fill">
      <mat-label>{{ "REGISTRATION.PASSWORD" | translate }}</mat-label>
      <input
        matInput
        formControlName="password"
        autocomplete="off"
        [type]="showPassword ? 'text' : 'password'"
        (change)="updateData()"
        name="registrationPassword"
      />
      <mat-icon
        matSuffix
        class="peak-eye"
        (click)="showPassword = !showPassword"
        >{{ showPassword ? "visibility" : "visibility_off" }}</mat-icon
      >
      <mat-error *ngIf="passwordForm.controls['password'].invalid">
        {{ getErrorMessage("password") }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="pass-field" appearance="fill">
      <mat-label>{{ "REGISTRATION.REPEAT_PASSWORD" | translate }}</mat-label>
      <input
        matInput
        formControlName="confirmPassword"
        autocomplete="off"
        [type]="showConfirmPassword ? 'text' : 'password'"
        (change)="updateData()"
      />
      <mat-icon
        matSuffix
        class="peak-eye"
        (click)="showConfirmPassword = !showConfirmPassword"
        >{{ showConfirmPassword ? "visibility" : "visibility_off" }}</mat-icon
      >
      <mat-error *ngIf="passwordForm.controls['confirmPassword'].invalid">
        {{ getErrorMessage("confirmPassword") }}
      </mat-error>
    </mat-form-field>
    <div class="bottom">
      <div
        [class]="
          hasBackButton ? 'bottom-buttons hasBackButton' : 'bottom-buttons'
        "
      >
        <button
          *ngIf="hasBackButton"
          mat-stroked-button
          type="button"
          (click)="previousStep()"
          class="button back"
        >
          {{ "COMMON.BACK" | translate }}
        </button>
        <button
          mat-flat-button
          class="button next"
          [disabled]="
            passwordForm.controls['password'].invalid ||
            passwordForm.controls['confirmPassword'].invalid
          "
          type="button"
          (click)="nextStep()"
        >
          {{ "REGISTRATION.SET_PASS" | translate }}
        </button>
      </div>
    </div>
  </div>
</section>

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IS_PRODUCTION } from '../environments/environment';
import { AuthenticationGuard } from './authentication/authentication.guard';
import { ShareAuthComponent } from './authentication/share-auth/share-auth.component';

export function isProd(): boolean {
  return IS_PRODUCTION;
}
export const ROUTES: Routes = [
  {
    path: '',
    canActivate: [AuthenticationGuard],
    runGuardsAndResolvers: 'always',
    data: { authUrl: ['login'] },
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'start/:otpCode/:secret', component: ShareAuthComponent },
      {
        path: 'home',
        loadChildren: () =>
          import('./modules/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'therapy',
        loadChildren: () =>
          import('./modules/training/training.module').then(
            (m) => m.TherapyModule
          ),
      },
      {
        path: 'wellbeing',
        loadChildren: () =>
          import('./modules/wellbeing/wellbeing.module').then(
            (m) => m.WellbeingModule
          ),
      },
      {
        path: 'knowledge',
        loadChildren: () =>
          import('./modules/knowledge/knowledge.module').then(
            (m) => m.KnowledgeModule
          ),
      },
      {
        path: 'more',
        loadChildren: () =>
          import('./modules/more/more.module').then((m) => m.MoreModule),
      },
    ],
  },
  // { path: '**', component: NotFoundCom },
];
@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, {
      enableTracing: false, // !isProd(),
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class RoutingModule {}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthorizedApiService } from 'app/core/services/authentication/authorized-api.service';
import { MessagingService } from 'app/core/services/messaging/messaging.service';
import { API_URL } from 'environments/environment';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigsDto } from './configs.dto';
import { ConfigsModel } from './configs.model';

export const OTP_CONFIG_NAME = 'OTP_SERVICE_URL';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public optServiceUrl: ReplaySubject<string> | null = null;

  constructor(
    private readonly http: HttpClient,
    private readonly token: AuthorizedApiService,
    private readonly messagingService: MessagingService
  ) {}

  getConfig(): Observable<ConfigsModel> {
    const url = `${API_URL}configs`;
    const options = { headers: this.token.getHeaderConfig() };
    return this.http.get<ConfigsDto>(url, options).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getItem(itemName: string): Observable<string> {
    if (this.optServiceUrl !== null) return this.optServiceUrl.asObservable();
    this.optServiceUrl = new ReplaySubject();
    this.getConfig().subscribe({
      next: (res) => {
        const item = res.find((item) => {
          return item.name === itemName;
        });
        if (!item) return;
        this.optServiceUrl!.next(item.value);
      },
      error: (error) => {
        this.messagingService.pushServerError(error);
      },
    });

    return this.optServiceUrl;
  }
}

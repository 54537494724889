import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { PatientStepsService } from 'app/core/services/patient-steps/patient-steps.service';
import * as moment from 'moment';
import {
  EnterStepsDialogComponent,
  MAX_STEPS_IN_THERAPY_WEEK,
} from '../enter-steps-dialog/enter-steps-dialog.component';

@Component({
  selector: 'mdnra-enter-steps-card',
  templateUrl: './enter-steps-card.component.html',
  styleUrls: ['./enter-steps-card.component.scss'],
})
export class EnterStepsCardComponent implements OnInit {
  @Input()
  therapyStartDay: string;

  public therapyWeekStartDay: string = 'Montag';
  public therapyWeekEndDay: string = 'Sonntag';

  public currentEnterStepsWeek: number = 0;

  constructor(
    private readonly dialog: MatDialog,
    private readonly patientStepsService: PatientStepsService,
    private readonly translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.patientStepsService.getCurrentSteps().subscribe((steps) => {
      this.currentEnterStepsWeek = +steps;
    });

    this.therapyWeekStartDay = moment(this.therapyStartDay)
      .locale('de')
      .format('dddd');
    this.therapyWeekEndDay = moment(this.therapyStartDay)
      .add(6, 'days')
      .locale('de')
      .format('dddd');
  }

  getDescription() {
    return this.currentEnterStepsWeek
      ? `${this.currentEnterStepsWeek} ${this.translate.instant(
          'COMMON.SINCE'
        )} ${this.therapyWeekStartDay}`
      : this.translate.instant('HOME.ENTER_STEPS.CARD.EMPTY_MESSAGE');
  }

  openEnterStepsDialog() {
    const dialogRef = this.dialog.open(EnterStepsDialogComponent, {
      width: '100%',
      height: '100%',
      maxWidth: '100vw',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.currentEnterStepsWeek += result.data;
      }
    });
  }
}

import { AfterContentInit, Component, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { WeeklyGoalSetDialogComponent } from '../weekly-goal-set-dialog/weekly-goal-set-dialog.component';
import { MilestonesService } from '../../milestones/milestones.service';

@Component({
  selector: 'mdnra-weekly-goal-completed',
  templateUrl: './weekly-goal-completed.component.html',
  styleUrls: ['./weekly-goal-completed.component.scss'],
})
export class WeeklyGoalCompletedComponent implements AfterContentInit {
  newGoal: any;
  newScore: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<WeeklyGoalCompletedComponent>,
    public milestonesService: MilestonesService
  ) {}

  ngAfterContentInit(): void {
    if (this.data.succeeded) {
      this.milestonesService.openNewMilestoneDialog(this.data, this.dialog);
    }
  }

  closeDialog() {
    this.dialogRef.close({
      event: 'close',
      data: this.newGoal,
      newScore: this.newScore,
    });
  }

  redirectSetGoal() {
    const dialogRef = this.dialog.open(WeeklyGoalSetDialogComponent, {
      width: '100%',
      height: '100%',
      maxWidth: '100vw',
      data: {
        oldGoal: 'data',
        id: '',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.newGoal = result.data.replace(/<[^>]+>/g, '');
        this.newScore = result.newScore;
      }
      this.closeDialog();
    });
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatLegacySliderChange as MatSliderChange } from '@angular/material/legacy-slider';

@Component({
  selector: 'mdnra-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent {
  @Input()
  title?: string;

  @Input()
  minValue: number = 0;

  @Input()
  maxValue: number = 0;

  @Input()
  step: number = 1;

  @Input()
  initValue: number = 0;

  @Input()
  minLabel?: string;

  @Input()
  maxLabel?: string;

  @Output()
  changeValue: EventEmitter<number> = new EventEmitter<number>();

  constructor() {}

  updateValue(event: MatSliderChange) {
    if (event.value) {
      this.changeValue.emit(event.value);
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CountUnreadMessageModal } from 'app/core/services/patient/count-unread-message.modal';
import { ProgressTreeLevel } from 'app/core/services/patient/progress-tree-level.model';
import { ProgressTreeDto } from 'app/core/services/patient/progress-tree.dto';
import { MedianAppApiModel } from 'app/shared/dto/median-app-api.dto';
import { MedianAppApiService } from 'app/shared/services/median-app-api.service';

import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { EMPTY, Observable, switchMap, timer } from 'rxjs';
import { PatientService } from '../../core/services/patient/patient.service';
import { MilestonesService } from './milestones/milestones.service';
import * as moment from 'moment';
import { PopupDialogComponent } from 'app/shared/component/popup-dialog/popup-dialog.component';
import {
  PopupDialogCloseReturnEnum,
  PopupDialogCloseReturnModel,
} from 'app/shared/component/popup-dialog/popup-dialog.model';

declare const MedianAppApi: MedianAppApiModel;
@Component({
  selector: 'mdnra-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public treeMessage: string = '';
  public description: string = '';
  public therapyStartDay: string = '';

  public isFeedbackEnabled: boolean = false;

  numberUnreadMessages$: Observable<CountUnreadMessageModal> =
    this.patientService.getUnreadMessagesCount();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly dialog: MatDialog,
    private readonly translateService: TranslateService,
    private readonly authenticationService: AuthenticationService,
    private readonly medianAppApiService: MedianAppApiService,
    private readonly milestonesService: MilestonesService,
    private readonly patientService: PatientService
  ) {}

  ngOnInit(): void {
    this.initMedianBars();

    this.route.data.subscribe(({ progress }) => {
      this.setupMilestoneInfo(progress);
    });

    this.route.data.subscribe(({ therapy, settings, patientStatus }) => {
      let isGlobalClinicChatDisabled = false;

      if (therapy.status === 'NOT_FOUND') {
        this.openNoTherapyDialog();
        return;
      }

      if (
        localStorage.getItem('dialogFinishedTherapyDisplayed') !== 'true' &&
        therapy &&
        !therapy.endConfirmed &&
        therapy.end &&
        moment(therapy.end).endOf('day').utc().isBefore(moment().utc())
      ) {
        localStorage.setItem('dialogFinishedTherapyDisplayed', 'true');
        this.dialog
          .open(PopupDialogComponent, {
            autoFocus: false,
            maxWidth: 'calc(100vw - 40px)',
            data: {
              title: this.translateService.instant(
                'THERAPY.DIALOG.THERAPY_PLAN_ENDED.TITLE'
              ),
              message: this.translateService.instant(
                'THERAPY.DIALOG.THERAPY_PLAN_ENDED.MESSAGE'
              ),
              message2: this.translateService.instant(
                'THERAPY.DIALOG.THERAPY_PLAN_ENDED.MESSAGE2'
              ),
              baseButtonText: this.translateService.instant(
                'THERAPY.DIALOG.THERAPY_PLAN_ENDED.BUTTON_BASE'
              ),
              hasCloseButton: true,
            },
          })
          .afterClosed()
          .pipe(
            switchMap((isConfirmed: PopupDialogCloseReturnModel) => {
              if (isConfirmed !== PopupDialogCloseReturnEnum.BASE) return EMPTY;
              return this.patientService.confirmTherapyEnd();
            })
          )
          .subscribe((response) => {
            if (!response.endConfirmed) return;
            localStorage.removeItem('dialogFinishedTherapyDisplayed');
          });
      }

      if (settings && settings[0]['value']) {
        isGlobalClinicChatDisabled = settings[0]['value'] === 'true';
        localStorage.setItem('feedbackEnabled', settings[0]['value']);
      }

      this.isFeedbackEnabled =
        patientStatus === 'SELF_PAYER' ||
        patientStatus === 'AFTERCARE' ||
        isGlobalClinicChatDisabled;

      this.therapyStartDay = therapy.start;
    });
  }

  openNoTherapyDialog(): void {
    this.dialog
      .open(PopupDialogComponent, {
        autoFocus: false,
        maxWidth: 'calc(100vw - 40px)',
        data: {
          title: this.translateService.instant(
            'THERAPY.DIALOG.NO_THERAPY_PLAN.TITLE'
          ),
          message: this.translateService.instant(
            'THERAPY.DIALOG.NO_THERAPY_PLAN.MESSAGE'
          ),
          baseButtonText: this.translateService.instant(
            'THERAPY.DIALOG.NO_THERAPY_PLAN.BUTTON_BASE'
          ),
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.authenticationService.leave();
      });
  }

  private initMedianBars() {
    timer(200).subscribe(() => {
      if (
        this.medianAppApiService.getMedianParent() &&
        typeof MedianAppApi !== 'undefined'
      ) {
        this.medianAppApiService.medianShowHeaderbar(MedianAppApi);
        this.medianAppApiService.medianHideTabbar(MedianAppApi);
      }
    });
  }

  private setupMilestoneInfo(progress: ProgressTreeDto) {
    const currentMilestone = this.milestonesService.getMilestone(
      progress?.leaves,
      progress?.levels
    );
    this.description = this.getMilestoneDesc(currentMilestone);
    this.treeMessage = this.getMilestoneMessage(currentMilestone);
  }

  private getMilestoneDesc(currentMilestone?: ProgressTreeLevel): string {
    return currentMilestone?.level === 1
      ? this.translateService.instant('HOME.DESCRIPTION')
      : '';
  }

  private getMilestoneMessage(currentMilestone?: ProgressTreeLevel): string {
    return currentMilestone
      ? this.translateService.instant(
          'HOME.MILESTONE' + currentMilestone.level + '_DESC'
        )
      : '';
  }
}

import { Injectable } from '@angular/core';

import { NameValue } from 'app/shared/dto/name-value.dto';
import { Observable } from 'rxjs';
import { PatientService } from '../services/patient/patient.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsResolverService  {
  constructor(private readonly patientService: PatientService) {}
  resolve(): Observable<NameValue[]> {
    return this.patientService.settings();
  }
}

import { Component, Input } from '@angular/core';
import { RegistrationService } from '../../../core/services/registration/registration.service';

@Component({
  selector: 'mdnra-registration-check-email',
  templateUrl: './registration-check-email.component.html',
  styleUrls: ['./registration-check-email.component.scss'],
})
export class RegistrationCheckEmailComponent {
  @Input() email: string = '';
  public resentMessage: string = '';
  public resent: boolean = false;

  constructor(private readonly registrationService: RegistrationService) {}

  resendEmail(): void {
    this.registrationService.resendWithEmail(this.email).subscribe({
      next: () => {
        this.resent = true;
      },
      error: (err) => {
        this.resentMessage = err.error.message;
      },
    });
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export abstract class AbstractStorage {
  private storage: Storage;

  protected constructor(storage: Storage) {
    this.storage = storage;
  }

  /**
   * Store the object in storage.
   */
  setItem(key: string, value: any): void {
    this.storage.setItem(key, JSON.stringify(value));
  }

  /**
   * Retrieves data from storage.
   */
  getItem<T>(key: string, defaultValue: T | null = null): T | null {
    const value = this.storage.getItem(key);
    if (value === null) {
      return defaultValue;
    }
    try {
      return JSON.parse(value);
    } catch (e) {
      console.warn('Unable to parse storage data for key: ', key);
      return defaultValue;
    }
  }

  /**
   * Removes value from storage.
   */
  removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  /**
   * Removes all data from storage.
   */
  clear(): void {
    this.storage.clear();
  }
}

<div class="complete-view-wrapper">
  <div class="complete-view-content">
    <div class="top-block">
      <mdnra-back-button
        *ngIf="hasBack"
        [color]="'white'"
        (closePressed)="closePressedHandler()"
      ></mdnra-back-button>
      <h1 class="title">
        {{ title | translate : { x: currentLevel - 1 || "" } }}
      </h1>
      <h5 class="description regular">{{ description | translate }}</h5>
    </div>
    <div class="center-block">
      <div class="progress-tree-container">
        <mdnra-tree [disableAnimation]="disableAnimation"></mdnra-tree>
        <h5 *ngIf="receivedPoints" class="received-points regular">
          + {{ receivedPoints }}
          {{
            receivedPoints === 1
              ? ("COMMON.POINT" | translate)
              : ("COMMON.POINTS" | translate)
          }}
        </h5>
      </div>
    </div>
    <div *ngIf="redirectLabel" class="bottom-block">
      <button
        mat-flat-button
        class="mdnra-button finish-button"
        (click)="redirectPressedHandler()"
      >
        {{ redirectLabel | translate }}
      </button>
    </div>
  </div>
</div>

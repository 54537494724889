import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PatientGoalService } from 'app/core/services/patient-goal/patient-goal.service';
import { PatientService } from 'app/core/services/patient/patient.service';
import { SessionStorageService } from 'app/shared/services/session-storage.service';
import * as moment from 'moment';
import { WeeklyGoalModalComponent } from '../weekly-goal-modal/weekly-goal-modal.component';
import { WeeklyGoalSetDialogComponent } from '../weekly-goal-set-dialog/weekly-goal-set-dialog.component';
import { PatientGoalModel } from 'app/core/services/patient-goal/patient-goal.model';
import { HttpStatusCode } from '@angular/common/http';

@Component({
  selector: 'mdnra-weekly-goal-card',
  templateUrl: './weekly-goal-card.component.html',
  styleUrls: ['./weekly-goal-card.component.scss'],
})
export class WeeklyGoalCardComponent implements OnInit {
  public updatedGoal: string = '';
  public currentGoal?: PatientGoalModel | null;
  public patientStatus?: string;

  constructor(
    private readonly dialog: MatDialog,
    private readonly sessionStorageService: SessionStorageService,
    private readonly goalService: PatientGoalService,
    private readonly patientService: PatientService
  ) {}

  ngOnInit(): void {
    this.patientService.getPatientStatus().subscribe(({ patientStatus }) => {
      this.patientStatus = patientStatus;
    });

    this.goalService
      .getCurrentGoal()
      .subscribe((response: { statusCode: number; data: PatientGoalModel }) => {
        if (response.statusCode === HttpStatusCode.NoContent) {
          this.currentGoal = null;
        } else {
          this.currentGoal = response.data;
          // CHECK IF GOAL IS FINISHED
          if (response.data) {
            const goalFinishDate = moment(response?.data?.finishAt);
            const currentDate = moment()
              .millisecond(0)
              .second(0)
              .minute(0)
              .hour(0);
            let shouldDisplay = this.sessionStorageService.getItem(
              'shouldDisplayWeeklyGoalModal'
            );
            if (goalFinishDate.isBefore(currentDate) && shouldDisplay) {
              const dialogRef = this.dialog.open(WeeklyGoalModalComponent, {
                width: '100%',
                height: '100%',
                maxWidth: '100vw',
                data: {
                  goal: response.data.ambition,
                  goalId: response.data.id,
                  goalObject: response.data,
                  patientStatus: this.patientStatus,
                },
              });
              dialogRef.afterClosed().subscribe((result) => {
                this.sessionStorageService.setItem(
                  'shouldDisplayWeeklyGoalModal',
                  false
                );
                this.updatedGoal = result?.data?.goal;
                if (result?.data?.completed) {
                  this.currentGoal = null;
                }
              });
            }
          }
        }
      });
  }

  openSetGoalDialog(): void {
    const dialogRef = this.dialog.open(WeeklyGoalSetDialogComponent, {
      width: '100%',
      height: '100%',
      maxWidth: '100vw',
      data: {
        goal: this.updatedGoal ? this.updatedGoal : this.currentGoal?.ambition,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.updatedGoal = result?.data?.replace(/<[^>]+>/g, '');
      }
    });
  }
}

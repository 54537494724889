import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'mdnra-carousel-item',
  templateUrl: './carousel-item.component.html',
  styleUrls: ['./carousel-item.component.scss'],
})
export class CarouselItemComponent implements OnInit {
  @Input()
  title: string = '';

  @Input()
  header: string = '';

  @Input()
  gradient: boolean = false;

  @Input()
  icon: string = '';

  @Input()
  iconBackground?: string = '';

  @Input()
  image?: string = '';

  @Input()
  duration: number = 0;

  @Input()
  detailLink: string = '';

  @Input()
  colorScheme: string = 'home';

  public durationTime: string = '';

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.durationTime = this.duration
      ? Math.ceil(
          moment.duration(this.duration, 'seconds').asMinutes()
        ).toString()
      : '';
  }

  navigate(): void {
    this.router.navigate([this.detailLink], {
      relativeTo: this.route,
      state: { openedFromLibrary: false },
    });
  }
}

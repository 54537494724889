<mat-card
  class="card-container"
  [class.first-card]="isFirstPreviousTraining"
  [class.second-card]="!isFirstPreviousTraining"
  (click)="navigate()"
>

  <div class="left-center-content">
    <div class="left-content">
      <div class="left-top">
        {{ formattedDate.weekday }}
      </div>
      <div class="left-second">
        {{ formattedDate.dayMonth }}
      </div>
      <div class="left-third">
        {{ formattedDate.year }}
      </div>
      <div class="left-forth">
      </div>
      <div class="left-bottom">
        {{ formattedDate.time }} {{ "THERAPY.ALL_EXERCISES_PREVIOUS_TRAININGS.HOUR" | translate }}
      </div>
    </div>
    <div class="center-content">
      <div class="center-top">
        {{ "THERAPY.ALL_EXERCISES_PREVIOUS_TRAININGS.TRAINING" | translate }}
      </div>
      <div class="center-center">
        {{ numberOfExercises }} {{ "THERAPY.ALL_EXERCISES_PREVIOUS_TRAININGS.EXERCISE" | translate }}
      </div>
      <div class="center-bottom">
        {{ duration }} {{ "THERAPY.ALL_EXERCISES_PREVIOUS_TRAININGS.MINUTE" | translate }}
      </div>
    </div>
  </div>
  <div class="right-content">
    <div class="right-top">
      <img *ngIf="imgUrl" class="image" src="{{ imgUrl }}" />
    </div>
    <button class="right-round-button" (click)="navigate()">
      {{ "THERAPY.ALL_EXERCISES_PREVIOUS_TRAININGS.TO_TRAINING" | translate }}
    </button>
  </div>

</mat-card>

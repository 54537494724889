import * as moment from 'moment';
import { Moment } from 'moment';

export const DISPLAY_FORMAT_MESSAGE_DATE = 'DD.MM.YY';
export const SORT_FORMAT_MESSAGE_DATE = 'YYYY.MM.DD';

export class MessageModel {
  messageDirection: string | null = null;
  messageType: string | null = null;
  createdAt: Date = new Date();
  message: string | null = null;
  date: Moment = moment();
  messageDateString: string | null = null;
  title: string | null = null;
  contentmentType: string | null = null;
  problemType: string | null = null;
  painLevel: string | null = null;
}

export class MessageGroups {
  private groups: {
    [key: string]: MessageGroup;
  } = {};
  addToGroups(message: MessageModel) {
    const key = message.date.format(SORT_FORMAT_MESSAGE_DATE);
    if (!this.groups[key]) {
      this.groups[key] = {
        date: moment(message.createdAt).format(DISPLAY_FORMAT_MESSAGE_DATE),
        messages: [],
      };
    }
    this.groups[key].messages.push(message);
  }
  getGroups() {
    return this.groups;
  }
}

export interface MessageGroup {
  date: string;
  messages: MessageModel[];
}

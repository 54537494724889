import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'mdnra-card-previous-training-layout',
  templateUrl: 'card-previous-training-layout.component.html',
  styleUrls: ['card-previous-training-layout.component.scss'],
})
export class CardPreviousTrainingLayoutComponent implements OnInit {

  @Input()
  detailLink = '';

  @Input()
  imgUrl= '';

  @Input()
  dateTime = '';

  @Input()
  duration = 0;

  @Input()
  numberOfExercises = 0;

  @Input()
  isFirstPreviousTraining = false;

  @Input()
  openedFromLibrary = true;

  formattedDate: FormattedDate = this.initializeFormattedDate();

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.formattedDate = this.formatDate(this.dateTime);
  }

  private initializeFormattedDate(): FormattedDate {
    return {
      weekday: '',
      dayMonth: '',
      year: '',
      time: '',
    };
  }

  private formatDate(dateString: string): FormattedDate {
    let date = new Date(dateString);
    try {
      date = new Date(dateString);
      if (isNaN(date.getTime())) {
        throw new Error('Invalid date');
      }
    } catch {
      return this.initializeFormattedDate();
    }
    const weekday = this.getTranslatedWeekdays()[date.getUTCDay()];
    const dayMonth = this.formatDayMonth(date);
    const year = date.getUTCFullYear().toString();
    const time = this.formatTime(date);

    return { weekday, dayMonth, year, time };
  }

  private getTranslatedWeekdays(): string[] {
    const baseKey = 'THERAPY.ALL_EXERCISES_PREVIOUS_TRAININGS.WEEKDAYS.';
    return [
      this.translateService.instant(baseKey + 'SUNDAY'),
      this.translateService.instant(baseKey + 'MONDAY'),
      this.translateService.instant(baseKey + 'TUESDAY'),
      this.translateService.instant(baseKey + 'WEDNESDAY'),
      this.translateService.instant(baseKey + 'THURSDAY'),
      this.translateService.instant(baseKey + 'FRIDAY'),
      this.translateService.instant(baseKey + 'SATURDAY'),
    ];
  }

  private formatDayMonth(date: Date): string {
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    return `${day}.${month}`;
  }

  private formatTime(date: Date): string {
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  }
  
  navigate(): void {
    this.router.navigate([this.detailLink], {
      relativeTo: this.route,
      state: { openedFromLibrary: this.openedFromLibrary }
    });
  }
}

interface FormattedDate {
  weekday: string;
  dayMonth: string;
  year: string;
  time: string;
}

import {Component, Input} from '@angular/core';

@Component({
    selector: 'mdnra-card-list',
    templateUrl: 'card-list.component.html',
    styleUrls: ['card-list.component.scss']
})
export class CardListComponent {
    @Input()
    all = false;

    constructor() {
    }
}

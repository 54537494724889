import {Component, Input} from '@angular/core';

@Component({
    selector: 'mdnra-question-layout',
    templateUrl: 'question-layout.component.html',
    styleUrls: ['question-layout.component.scss']
})
export class QuestionLayoutComponent {
    @Input()
    title = '';

    @Input()
    detailLink = '';
}

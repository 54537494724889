<div *ngIf="!imprint && !dataProtection" class="login-wrapper">
  <div class="welcome-screen" [class.login-visible]="loginScreen">
    <div class="powered-by-wrapper">
      <img
        class="median-logo"
        src="../../assets/other/median-logo.svg"
        alt=""
      />
    </div>
    <h2>{{ "REGISTRATION.INTRO" | translate }}</h2>
    <img class="tree leaf" src="../assets/backgrounds/leafg1.svg" alt="" />
    <button
      class="to-login-button"
      mat-flat-button
      type="button"
      (click)="toLogin()"
    >
      {{ "LOGIN.LOGIN" | translate }}
    </button>
  </div>
  <div class="login-screen" [class.login-visible]="loginScreen">
    <form class="login-form" (ngSubmit)="login()" [formGroup]="loginForm">
      <div class="info-wrapper" [class.error-displayed]="displayError">
        <h2 class="">{{ "LOGIN.TITLE" | translate }}</h2>
        <h5 class="regular">{{ "LOGIN.DESCRIPTION" | translate }}</h5>
      </div>
      <mat-card *ngIf="displayError" class="error-card">
        {{ errorMessage }}
      </mat-card>

      <mat-form-field appearance="fill">
        <mat-label>{{ "LOGIN.EMAIL" | translate }}</mat-label>
        <input
          matInput
          id="email"
          (keyup)="correctInput()"
          [formControl]="userControl"
        />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ "LOGIN.PASSWORD" | translate }}</mat-label>
        <input
          matInput
          id="password"
          (keyup)="correctInput()"
          [formControl]="passControl"
          [type]="hide ? 'password' : 'text'"
        />
        <mat-icon matSuffix class="peak-eye" (click)="hide = !hide">{{
          hide ? "visibility_off" : "visibility"
        }}</mat-icon>
      </mat-form-field>
      <div class="extra-options">
        <a
          mat-button
          class="forgot-password"
          color="primary"
          routerLink="../forgot"
          >{{ "LOGIN.FORGOT_PASS" | translate }}</a
        >
      </div>
      <div class="extra-options">
        <a
          class="agb"
          mat-button
          color="primary"
          (click)="dataProtection = true"
          >{{ "LOGIN.AGB" | translate }}</a
        >
      </div>
      <div class="extra-options">
        <a
          class="imprint"
          mat-button
          color="primary"
          (click)="imprint = true"
          >{{ "LOGIN.IMPRINT" | translate }}</a
        >
      </div>
      <div class="bottom">
        <button
          class="login-button"
          type="submit"
          mat-flat-button
          color="primary"
          [disabled]="isLoginDisabled"
        >
          {{ "LOGIN.LOGIN" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
<div *ngIf="imprint">
  <mdnra-imprint
    [popUp]="true"
    (imprintEvent)="setImprint($event)"
  ></mdnra-imprint>
</div>

<div *ngIf="dataProtection">
  <mdnra-data-protection
    [popUp]="true"
    (privacyEvent)="setDataProtection($event)"
  ></mdnra-data-protection>
</div>

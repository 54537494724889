import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  passwordConfirmValidator,
  passwordValidator,
} from 'app/authentication/common';
import { MessagingService } from 'app/core/services/messaging/messaging.service';
import { Direction } from '../registration.model';
import { RegistrationService } from '../../../core/services/registration/registration.service';
import {
  PasswordStepModel,
  RegistrationPasswordModel,
} from './registration-password.model';

@Component({
  selector: 'mdnra-registration-password',
  templateUrl: './registration-password.component.html',
  styleUrls: ['./registration-password.component.scss'],
})
export class RegistrationPasswordComponent implements OnInit {
  @Input()
  token: string = '';

  @Input()
  hasBackButton: boolean = false;

  @Input()
  data: RegistrationPasswordModel = {
    password: '',
    confirmPassword: '',
  };

  @Output()
  changeStep: EventEmitter<PasswordStepModel> =
    new EventEmitter<PasswordStepModel>();

  @Output()
  updateStepData: EventEmitter<RegistrationPasswordModel> =
    new EventEmitter<RegistrationPasswordModel>();

  public showPassword: boolean = false;
  public showConfirmPassword: boolean = false;
  //TODO: Type form controls
  private passwordControl: UntypedFormControl = new UntypedFormControl(
    this.data.password,
    {
      validators: [Validators.required],
    }
  );

  private confirmPasswordControl: UntypedFormControl = new UntypedFormControl(
    this.data.confirmPassword,
    [Validators.required]
  );

  public passwordForm: UntypedFormGroup = this.formBuilder.group({
    password: this.passwordControl,
    confirmPassword: this.confirmPasswordControl,
  });

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly registrationService: RegistrationService,
    private readonly messagingService: MessagingService
  ) {}

  ngOnInit(): void {
    this.passwordControl.setValue(this.data.password);
    this.confirmPasswordControl.setValue(this.data.confirmPassword);

    this.passwordControl.setAsyncValidators(
      passwordValidator(
        this.registrationService,
        this.confirmPasswordControl,
        this.token
      )
    );

    this.confirmPasswordControl.setAsyncValidators(
      passwordConfirmValidator(
        this.registrationService,
        this.passwordControl,
        this.token
      )
    );

    this.confirmPasswordControl.valueChanges.subscribe(() =>
      this.passwordControl.updateValueAndValidity({ emitEvent: false })
    );

    this.passwordControl.valueChanges.subscribe(() =>
      this.confirmPasswordControl.updateValueAndValidity({ emitEvent: false })
    );
  }

  updateData() {
    this.updateStepData.emit({
      password: this.passwordControl.value,
      confirmPassword: this.confirmPasswordControl.value,
    });
  }

  previousStep() {
    this.changeStep.emit({
      data: {
        password: this.passwordControl.value,
        confirmPassword: this.confirmPasswordControl.value,
      },
      direction: Direction.BACK,
    });
  }

  nextStep() {
    this.changeStep.emit({
      data: {
        password: this.passwordControl.value,
        confirmPassword: this.confirmPasswordControl.value,
      },
      direction: Direction.FORWARD,
    });
  }

  getErrorMessage(controlName: string): string {
    const control = this.passwordForm.get(controlName);
    if (control?.errors) {
      return this.messagingService.getControlErrorMessage(control.errors);
    }
    return '';
  }
}

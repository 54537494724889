import { RegistrationBirthdayModel } from './registration-birthday/registration-birthday.model';
import { RegistrationContactModel } from './registration-contact/registration-contact.model';
import { RegistrationPasswordModel } from './registration-password/registration-password.model';
import { RegistrationTermsModel } from './registration-terms/registration-terms.model';

export interface RegistrationModel {
  token: string;
  terms: RegistrationTermsModel;
  pass: RegistrationPasswordModel;
  birth: RegistrationBirthdayModel;
  contact: RegistrationContactModel;
}

export enum Direction {
  FORWARD,
  BACK,
}

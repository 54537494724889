import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthorizedApiService } from '../authentication/authorized-api.service';
import { ProgressTreeDto } from '../patient/progress-tree.dto';
import { ProgressTreeModel } from '../patient/progress-tree.model';
import { ContentDto } from './content.dto';
import { ContentModel } from './content.model';
import { ContentsDto } from './contents.dto';
import { ContentsModel } from './contents.model';
import { v7 as uuidv7 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  constructor(
    private readonly http: HttpClient,
    private readonly token: AuthorizedApiService
  ) {}

  getAllContents(type: string = ''): Observable<ContentsModel> {
    const url = `${API_URL}contents`;
    const options = {
      headers: this.token.getHeaderConfig(),
      params: { type },
    };
    return this.http.get<ContentsDto>(url, options).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getCurrentContent(type: string = ''): Observable<ContentsModel> {
    const url = `${API_URL}contents/current`;
    const options = {
      headers: this.token.getHeaderConfig(),
      params: { type },
    };
    return this.http.get<ContentsDto>(url, options).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getContent(id: number): Observable<ContentModel> {
    const url = `${API_URL}contents/${id}`;
    const options = {
      headers: this.token.getHeaderConfig(),
    };
    return this.http.get<ContentDto>(url, options).pipe(
      map((response) => {
        return response;
      })
    );
  }

  completeContent(id: number): Observable<ProgressTreeModel> {
    const url = `${API_URL}contents/${id}`;
    const options = { headers: this.token.getHeaderConfig() };
    return this.http.post<ProgressTreeDto>(url, {}, options).pipe(
      map((response) => {
        return response;
      })
    );
  }

  timeTrack(id: number, delta: number, startedTracking: boolean): Observable<void> {
    const url = `${API_URL}contents/${id}/tracking`;
    const uuid = uuidv7();

    const options = { headers: this.token.getHeaderConfig() };
    const body = { timeDelta: Math.ceil(delta), identifier: uuid, startedTracking: startedTracking };
    return this.http.post<void>(url, body, options);
  }
}

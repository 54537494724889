<div class="slider">
  <div class="header" *ngIf="title">{{ title }}</div>
  <mat-slider
    [min]="minValue"
    [max]="maxValue"
    [step]="step"
    [value]="initValue"
    (change)="updateValue($event)"
  >
  </mat-slider>
  <div class="labels">
    <div>{{ minLabel }}</div>
    <div>{{ maxLabel }}</div>
  </div>
</div>

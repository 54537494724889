import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageDto } from 'app/core/services/patient/message.dto';
import { MessageModel } from 'app/modules/more/chat/message.model';
import { NameValue } from 'app/shared/dto/name-value.dto';
import { API_URL } from 'environments/environment';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Observable, Subject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthorizedApiService } from '../authentication/authorized-api.service';
import { CountUnreadMessageDto } from './count-unread-message.dto';
import { CountUnreadMessageModal } from './count-unread-message.modal';
import { ProgressTreeDto } from './progress-tree.dto';
import { ProgressTreeModel } from './progress-tree.model';
import { QuestionnaireModel } from './questionnaire.model';
import { TherapyDto } from './therapy.dto';
import { TherapyModel } from './therapy.model';
import { ContactBannerModel } from './contact-banner.model';

@Injectable({
  providedIn: 'root',
})
export class PatientService {
  private therapy?: TherapyDto;
  public progressTree$: Subject<ProgressTreeModel> =
    new Subject<ProgressTreeModel>();

  constructor(
    private readonly http: HttpClient,
    private readonly token: AuthorizedApiService
  ) {}

  getTreeProgress(): Observable<ProgressTreeModel> {
    const url = `${API_URL}tree-progress`;
    const options = { headers: this.token.getHeaderConfig() };
    return this.http.get<ProgressTreeModel>(url, options).pipe(
      map((response) => {
        this.updateTreeProgress(response);
        return response;
      })
    );
  }

  updateTreeProgress(progressTree: ProgressTreeModel): void {
    this.progressTree$.next(progressTree);
  }

  getMessages(): Observable<MessageModel[]> {
    const headers = this.token.getHeaderConfig();
    return this.http.get<MessageDto[]>(API_URL + 'message', { headers }).pipe(
      map((response) =>
        response.map((message) => {
          return this.mapToModel(message);
        })
      )
    );
  }

  getUnreadMessagesCount(): Observable<CountUnreadMessageModal> {
    const options = { headers: this.token.getHeaderConfig() };
    return this.http
      .get<CountUnreadMessageDto>(API_URL + 'message/unread-count', options)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  markMessagesAsRead(): Observable<void> {
    const headers = this.token.getHeaderConfig();
    return this.http.patch<any>(API_URL + 'message', null, { headers });
  }

  sendMessage(message: string): Observable<MessageModel> {
    const headers = this.token.getHeaderConfig();
    const body = {
      text: message,
    };
    return this.http
      .post<MessageDto>(API_URL + 'message', body, {
        headers,
      })
      .pipe(map((response) => this.mapToModel(response)));
  }

  settings(): Observable<NameValue[]> {
    const url = `${API_URL}settings`;
    const options = { headers: this.token.getHeaderConfig() };
    return this.http.get<NameValue[]>(url, options);
  }

  getTherapy(useCache: boolean = false): Observable<TherapyModel> {
    if (useCache && this.therapy) {
      return of(this.therapy);
    }
    const url = `${API_URL}therapy`;
    const options = { headers: this.token.getHeaderConfig() };
    return this.http.get<TherapyDto>(url, options).pipe(
      map((resp) => {
        this.therapy = resp;
        return resp;
      })
    );
  }

  confirmTherapyEnd(): Observable<TherapyModel> {
    const url = `${API_URL}therapy/confirm-end`;
    const options = { headers: this.token.getHeaderConfig() };
    return this.http.put<TherapyDto>(url, undefined, options).pipe(
      map((resp) => {
        this.therapy = resp;
        return resp;
      })
    );
  }

  getContactBanner(): Observable<ContactBannerModel> {
    const url = `${API_URL}contact-banner`;
    const options = { headers: this.token.getHeaderConfig() };
    return this.http.get<ContactBannerModel>(url, options);
  }

  getQuestionnaire(id: number): Observable<QuestionnaireModel> {
    const url = `${API_URL}therapy/${id}/questionnaire`;
    const options = { headers: this.token.getHeaderConfig() };
    return this.http.get<QuestionnaireModel>(url, options).pipe(
      map((response) => {
        return response;
      })
    );
  }

  saveQuestionnaire(
    id: number,
    body: QuestionnaireModel
  ): Observable<ProgressTreeModel> {
    const url = `${API_URL}therapy/${id}/questionnaire`;
    const options = { headers: this.token.getHeaderConfig() };
    return this.http.post<ProgressTreeDto>(url, body, options).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getPatientStatus(): Observable<{ patientStatus: string }> {
    const url = `${API_URL}type`;
    const options = { headers: this.token.getHeaderConfig() };
    return this.http.get<{ patientStatus: string }>(url, options);
  }

  private mapToModel(message: MessageDto): MessageModel {
    const messageModel: MessageModel = new MessageModel();
    const messageCreatedAt = moment(message.createdAt);
    messageModel.createdAt = messageCreatedAt.toDate();
    messageModel.date = this.clearTime(moment(messageModel.createdAt));
    messageModel.messageDirection = message.messageDirection ?? null;
    messageModel.messageType = message.messageType ?? null;
    messageModel.message = message.message ?? null;
    messageModel.messageDateString = message.messageDateString ?? null;
    messageModel.title = message.title ?? null;
    messageModel.contentmentType = message.contentmentType ?? null;
    messageModel.problemType = message.problemType ?? null;
    messageModel.painLevel = message.painLevel ?? null;
    return messageModel;
  }

  private clearTime(date: Moment) {
    return date.millisecond(0).second(0).minute(0).hour(0);
  }
}

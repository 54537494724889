import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PatientService } from 'app/core/services/patient/patient.service';
import { ProgressTreeLevel } from 'app/core/services/patient/progress-tree-level.model';
import { ProgressTreeModel } from 'app/core/services/patient/progress-tree.model';
import { MilestonesService } from 'app/modules/home/milestones/milestones.service';
import { Observable, Subscription, take } from 'rxjs';

@Component({
  selector: 'mdnra-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
})
export class TreeComponent implements OnInit, OnDestroy {
  @Input() forHome = false;
  @Input() disableAnimation = false;

  public leaves: number = 0;
  public percentage: number = 0;
  public milestone?: ProgressTreeLevel;
  public milestoneMaxLeaves?: number = 0;
  private subscription: Subscription;

  constructor(
    private readonly milestonesService: MilestonesService,
    private readonly patientService: PatientService
  ) {}

  ngOnInit(): void {
    this.patientService.getTreeProgress().subscribe();
    this.subscription = this.patientService.progressTree$.subscribe(
      (progressTree: ProgressTreeModel) => {
        this.leaves = progressTree.leaves;

        this.milestone = this.milestonesService.getMilestone(
          this.leaves,
          progressTree.levels
        );

        this.milestoneMaxLeaves = this.milestone?.levelEnd;
        this.percentage = this.calculateProgress(
          this.leaves,
          this.milestone.levelEnd
        );
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private calculateProgress(partialValue: number, totalValue: number): number {
    if (totalValue) {
      return partialValue / totalValue;
    }
    return 0;
  }
}

<div class="main-layout">
  <div class="main-page">
    <div
      class="main-layout-header"
      [ngClass]="colorScheme"
      [class.recipe]="imgLink"
    >
      <div *ngIf="imgLink" class="img-wrapper">
        <img class="image" src="{{ imgLink }}" />
        <div class="gradient"></div>
      </div>

      <div class="text-container">
        <div
          class="logo-wrapper d-flex flex-row justify-content-between col-xs-12"
        >
          <div *ngIf="hasMedianLogo" class="col-xs-3 col-sm-2 mt-4">
            <img
              class="median-logo"
              src="../../../assets/other/median-logo.svg"
              alt=""
            />
          </div>
          <div
            class="col-xs-3 col-sm-2 mt-4 align-self-center"
            *ngIf="hasMessagesIcon"
          >
            <span
              class="fa-stack has-badge"
              (click)="goToChat()"
              [attr.data-count]="
                unreadMessageCount > 9 ? '9+' : unreadMessageCount
              "
              [ngClass]="{
                'no-messages': unreadMessageCount === 0,
                'nine-more': unreadMessageCount > 9
              }"
            >
              <i class="far fa-lg fa-comment"></i>
            </span>
          </div>
        </div>


          <mdnra-button-link
            class="back-button"
            type="link"
            color="white"
            [route]="backUrl"
          >
            <span *ngIf="hasX">X</span>
            <ng-container *ngIf="hasBack">
              <i class="fas fa-long-arrow-left mr-4"></i
              >{{ "COMMON.BACK" | translate }}
            </ng-container>
          </mdnra-button-link>
          <i *ngIf="icon" class="icon-corner-right" [class]="icon"></i>

        <h1
          *ngIf="title !== ''"
          id="title"
          [ngClass]="{ 'mt-4': marginSmall }"
          class="title"
        >
          {{ title }}
        </h1>
        <div
          *ngIf="description !== ''"
          class="description"
          [innerHtml]="description"
        ></div>
        <div class="header-content">
          <ng-content select="[slot=header-content]"></ng-content>
        </div>
      </div>
      <!-- <div class="back-button-container">
        <a *ngIf="hasX" class="x-button" (click)="goBack()">X</a>
        <h5 *ngIf="hasBack" class="back-button" (click)="goBack()">
          <i class="fas fa-long-arrow-left"></i>{{ "COMMON.BACK" | translate }}
        </h5>
        <i *ngIf="icon" class="far {{ icon }} icon-corner-right"></i>
      </div> -->
    </div>
    <div class="main-content">
      <ng-content select="[slot=main-content]"></ng-content>
    </div>
  </div>
  <mdnra-menu class="menu"></mdnra-menu>
</div>

<!--Example-->
<!--<mdnra-main-layout-->
<!--        [hasX]="true"-->
<!--        [hasBack]="true"-->
<!--        [title]="'Test'"-->
<!--        [description]="'This is just a description, no lorem ipsum here'"-->
<!--&gt;-->
<!--    <div slot="header-content">Let's see how this works. By the way, this is header content</div>-->
<!--    <div slot="main-content">Now this is something different, this is main content</div>-->
<!--</mdnra-main-layout>-->

<section class="section">
  <h2>{{ "REGISTRATION.CHECK_EMAIL" | translate }}</h2>
  <p [innerHtml]="'REGISTRATION.CHECK_EMAIL_DESC' | translate: {email: email}"></p>

  <a class="link resend" mat-button *ngIf="!resent" (click)="resendEmail()"
    ><h5>{{ "REGISTRATION.SEND_AGAIN" | translate }}</h5></a
  >
  <p class="message">{{ resentMessage }}</p>

  <div class="bottom">
    <button
      class="next-button"
      mat-flat-button
      type="button"
      [routerLink]="['/login']"
    >
      {{ "REGISTRATION.TO_LOGIN" | translate }}
    </button>
  </div>
</section>

import { Component, Input } from '@angular/core';

@Component({
  selector: 'mdnra-progress-tree',
  templateUrl: './progress-tree.component.html',
  styleUrls: ['./progress-tree.component.scss'],
})
export class ProgressTreeComponent {
  @Input()
  disableAnimation: boolean = false;

  @Input()
  level: number = 1;

  @Input()
  set percentage(p: number) {
    this._percentage = p;
    this.displayProgress(360 * p);
  }

  get percentage(): number {
    return this._percentage;
  }

  private _percentage: number = 0;

  constructor() {}

  private displayProgress(degrees: number): void {
    const style = document.documentElement.style;

    if (degrees < 180) {
      style.setProperty('--deg1', degrees + 'deg');
      style.setProperty('--deg2', 0 + 'deg');
    } else {
      style.setProperty('--deg1', 180 + 'deg');
      style.setProperty(
        '--deg2',
        (degrees > 360 ? 180 : degrees - 180) + 'deg'
      );
    }
  }
}

import { Component, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { PatientGoalService } from 'app/core/services/patient-goal/patient-goal.service';
import { ProgressTreeModel } from 'app/core/services/patient/progress-tree.model';
import { WeeklyGoalCompletedComponent } from '../weekly-goal-completed/weekly-goal-completed.component';

@Component({
  selector: 'mdnra-weekly-goal-modal',
  templateUrl: './weekly-goal-modal.component.html',
  styleUrls: ['./weekly-goal-modal.component.scss'],
})
export class WeeklyGoalModalComponent {
  scoreDelta: any;
  score: any;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private readonly dialog: MatDialog,
    public dialogRef: MatDialogRef<WeeklyGoalModalComponent>,
    private readonly patientGoalService: PatientGoalService
  ) {}

  complete(succeeded: boolean, goalId: string) {
    const body = {
      succeeded: succeeded,
      ambition: this.data.goalObject?.ambition,
      type: this.data.goalObject?.type,
    };

    this.patientGoalService.updateGoal(goalId, body).subscribe({
      next: (response: ProgressTreeModel) => {
        this.score = response?.leaves;
        this.scoreDelta = response?.leavesDelta;
        const dialogRef = this.dialog.open(WeeklyGoalCompletedComponent, {
          width: '100%',
          height: '100%',
          maxWidth: '100vw',
          data: {
            succeeded: succeeded,
            score: response?.leaves,
            scoreDelta: response?.leavesDelta,
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          this.dialogRef.close({
            event: 'close',
            data: {
              goal: result.data,
              completed: true,
            },
          });
        });
      },
      error: () => {
        console.error('Request failed with error');
      },
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}

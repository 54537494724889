import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mdnra-complete-view',
  templateUrl: './complete-view.component.html',
  styleUrls: ['./complete-view.component.scss'],
})
export class CompleteViewComponent {
  public currentLevel: number = 1;

  @Input()
  hasBack = true;

  @Input()
  title = '';

  @Input()
  description = '';

  @Input()
  redirectLabel? = '';

  @Input()
  receivedPoints = 0;

  @Input()
  totalPoints: number = 0;

  @Input()
  disableAnimation = false;

  @Output()
  redirectPressed = new EventEmitter<void>();

  @Output()
  closePressed = new EventEmitter<void>();

  redirectPressedHandler(): void {
    this.redirectPressed.emit();
  }

  closePressedHandler(): void {
    this.closePressed.emit();
  }
}

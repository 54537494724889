<div class="privacy-wrapper">
  <mdnra-back-button (closePressed)="goBack(popUp)"></mdnra-back-button>
  <br />
  <p><b>Datenschutzerklärung MyMEDIAN@Home</b></p>
  <br />
  <p>
    Die Anwendung MyMEDIAN@Home (nachfolgend „<b>App</b>“) ist eine webbasierte
    Anwendung für die digitale Tele-Reha-Nachsorge, die Ihnen vom Digitalen
    Nachsorgezentrum der MEDIAN ReHealth GmbH zur Verfügung gestellt wird. Die
    Anwendung richtet sich an orthopädische Rehabilitanden, um die Teilnahme an
    einer Tele-Reha-Nachsorge zu ermöglichen.
  </p>
  <br />
  <p>
    Weil uns der Schutz Ihrer Privatsphäre bei der Nutzung der App wichtig ist,
    möchten wir Sie mit den nachfolgenden Angaben darüber informieren, welche
    personenbezogenen Daten wir verarbeiten, wenn Sie die App nutzen, und wie
    wir mit diesen Daten umgehen. Darüber hinaus unterrichten wir Sie über die
    Rechtsgrundlagen für die Verarbeitung Ihrer Daten und, soweit die
    Verarbeitung zur Wahrung unserer berechtigten Interessen erforderlich ist,
    auch über unsere berechtigten Interessen.
  </p>
  <br />
  <p><b>1. Rechtsgrundlagen für die Verarbeitung personenbezogener Daten</b></p>
  <br />
  <p>
    Die Verarbeitung Ihrer personenbezogenen Daten erfolgt auf gesetzlicher
    Grundlage und – soweit erforderlich – auf Grundlage Ihrer Einwilligung.
    Wichtigste gesetzliche Grundlagen sind die EU Datenschutz-Grundverordnung
    (DSGVO) und das Bundesdatenschutzgesetz (BDSG).
  </p>
  <br />
  <p><b>2. Datenschutzrechtliche Verantwortlichkeit</b></p>
  <br />
  <p>
    Soweit nicht anders angegeben, ist Verantwortlicher für die im Folgenden
    erläuterten Verarbeitungen Ihrer Daten das Digitale Nachsorgezentrum der
    MEDIAN ReHealth GmbH.
  </p>
  <br />
  <p>
    <b
      >3. Verarbeitung der personenbezogenen Daten im Rahmen der Nutzung der
      App</b
    >
  </p>
  <br />
  <p>
    Bei der Nutzung der App werden von uns personenbezogene Daten über Sie
    verarbeitet. Unter personenbezogenen Daten sind sämtliche Informationen zu
    verstehen, die sich auf eine identifizierte oder identifizierbare natürliche
    Person beziehen, z. B. Name, E-Mail-Adresse, Geburtsdatum, Nutzerverhalten
    und technische Informationen.
  </p>
  <br />
  <p>
    Bestimmte Informationen werden bereits automatisch verarbeitet, sobald Sie
    die App verwenden. Welche personenbezogenen Daten genau verarbeitet werden,
    haben wir im Folgenden für Sie aufgeführt:
  </p>
  <div class="paragraph">
    <div>
      <span> 3.1 </span>
    </div>
    <div class="ms-lg-2">
      <p>
        Bevor Sie einen Nutzeraccount anlegen, verarbeiten wir die nachfolgend
        beschriebenen personenbezogenen Daten, um die komfortable Nutzung der
        Funktionen zu ermöglichen. Wenn Sie unsere App nutzen, verarbeiten wir
        die folgenden Daten, die für uns technisch erforderlich sind, um Ihnen
        die Funktionen unserer App anzubieten und die Stabilität und Sicherheit
        zu gewährleisten, sodass diese von uns verarbeitet werden können.
      </p>
      <ul>
        <li>Name, Vorname</li>
        <li>IP-Adresse</li>
        <li>Betriebssystem</li>
        <li>Datum und Uhrzeit der Anfrage</li>
        <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
        <li>Inhalt der Anforderung (besuchte Seite)</li>
        <li>Zugriffsstatus/HTTP-Statuscode</li>
        <li>jeweils übertragene Datenmenge</li>
        <li>Sprache und Version der Browsersoftware</li>
        <li>Name Ihres mobilen Endgerätes</li>
        <li>E-Mail-Adresse</li>
        <li>MEDIAN Third Party ID (eineindeutige Patientennummer)</li>
      </ul>
      <p>
        Diese personenbezogenen Daten werden automatisch an uns übermittelt,
        teilweise in Logfiles temporär aber nicht permanent gespeichert. Sie
        sind notwendig, um Ihnen die App und die damit verbundenen Funktionen
        zur Verfügung zu stellen, die Funktionen und Leistungsmerkmale der App
        zu verbessern und Missbrauch sowie Fehlfunktionen vorzubeugen und zu
        beseitigen. Diese Datenverarbeitung ist dadurch gerechtfertigt, dass wir
        ein berechtigtes Interesse daran haben, die Funktionsfähigkeit und den
        fehlerfreien Betrieb der App zu gewährleisten, das hier Ihre Rechte und
        Interessen am Schutz Ihrer personenbezogenen Daten im Sinne von Art.6
        Abs.1 lit.f) DSGVO überwiegt.
      </p>
      <p>
        Die Nutzung der App dient der Durchführung des Behandlungsvertrages, den
        Sie im Rahmen der digitalen Tele-Reha-Nachsorge mit uns geschlossen
        haben sowie der Durchführung des Nutzungsvertrages für diese App.
        Rechtsgrundlage der Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO
        (Anbahnung bzw. Durchführung eines Vertrages).
      </p>
      <br />
      <p>
        Cookies: Zusätzlich zu den zuvor genannten Daten werden bei Ihrer
        Nutzung unserer mobilen App technische Hilfsmittel für verschiedene
        Funktionen verwendet, insbesondere Cookies, die auf Ihrem Endgerät
        gespeichert werden können. Cookies sind Textdateien oder Informationen
        in einer Datenbank, die im Gerätespeicher Ihres mobilen Endgeräts
        abgelegt und der von Ihnen verwendeten mobilen App zugeordnet
        gespeichert werden. Durch Cookies können der Stelle, die den Cookie
        setzt, bestimmte Informationen zufließen. Cookies können keine Programme
        ausführen oder Viren auf Ihr mobiles Endgerät übertragen, sondern dienen
        primär dazu, das Internetangebot schneller und nutzerfreundlicher
        auszugestalten.
      </p>
      <br />
      <p>
        Signierte Cookies: Diese App nutzt selbst-signierte Cookies, welche dazu
        verwendet werden auf Inhalte, welche auf einer Cloud-Plattform
        gespeichert sind, zuzugreifen. Diese Inhalte sind ohne benannte Cookies
        nicht erreichbar, was selbige für einen voll umfänglichen Gebrauch der
        App notwendig macht.
      </p>
    </div>
    <br />
  </div>
  <div class="paragraph">
    <div>
      <span> 3.2 </span>
    </div>
    <div class="ms-lg-2">
      <p>
        Wenn Sie einen Nutzer-Account erstellen oder sich anmelden, verarbeiten
        wir Ihre Zugangsdaten (E-Mail-Adresse und Passwort), um Ihnen den Zugang
        zu Ihrem Nutzer-Account zu gewähren und diesen zu verwalten
        („Pflichtangaben“). Pflichtangaben im Rahmen der Registrierung sind mit
        einem Sternchen gekennzeichnet und sind für den Abschluss des
        Nutzungsvertrages erforderlich. Wenn Sie diese Daten nicht angeben,
        können Sie keinen Nutzer-Account erstellen.
      </p>
      <br />
      <p>
        Darüber hinaus können Sie folgende freiwillige Angaben während oder nach
        Abschluss der Registrierung machen: Telefonnummer.
      </p>
      <br />
      <p>
        Die Pflichtangaben verwenden wir, um Sie beim Login zu authentifizieren
        und Anfragen zur Rücksetzung Ihres Passwortes nachzugehen. Die von Ihnen
        im Rahmen der Registrierung oder einer Anmeldung eingegebenen Daten
        werden von uns verarbeitet und verwendet, (1) um Ihre Berechtigung zur
        Verwaltung des Nutzer-Accounts zu verifizieren; (2) die
        Nutzungsbedingungen der App sowie alle damit verbundenen Rechte und
        Pflichten durchzusetzen und (3) mit Ihnen in Kontakt zu treten, um Ihnen
        technische oder rechtliche Hinweise, Updates, Sicherheitsmeldungen oder
        andere Nachrichten, die etwa die Verwaltung des Nutzer-Accounts
        betreffen, senden zu können.
      </p>
      <p>
        Freiwillige Angaben verwenden wir, um diese entsprechend Ihrer
        vorgenommenen Einstellungen im Rahmen der App anzuzeigen und an ihren
        behandelnden Arzt / Therapeuten weiterzuleiten.
      </p>
      <br />
      <p>
        Während der Nutzung der App können Sie verschiedene Informationen,
        Aufgaben und Aktivitäten eingeben, verwalten und bearbeiten. Diese
        Informationen umfassen insbesondere folgende Daten: Therapiedaten (z.B.
        Wochenziele), Feedback zu Übungen, Antworten auf Fragebögen, Hilfe- und
        Kontaktnachrichten.
      </p>
      <br />
      <p>
        Name, Anschrift, E-Mail und Telefonnummer werden für die Kontaktaufnahme
        durch das digitale Therapiezentrum verwendet. Alle Aktionen innerhalb
        der App werden aufgezeichnet, da diese als Therapieminuten gegenüber der
        Deutschen Rentenversicherung (DRV) nachgewiesen werden müssen.
        Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. c) DSGVO.
      </p>
      <br />
      <p>
        Hier wird z.B. aufgezeichnet, wie lange ein Video angesehen oder wie oft
        bestimmter Content konsumiert wurde. Dies betrifft auch generelle
        Informationen wie Sitzungszeiten oder die Dauer einer Sitzung. Auch
        Antworten auf die hinterlegten Fragebögen werden aufgezeichnet und auf
        Verlangen der DRV an diese in anonymisierter Form ausgehändigt.
      </p>
      <br />
      <p>
        Ohne die Erbringung dieser Nachweise ist eine Teilnahme am
        Tele-Reha-Nachsorgeprogramm der DRV nicht möglich.
      </p>
      <br />
      <p>
        Die App erfordert darüber hinaus Internetzugriff. Dieser wird benötigt,
        um Ihre Eingaben auf unseren Servern zu speichern.
      </p>
      <br />
      <p>
        Wir verarbeiten anonymisierte Nutzungsdaten für statistische Zwecke und
        zur Verbesserung der Funktionalitäten der App.
      </p>
      <br />
      <p>
        Die Rechtsgrundlage für diese Datenverarbeitung ist Ihre Einwilligung
        nach Art. 6 Abs. 1 S. 1 lit. a) i. V. m. Art. 9 Abs. 2 lit. a) DSGVO.
      </p>
      <br />
    </div>
  </div>
  <p><b>4. Weitergabe und Übertragung von Daten</b></p>
  <br />
  <p>
    Eine Weitergabe Ihrer personenbezogenen Daten ohne Ihre ausdrückliche
    vorherige Einwilligung erfolgt neben den explizit in dieser
    Datenschutzerklärung genannten Fällen lediglich dann, wenn es gesetzlich
    zulässig bzw. erforderlich ist. Dies kann u.a. der Fall sein, wenn die
    Verarbeitung erforderlich ist, um lebenswichtige Interessen des Nutzers oder
    einer anderen natürlichen Person zu schützen.
  </p>
  <div class="paragraph">
    <div>
      <span> 4.1</span>
    </div>
    <div class="ms-lg-2">
      <p>
        Die anlässlich der Registrierung angegebenen Daten werden für
        Verwaltungszwecke und die gemeinsame Patientenbetreuung innerhalb der
        MEDAN Gruppe im Rahmen des Erforderlichen weitergeben. Eine Weitergabe
        der personenbezogenen Daten ist dadurch gerechtfertigt, dass wir ein
        berechtigtes Interesse daran haben, die Daten für die Erbringung der
        Reha-Nachsorge (z. B. Einbindung von Ärzten und Therapeuten) sowie für
        administrative Zwecke (z. B. Abrechnung der erbrachten Leistungen)
        innerhalb unserer Unternehmensgruppe weiterzugeben und Ihre Rechte und
        Interessen am Schutz Ihrer personenbezogenen Daten im Sinne von Art.6
        Abs.1 lit.f) DSGVO nicht überwiegen.
      </p>
    </div>
  </div>
  <div class="paragraph">
    <div>
      <span> 4.2</span>
    </div>
    <div class="ms-lg-2">
      <p>
        Wenn es zur Aufklärung einer rechtswidrigen bzw. missbräuchlichen
        Nutzung der App oder für die Rechtsverfolgung erforderlich ist, werden
        personenbezogene Daten an die Strafverfolgungsbehörden oder andere
        Behörden sowie ggf. an geschädigte Dritte oder Rechtsberater
        weitergeleitet. Dies geschieht jedoch nur, wenn Anhaltspunkte für ein
        gesetzwidriges bzw. missbräuchliches Verhalten vorliegen. Eine
        Weitergabe kann auch stattfinden, wenn dies der Durchsetzung von
        Nutzungsbedingungen oder anderen Rechtsansprüchen dient. Wir sind zudem
        gesetzlich verpflichtet, auf Anfrage bestimmten öffentlichen Stellen
        Auskunft zu erteilen. Dies sind Strafverfolgungsbehörden, Behörden, die
        bußgeldbewährte Ordnungswidrigkeiten verfolgen und Finanzbehörden.
      </p>
      <br />
      <p>
        Eine etwaige Weitergabe der personenbezogenen Daten ist dadurch
        gerechtfertigt, dass (1) die Verarbeitung zur Erfüllung einer
        rechtlichen Verpflichtung erforderlich ist, der wir gemäß Art.6 Abs.1
        lit.f) DSGVO i. V. m. nationalen rechtlichen Vorgaben zur Weitergabe von
        Daten an Strafverfolgungsbehörden unterliegen, oder (2) wir ein
        berechtigtes Interesse daran haben, die Daten bei Vorliegen von
        Anhaltspunkten für missbräuchliches Verhalten oder zur Durchsetzung
        unserer Nutzungsbedingungen, anderer Bedingungen oder von
        Rechtsansprüchen an die genannten Dritten weiterzugeben und Ihre Rechte
        und Interessen am Schutz Ihrer personenbezogenen Daten im Sinne von
        Art.6 Abs.1 lit.f) DSGVO nicht überwiegen.
      </p>
      <br />
    </div>
  </div>
  <div class="paragraph">
    <div>
      <span> 4.3</span>
    </div>
    <div class="ms-lg-2">
      <p>
        Wir sind für die Erbringung unseres Dienstes auf vertraglich verbundene
        Gesellschaften der MEDIAN Unternehmensgruppe sowie auf Fremdunternehmen
        als externe Dienstleister angewiesen. Zusammen mit den behandelnden
        MEDIAN Kliniken verantworten wir als Digitales Nachsorgezentrum die
        administrative und medizinische Betreuung der Patienten bei der Nutzung
        der App. Die TWT Digital Health GmbH, Im Schuhmachergewann 6, 69123
        Heidelberg, hat die Anwendung entwickelt, stellt diese im hauseigenen
        Rechenzentrum bereit und ist für die technische Wartung sowie den
        Betrieb verantwortlich. Die Navitas Solutions GmbH, Berliner Allee 261,
        13088 Berlin, stellt die Administrationsoberfläche für die Mitarbeiter
        im Digitalen Nachsorgezentrum bereit und ist für deren Betrieb und
        Wartung verantwortlich. Betrieben wird die Oberfläche im Rechenzentrum
        der TWL-KOM GmbH, Donnersbergweg 4, 67059 Ludwigshafen, als
        Infrastruktur-Dienstleister. Das Controlling der Daten erfolgt im
        Rechenzentrum der OEDIV Oetker Daten- und Informationsverarbeitung KG,
        Bechterdisser Straße 10, 33719 Bielefeld, als IT-Dienstleister für
        SAP-Systeme.
      </p>
      <br />
      <p>
        Alle Unternehmen sind nach Art.28 Abs.1 DSGVO als Auftragsverarbeiter
        sorgfältig ausgewählt, überprüft und vertraglich verpflichtet worden,
        sämtliche personenbezogenen Daten ausschließlich entsprechend unserer
        Weisungen zu verarbeiten.
      </p>
      <br />
    </div>
  </div>
  <div class="paragraph">
    <div>
      <span> 4.4</span>
    </div>
    <div class="ms-lg-2">
      <p>
        Nach Abschluss der Tele-Reha-Nachsorge übermittelt das Digitale
        Nachsorgezentrum relevante Daten gemäß IRENA Fachkonzept der DRV an die
        behandelnde Klinik, welche anschließend zu Abrechnungszwecken
        dokumentiert werden. Es werden folgende relevante Daten übermittelt: (i)
        Kontaktdaten, (ii) Dokumentation der erbrachten Leistungen nach
        aktueller Klassifizierung therapeutischer Leistungen der DRV.
      </p>
      <br />
    </div>
  </div>
  <div class="paragraph">
    <div>
      <span> 4.5</span>
    </div>
    <div class="ms-lg-2">
      Im Rahmen der Weiterentwicklung unseres Geschäfts kann es dazu kommen,
      dass sich die Struktur unseres Unternehmens wandelt, indem die Rechtsform
      geändert wird, Tochtergesellschaften, Unternehmensteile oder Bestandteile
      gegründet, gekauft oder verkauft werden. Bei solchen Transaktionen werden
      die Kundeninformationen gegebenenfalls zusammen mit dem zu übertragenden
      Teil des Unternehmens weitergegeben. Bei jeder Weitergabe von
      personenbezogenen Daten an Dritte in dem vorbeschriebenen Umfang tragen
      wir dafür Sorge, dass dies in Übereinstimmung mit dieser
      Datenschutzerklärung und dem anwendbaren Datenschutzrecht erfolgt. Eine
      etwaige Weitergabe der personenbezogenen Daten ist dadurch gerechtfertigt,
      dass wir ein berechtigtes Interesse daran haben, unsere Unternehmensform
      den wirtschaftlichen und rechtlichen Gegebenheiten entsprechend bei Bedarf
      anzupassen und Ihre Rechte und Interessen am Schutz Ihrer
      personenbezogenen Daten im Sinne von Art.6 Abs.1 lit.f) DSGVO nicht
      überwiegen.
    </div>
  </div>

  <p><b>5. Datenübermittlungen in Drittländer</b></p>
  <br />
  <p>
    Wir verarbeiten Ihre Daten ausschließlich innerhalb der Europäischen Union,
    dem Europäischen Wirtschaftsraum.
  </p>

  <p><b>6. Zweckänderungen</b></p>
  <br />
  <p>
    Verarbeitungen Ihrer personenbezogenen Daten zu anderen als den
    beschriebenen Zwecken erfolgen nur, soweit eine Rechtsvorschrift dies
    erlaubt oder Sie in den geänderten Zweck der Datenverarbeitung eingewilligt
    haben. Im Falle einer Weiterverarbeitung zu anderen Zwecken als denen, für
    den die Daten ursprünglich erhoben worden sind, informieren wir Sie vor der
    Weiterverarbeitung über diese anderen Zwecke und stellen Ihnen sämtliche
    weitere hierfür maßgeblichen Informationen zur Verfügung.
  </p>
  <br />

  <p><b>7. Zeitraum der Datenspeicherung</b></p>
  <br />
  <p>
    Wir löschen oder anonymisieren Ihre personenbezogenen Daten, sobald diese
    für die Zwecke, für die wir die Daten nach den vorstehenden Ziffern erhoben
    oder verwendet haben, nicht mehr erforderlich sind. In der Regel speichern
    wir Ihre personenbezogenen Daten für die Dauer des App-Nutzungsverhältnisses
    sowie zur Sicherstellung einer korrekten Abrechnung für bis zu 6 Monate nach
    Beendigung des Tele-Reha-Nachsorgeprogrammes in der Anwendung. Die
    technischen Nutzungsdaten werden gelöscht, die Behandlungsdaten werden in
    die digitale Patientenakte der behandelnden Klinik überführt und dort für 30
    Jahre gespeichert. Rechtliche Vorgaben zur Aufbewahrung und Löschung
    personenbezogener Daten, insbesondere aus steuerrechtlichen Gründen, bleiben
    unberührt.
  </p>
  <br />

  <p><b>8. Ihre Rechte als Betroffene bzw. Betroffener</b></p>
  <p>
    Sie haben uns gegenüber die folgenden Rechte hinsichtlich der Sie
    betreffenden personenbezogenen Daten.
  </p>

  <div class="paragraph">
    <div>
      <span>8.1</span>
    </div>
    <div class="ms-lg-2">
      <p>Auskunftsrecht</p>
      <p>
        Sie haben das Recht, von uns eine Auskunft über die von uns
        verarbeiteten, Sie betreffenden personenbezogenen Daten im Umfang des
        Art.15 DSGVO zu erhalten. Ihr Auskunftsersuchen können Sie postalisch
        oder per E-Mail an die unten angegebenen Adressen senden.
      </p>
      <br />
    </div>
  </div>

  <div class="paragraph">
    <div>
      <span>8.2</span>
    </div>
    <div class="ms-lg-2">
      <p>Recht zur Berichtigung unrichtiger Daten</p>
      <p>
        Sie haben das Recht, von uns die unverzügliche Berichtigung der Sie
        betreffenden personenbezogenen Daten zu verlangen, sofern diese
        unrichtig sein sollten. Wenden Sie sich hierfür bitte an die unten
        angegebenen Kontaktadressen.
      </p>
      <br />
    </div>
  </div>

  <div class="paragraph">
    <div>
      <span>8.3</span>
    </div>
    <div class="ms-lg-2">
      <p>Recht auf Löschung</p>
      <p>
        Sie haben das Recht, unter den in Art.17 DSGVO beschriebenen
        Voraussetzungen von uns die Löschung der Sie betreffenden
        personenbezogenen Daten zu verlangen. Diese Voraussetzungen sehen
        insbesondere ein Löschungsrecht vor, wenn die personenbezogenen Daten
        für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet
        wurden, nicht mehr notwendig sind, sowie in Fällen der unrechtmäßigen
        Verarbeitung, des Vorliegens eines Widerspruchs oder des Bestehens einer
        Löschungspflicht nach Unionsrecht oder dem Recht des Mitgliedstaates,
        dem wir unterliegen. Zum Zeitraum der Datenspeicherung siehe im Übrigen
        Ziffer 5 dieser Datenschutzerklärung. Um Ihr Recht auf Löschung geltend
        zu machen, wenden Sie sich bitte an die unten angegebenen
        Kontaktadressen.
      </p>
      <br />
    </div>
  </div>

  <div class="paragraph">
    <div>
      <span>8.4</span>
    </div>
    <div class="ms-lg-2">
      <p>Recht auf Einschränkung der Verarbeitung</p>
      <p>
        Sie haben das Recht, von uns die Einschränkung der Verarbeitung nach
        Maßgabe des Art.18 DSGVO zu verlangen. Dieses Recht besteht
        insbesondere, wenn die Richtigkeit der personenbezogenen Daten zwischen
        dem Nutzer und uns umstritten ist, für die Dauer, welche die Überprüfung
        der Richtigkeit erfordert, sowie im Fall, dass der Nutzer bei einem
        bestehenden Recht auf Löschung anstelle der Löschung eine eingeschränkte
        Verarbeitung verlangt; ferner für den Fall, dass die Daten für die von
        uns verfolgten Zwecke nicht länger erforderlich sind, der Nutzer sie
        jedoch zur Geltendmachung, Ausübung oder Verteidigung von
        Rechtsansprüchen benötigt sowie, wenn die erfolgreiche Ausübung eines
        Widerspruchs zwischen uns und dem Nutzer noch umstritten ist. Um Ihr
        Recht auf Einschränkung der Verarbeitung geltend zu machen, wenden Sie
        sich bitte an die unten angegebenen Kontaktadressen.
      </p>
      <br />
    </div>
  </div>
  <div class="paragraph">
    <div>
      <span>8.5</span>
    </div>
    <div class="ms-lg-2">
      <p>Recht auf Datenübertragbarkeit</p>
      <p>
        Sie haben das Recht, von uns die Sie betreffenden personenbezogenen
        Daten, die Sie uns bereitgestellt haben, in einem strukturierten,
        gängigen, maschinenlesbaren Format nach Maßgabe des Art.20 DSGVO zu
        erhalten. Um Ihr Recht auf Datenübertragbarkeit geltend zu machen,
        wenden Sie sich bitte an die unten angegebenen Kontaktadressen.
      </p>
    </div>
  </div>
  <p><b>9. Widerspruchsrecht</b></p>
  <br />
  <p>
    Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation
    ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener
    Daten, die u. a. aufgrund von Art.6 Abs.1 lit.e) oder f) DSGVO erfolgt,
    Widerspruch nach Art.21 DSGVO einzulegen. Wir werden die Verarbeitung Ihrer
    personenbezogenen Daten einstellen, es sei denn, wir können zwingende
    schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen,
    Rechte und Freiheiten überwiegen, oder wenn die Verarbeitung der
    Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient.
  </p>
  <br />

  <p><b>10. Beschwerderecht</b></p>
  <p>
    Sie haben ferner das Recht, sich bei Beschwerden an die zuständige
    Aufsichtsbehörde zu wenden. Für das Digitale Nachsorgezentrum der MEDIAN
    ReHealth GmbH ist dies der Landesbeauftragte für Datenschutz und
    Informationsfreiheit, Nordrhein-Westfalen, Postfach 200444, 40102
    Düsseldorf, poststelle@ldi.nrw.de.
  </p>
  <br />

  <p>
    <b
      >11. Verantwortlicher für die Datenverarbeitung und
      Datenschutzbeauftragter</b
    >
  </p>
  <br />
  <p>
    Verantwortlich für die Datenverarbeitung ist Digitales Nachsorgezentrum der
    MEDIAN ReHealth GmbH Fährstraße 1, 40221 Düsseldorf
  </p>
  <br />
  <p>
    Der betriebliche Datenschutzbeauftragte ist Herr Christian Volkmer Projekt
    29 GmbH & Co. KG, Ostengasse 14, 93047 Regensburg E-Mail:
    anfrage@projekt29.de, Tel.: +49 (0)941-2986930
  </p>
  <br />
  <p><b>12. Änderungen dieser Datenschutzerklärung</b></p>
  <br />
  <p>
    Wir halten diese Datenschutzerklärung auf dem neuesten Stand. Deshalb
    behalten wir uns vor, sie von Zeit zu Zeit zu ändern und Änderungen bei der
    Erhebung, Verarbeitung oder Nutzung Ihrer Daten nachzupflegen. Die aktuelle
    Fassung der Datenschutzerklärung ist stets unter „Impressum & Datenschutz“
    innerhalb der App abrufbar.
  </p>
  <br />
  <p><b>Stand: </b> <span>11.04.2022</span></p>
</div>

<mdnra-section-layout
  [title]="'HOME.ENTER_STEPS.CARD.TITLE' | translate"
  [description]="
    'HOME.ENTER_STEPS.CARD.DESCRIPTION'
      | translate : { therapyWeekStartDay, therapyWeekEndDay }
  "
>
  <div slot="section-content">
    <mdnra-card-layout
      class="enter-steps"
      [header]="'HOME.ENTER_STEPS.CARD.HEADER' | translate"
      [description]="getDescription()"
      [icon]="'fa-walking'"
      [colorScheme]="'home'"
      (click)="openEnterStepsDialog()"
    >
    </mdnra-card-layout>
  </div>
</mdnra-section-layout>

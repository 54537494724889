import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { LoaderService } from './loader.service';
import { MessagingService } from './core/services/messaging/messaging.service';
import {
  map,
  startWith,
  switchAll,
  switchMap,
  take,
  throttleTime,
} from 'rxjs/operators';
import { fromEvent, merge, timer } from 'rxjs';

import { Router } from '@angular/router';
import { KeepAliveService } from './core/services/keep-alive/keep-alive.service';
import { DOCUMENT } from '@angular/common';
import { AuthenticationService } from './core/services/authentication/authentication.service';

declare const ngDevMode: boolean;

@Component({
  selector: 'mdnra-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  current = '';
  displayError = false;
  loginError = false;

  @ViewChild('snackbarMessageTpl') snackbarMessageTpl!: TemplateRef<any>;
  @ViewChild('inactivityLogoutWarningTpl')
  inactivityLogoutWarningTpl!: TemplateRef<any>;

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: BeforeUnloadEvent): void {
    this.authenticationService.queueLogout(
      +new Date() + 500 * (ngDevMode ? 10 : 1)
    );
  }

  constructor(
    private readonly translate: TranslateService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private readonly snackbar: MatSnackBar,
    private readonly messagingService: MessagingService,
    private readonly elementRef: ElementRef,
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
    private readonly keepAliveService: KeepAliveService,
    public readonly loaderService: LoaderService,
    @Inject(DOCUMENT) public document: Document
  ) {}

  ngOnInit(): void {
    /*
    "https://www.drittanbieter-url.de/login?token=eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJNRURJQU4iLCJwaWQiOiIwMDEtMTIzNCIsInR5cGUiOiJteW1lZGlhbiIsImlhdCI6MTY1ODQxNTk2OCwiZXhwIjoxNzU4NDE5NTY4fQ.kVa-XM6xmAWWh-cR9SkLRocpk48D43VDYjwY9FEOMuY";
    */
    this.current = this.document.location.href;
    const match = this.current.match('http.*\\/.*\\?token\\=(.*)');
    if (
      match &&
      !this.current.includes('reset') &&
      !this.current.includes('email-confirm')
    ) {
      this.authenticationService.loginWithMedian(match[1]);
    }

    this.matIconRegistry.addSvgIcon(
      'long-arrow-right',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/pfad1499.svg'
      )
    );

    this.messagingService.error$
      .pipe(throttleTime(3000, undefined, { leading: true, trailing: true }))
      .subscribe((message) =>
        this.snackbar.openFromTemplate(this.snackbarMessageTpl, {
          data: { message, prefix: this.translate.instant('COMMON.ERROR') },
          duration: 3000,
          panelClass: 'snackbar-error-panel',
        })
      );

    this.messagingService.warning$
      .pipe(throttleTime(3000, undefined, { leading: true, trailing: true }))
      .subscribe((message) =>
        this.snackbar.openFromTemplate(this.snackbarMessageTpl, {
          data: { message, prefix: this.translate.instant('COMMON.WARNING') },
          duration: 3000,
          panelClass: 'snackbar-warning-panel',
        })
      );

    this.messagingService.info$
      .pipe(throttleTime(3000, undefined, { leading: true, trailing: true }))
      .subscribe((message) =>
        this.snackbar.openFromTemplate(this.snackbarMessageTpl, {
          data: { message, prefix: this.translate.instant('COMMON.INFO') },
          duration: 3000,
          panelClass: 'snackbar-info-panel',
        })
      );

    const element = this.elementRef.nativeElement;
    merge(
      fromEvent(element, 'mousedown'),
      fromEvent(element, 'mousemove'),
      fromEvent(element, 'keypress'),
      fromEvent(element, 'scroll'),
      fromEvent(element, 'touchstart'),
      this.keepAliveService.ping
    )
      .pipe(
        startWith(true),
        map((_) => timer(1800000)),
        switchAll(),
        switchMap((_) => this.authenticationService.account$.pipe(take(1))),
        map((account) => {
          if (!Object.keys(account).length) {
            return;
          }
          setTimeout(() => {
            this.authenticationService.logout();
            this.router.navigateByUrl('/', { skipLocationChange: true });
            this.snackbar.openFromTemplate(this.inactivityLogoutWarningTpl, {
              duration: 2500,
              panelClass: 'snackbar-warning-panel',
            });
          });
        })
      )
      .subscribe();

    this.document.getElementsByClassName('app-loader')[0]?.remove();
  }
}

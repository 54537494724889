<div class="menu">
    <button mat-flat-button
            class="menu-button"
            routerLink="/home"
            routerLinkActive="is-active">
        <mat-icon class="menu-icon" fontSet="far" fontIcon="fa-home"></mat-icon>
        <mat-icon class="menu-icon-active" fontSet="fas" fontIcon="fa-home"></mat-icon>
        <span>{{'MENU.HOME' | translate}}</span>
    </button>
    <button mat-flat-button
            class="menu-button"
            routerLink="/therapy"
            routerLinkActive="is-active">
        <mat-icon class="menu-icon" fontSet="far" fontIcon="fa-walking"></mat-icon>
        <mat-icon class="menu-icon-active" fontSet="fas" fontIcon="fa-walking"></mat-icon>
        <span>{{'MENU.THERAPY' | translate}}</span>
    </button>
    <button mat-flat-button
            class="menu-button wellbeing"
            routerLink="/wellbeing"
            routerLinkActive="is-active">
        <mat-icon class="menu-icon" fontSet="far" fontIcon="fa-smile"></mat-icon>
        <mat-icon class="menu-icon-active" fontSet="fas" fontIcon="fa-smile"></mat-icon>
        <span>{{'MENU.WELLBEING' | translate}}</span>
    </button>
    <button mat-flat-button
            class="menu-button"
            routerLink="/knowledge"
            routerLinkActive="is-active">
        <mat-icon class="menu-icon" fontSet="far" fontIcon="fa-lightbulb-on"></mat-icon>
        <mat-icon class="menu-icon-active" fontSet="fas" fontIcon="fa-lightbulb-on"></mat-icon>
        <span>{{'MENU.KNOWLEDGE' | translate}}</span>
    </button>
    <button mat-flat-button
            class="menu-button"
            routerLink="/more"
            routerLinkActive="is-active">
        <mat-icon class="menu-icon" fontSet="far" fontIcon="fa-ellipsis-h"></mat-icon>
        <mat-icon class="menu-icon-active" fontSet="fas" fontIcon="fa-ellipsis-h"></mat-icon>
        <span>{{'MENU.MORE' | translate}}</span>
    </button>

    <h5 class="logout-button" (click)="logout()" routerLink="/">{{'COMMON.LOGOUT_MAIN' | translate}}</h5>
</div>

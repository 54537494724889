<div class="circular">
  <div class="inner"></div>
  <div class="inner2"></div>
  <div class="tree">
    <img
      class="tree leaf{{ level - 1 }}"
      src="../assets/backgrounds/leaf{{ level - 1 }}.svg"
      alt=""
    />
  </div>
  <div class="circle" [class.no-animation]="disableAnimation">
    <div class="bar left">
      <div class="progress"></div>
    </div>
    <div class="bar right">
      <div class="progress"></div>
    </div>
  </div>
</div>

<div class="imprint-wrapper">
  <mdnra-back-button (closePressed)="goBack(popUp)"></mdnra-back-button>
  <h2 class="header">{{ "MORE.IMPRINT_DATA_PROTECTION" | translate }}</h2>

  <div class="section">
    <b>{{ "IMPRINT.ADDRESS_TITLE" | translate }}</b>
    <div class="data">
      <div>{{ "IMPRINT.COMPANY_NAME" | translate }}</div>
      <div>{{ "IMPRINT.ADDRESS_DESC" | translate }}</div>
      <div>{{ "IMPRINT.CONTACT_PHONE" | translate }}</div>
      <div>{{ "IMPRINT.CONTACT_EMAIL" | translate }}</div>
    </div>
  </div>

  <div class="section">
    <b>{{ "IMPRINT.COURT_TITLE" | translate }}</b>
    <div class="data">
      <div>{{ "IMPRINT.COURT_CITY" | translate }}</div>
      <div>{{ "IMPRINT.COURT" | translate }}</div>
      <div>{{ "IMPRINT.COURT_HRB" | translate }}</div>
      <div>{{ "IMPRINT.COURT_UST" | translate }}</div>
    </div>
  </div>
  <!--
  <p><b>Persönlich haftender Gesellschafter</b> <br>Remedco Mgmt. B.V., Berlin <br>Registergericht: AG Charlottenburg HRB <br>162698 B</p>
-->
  <div class="section">
    <b>{{ "IMPRINT.CEO_TITLE" | translate }}</b>
    <div class="data">
      <div>{{ "IMPRINT.CEO_1" | translate }}</div>
      <div>{{ "IMPRINT.CEO_2" | translate }}</div>
      <div>{{ "IMPRINT.CEO_3" | translate }}</div>
    </div>
  </div>
</div>

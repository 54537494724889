<div class="loading" *ngIf="loaderService.loading">
  <mat-spinner diameter="80"></mat-spinner>
</div>
<router-outlet></router-outlet>
<ng-template #snackbarMessageTpl let-data>
  <div class="snackbar-content">
    <span
      ><b>{{ data.prefix }}</b> {{ data.message }}</span
    >
  </div>
</ng-template>
<ng-template #inactivityLogoutWarningTpl>
  <div class="snackbar-content">
    <p>{{ "COMMON.INACTIVITY_WARNING" | translate }}</p>
    <!--    not needed for now-->
    <!--    <button mat-icon-button>-->
    <!--      <mat-icon fontSet="far" fontIcon="fa-times"></mat-icon>-->
    <!--    </button>-->
  </div>
</ng-template>
<mdnra-release-info></mdnra-release-info>

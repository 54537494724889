import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MessagingService } from 'app/core/services/messaging/messaging.service';
import { ResetPasswordService } from '../../../core/services/reset-password/reset-password.service';
import {
  passwordConfirmValidator,
  passwordValidator,
} from '../../reset.common';
import { ResetPasswordValidationModel } from './reset-password-validation.model';

@Component({
  selector: 'mdnra-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  @Input()
  token: string = '';

  @Input()
  birthday: string = '';

  @Input() set errors(err: { [key: string]: string[] }) {
    Object.entries(err).forEach(([field, validation]) => {
      (validation as string[]).forEach((error) => {
        if (field === 'newPassword') {
          this.passwordControl.setErrors({ [error]: true });
        }
        if (field === 'newPasswordMatch') {
          this.confirmPasswordControl.setErrors({ [error]: true });
        }
        if (field === 'dateOfBirth') {
          this.messagingService.pushError(
            this.translateService.instant(`ERRORS.${error}`)
          );
        }
      });
    });
  }

  @Input()
  data: ResetPasswordValidationModel = {
    password: '',
    confirmPassword: '',
  };

  @Output()
  changeStep: EventEmitter<ResetPasswordValidationModel> =
    new EventEmitter<ResetPasswordValidationModel>();

  @Output()
  updateStepData: EventEmitter<ResetPasswordValidationModel> =
    new EventEmitter<ResetPasswordValidationModel>();

  public showPassword: boolean = false;
  public showConfirmPassword: boolean = false;
  //TODO: Type form controls
  private passwordControl: UntypedFormControl = new UntypedFormControl(null, {
    validators: [Validators.required],
  });

  private confirmPasswordControl: UntypedFormControl = new UntypedFormControl(
    null,
    [Validators.required]
  );

  public passwordForm: UntypedFormGroup = this.formBuilder.group({
    password: this.passwordControl,
    confirmPassword: this.confirmPasswordControl,
  });

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly messagingService: MessagingService,
    private readonly resetPasswordService: ResetPasswordService,

    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.passwordControl.setValue(this.data.password);
    this.confirmPasswordControl.setValue(this.data.confirmPassword);

    this.passwordControl.setAsyncValidators(
      passwordValidator(
        this.resetPasswordService,
        this.confirmPasswordControl,
        this.token
      )
    );

    this.confirmPasswordControl.setAsyncValidators(
      passwordConfirmValidator(
        this.resetPasswordService,
        this.passwordControl,
        this.token
      )
    );

    this.confirmPasswordControl.valueChanges.subscribe(() =>
      this.passwordControl.updateValueAndValidity({ emitEvent: false })
    );

    this.passwordControl.valueChanges.subscribe(() =>
      this.confirmPasswordControl.updateValueAndValidity({ emitEvent: false })
    );
  }

  updateData() {
    this.updateStepData.emit({
      password: this.passwordControl.value,
      confirmPassword: this.confirmPasswordControl.value,
    });
  }

  nextStep() {
    this.changeStep.emit({
      password: this.passwordControl.value,
      confirmPassword: this.confirmPasswordControl.value,
    });
  }

  getErrorMessage(controlName: string): string {
    const control = this.passwordForm.get(controlName);
    if (control?.errors) {
      return this.messagingService.getControlErrorMessage(control.errors);
    }
    return '';
  }
}

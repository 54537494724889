import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'mdnra-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit {
  @ViewChild('media', { static: true })
  private media: ElementRef<HTMLVideoElement> = {} as ElementRef;

  @Input()
  videoSource: string = '';

  @Input()
  loop: boolean = false;

  @Input()
  autoplay: boolean = false;

  @Input()
  playbackRate: number = 1;

  @Output()
  isPlayingEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  videoEndReached: EventEmitter<void> = new EventEmitter<void>();

  ngOnInit(): void {
    const media = this.media.nativeElement;
    media.playbackRate = this.playbackRate;

    media.onpause = () => {
      this.isPlayingEvent.emit(false);
    };

    media.onplaying = () => {
      this.isPlayingEvent.emit(true);
    };
  }

  public play(): void {
    this.media.nativeElement.play();
  }

  public pause(): void {
    this.media.nativeElement.pause();
  }

  public stop(): void {
    this.pause();
    this.media.nativeElement.currentTime = 0;
  }

  public onVideoEndReached(): void {
    if (this.loop) {
      this.videoEndReached.emit();
    }
  }
}



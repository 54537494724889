<section class="section">
  <ng-container *ngIf="expired$ | async; else success">
    <h2>{{ "CONFIRM_EMAIL.NOT_VERIFIED" | translate }}</h2>
    <p>
      {{ "CONFIRM_EMAIL.NOT_VERIFIED_DESC" | translate }}
    </p>
    <div class="bottom">
      <button
        class="next-button"
        mat-flat-button
        type="button"
        [routerLink]="'/login'"
      >
        {{ "CONFIRM_EMAIL.BUTTON_LOGIN" | translate }}
      </button>
    </div>
  </ng-container>
</section>
<ng-template #success>
  <h2>{{ "CONFIRM_EMAIL.VERIFIED" | translate }}</h2>
  <p>
    {{ "CONFIRM_EMAIL.VERIFIED_DESC" | translate }}
  </p>
  <div class="bottom">
    <button
      class="next-button"
      mat-flat-button
      type="button"
      [routerLink]="'/login'"
    >
      {{ "CONFIRM_EMAIL.BUTTON_LOGIN" | translate }}
    </button>
  </div>
</ng-template>

<mdnra-section-layout
  [title]="'HOME.YOUR_WEEKLY_GOAL.CARD.TITLE' | translate"
  [description]="'HOME.YOUR_WEEKLY_GOAL.CARD.DESCRIPTION' | translate"
>
  <div slot="section-content">
    <mdnra-card-layout
      class="weekly-goal"
      [header]="'HOME.YOUR_WEEKLY_GOAL.CARD.HEADER' | translate"
      [description]="
        updatedGoal
          ? updatedGoal
          : currentGoal
          ? currentGoal.ambition
          : ('HOME.YOUR_WEEKLY_GOAL.CARD.EMPTY_MESSAGE' | translate)
      "
      [icon]="'fa-bullseye-arrow'"
      [colorScheme]="'home'"
      (click)="openSetGoalDialog()"
    >
    </mdnra-card-layout>
  </div>
</mdnra-section-layout>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { map } from 'rxjs/operators';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';

@Component({
  selector: 'mdnra-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss'],
})
export class ConfirmEmailComponent implements OnInit {
  expired$ = this.route.queryParams.pipe(map(({ expired }) => expired));

  constructor(
    private readonly route: ActivatedRoute,
    private readonly authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.authService.logout();
  }
}

import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { atLeast1Validator } from 'app/authentication/common';
import { PatientStepsNewModel } from 'app/core/services/patient-steps/patient-steps-new.model';
import { PatientStepsService } from 'app/core/services/patient-steps/patient-steps.service';
import { ProgressTreeModel } from 'app/core/services/patient/progress-tree.model';
import { MilestonesService } from '../../milestones/milestones.service';
import { PatientService } from 'app/core/services/patient/patient.service';

export const STEPS_PER_MINUTE = 100;
export const MAX_STEPS_IN_THERAPY_WEEK = 9000;

@Component({
  selector: 'mdnra-enter-steps-dialog',
  templateUrl: './enter-steps-dialog.component.html',
})
export class EnterStepsDialogComponent implements OnInit {
  public submittingSteps: boolean = false;
  public openCompleteView: boolean = false;
  public maxStepsTherapyWeekReached: boolean = false;
  //TODO: Type form controls
  public stepsControl = new UntypedFormControl(null, {
    validators: [Validators.pattern('[1-9][0-9]*')],
  });

  public minutesControl = new UntypedFormControl(null, {
    validators: [Validators.pattern('[1-9][0-9]*')],
  });

  public stepsForm: UntypedFormGroup = null as unknown as UntypedFormGroup;
  public responseData?: ProgressTreeModel;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly patientService: PatientService,
    private readonly patientStepsService: PatientStepsService,
    private readonly milestonesService: MilestonesService,
    private readonly dialog: MatDialog,
    public dialogRef: MatDialogRef<EnterStepsDialogComponent>
  ) {}

  ngOnInit(): void {
    this.stepsForm = this.formBuilder.group(
      {
        steps: this.stepsControl,
        minutes: this.minutesControl,
      },
      {
        validators: atLeast1Validator('steps', 'minutes'),
      }
    );
  }

  submit() {
    this.submittingSteps = true;
    const data: PatientStepsNewModel = {
      steps:
        +this.stepsControl.value + this.minutesControl.value * STEPS_PER_MINUTE,
    };
    this.patientStepsService.addSteps(data).subscribe({
      next: (response: ProgressTreeModel) => {
        this.responseData = response;
        this.patientService.updateTreeProgress(response);
        this.submittingSteps = false;

        this.patientStepsService.getCurrentSteps().subscribe((steps) => {
          this.maxStepsTherapyWeekReached = MAX_STEPS_IN_THERAPY_WEEK <= +steps;
          this.openCompleteView =
            response.leavesDelta > 0 || this.maxStepsTherapyWeekReached;
          if (!this.openCompleteView) {
            this.closeDialog();
          }
          this.milestonesService.openNewMilestoneDialog(response, this.dialog);
        });
      },
      error: () => {
        this.submittingSteps = false;
      },
    });
  }

  closeDialog() {
    this.dialogRef.close({
      event: 'close',
      data:
        +this.stepsForm.value.steps +
        (+this.stepsForm.value.minutes
          ? +this.stepsForm.value.minutes * STEPS_PER_MINUTE
          : 0),
    });
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { emailValidator, phoneValidator } from 'app/authentication/common';
import { MessagingService } from 'app/core/services/messaging/messaging.service';
import { Direction } from '../registration.model';
import { RegistrationService } from '../../../core/services/registration/registration.service';
import {
  ContactStepModel,
  RegistrationContactModel,
} from './registration-contact.model';

@Component({
  selector: 'mdnra-registration-contact',
  templateUrl: './registration-contact.component.html',
  styleUrls: ['./registration-contact.component.scss'],
})
export class RegistrationContactComponent implements OnInit {
  @Input()
  token: string = '';

  @Input()
  hasBackButton: boolean = false;

  @Input()
  data: RegistrationContactModel = {
    phone: '',
    email: '',
  };

  @Output()
  updateStepData: EventEmitter<RegistrationContactModel> =
    new EventEmitter<RegistrationContactModel>();

  @Output()
  changeStep: EventEmitter<ContactStepModel> =
    new EventEmitter<ContactStepModel>();
  //TODO: Type form controls
  public emailControl = new UntypedFormControl(null, {
    validators: [Validators.required],
  });

  phoneControl = new UntypedFormControl(null);

  public contactForm: UntypedFormGroup = null as unknown as UntypedFormGroup;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly registrationService: RegistrationService,
    private readonly messagingService: MessagingService
  ) {}

  ngOnInit(): void {
    this.phoneControl.setValue(this.data.phone);
    this.phoneControl.setAsyncValidators([
      phoneValidator(this.registrationService, this.token),
    ]);
    this.emailControl.setValue(this.data.email);
    this.emailControl.setAsyncValidators([
      emailValidator(this.registrationService, this.token),
    ]);
    this.contactForm = this.formBuilder.group({
      phone: this.phoneControl,
      email: this.emailControl,
    });
  }

  updateData() {
    this.updateStepData.emit({
      phone: this.contactForm.controls.phone.value,
      email: this.contactForm.controls.email.value,
    });
  }

  previousStep() {
    this.changeStep.emit({
      data: {
        phone: this.phoneControl.value,
        email: '',
      },
      direction: Direction.BACK,
    });
  }

  nextStep() {
    this.changeStep.emit({
      data: {
        phone: this.phoneControl.value,
        email: this.emailControl.value,
      },
      direction: Direction.FORWARD,
    });
  }

  getErrorMessage(controlName: string): string {
    const control = this.contactForm.get(controlName);
    if (control?.errors) {
      return this.messagingService.getControlErrorMessage(control.errors);
    }
    return '';
  }
}

import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { PatientService } from '../services/patient/patient.service';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireResolverService  {
  constructor(private readonly patientService: PatientService) {}
  resolve(): Observable<any> {
    return this.patientService.getTherapy().pipe(
      switchMap(({ id }) => this.patientService.getQuestionnaire(id)),
      catchError((e) => of(false))
    );
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { HomeComponent } from 'app/modules/home/home.component';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PatientService } from '../services/patient/patient.service';

@Injectable({
  providedIn: 'root',
})
export class TherapyResolverService {
  constructor(private readonly patientService: PatientService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    // clear therapy cache each time before navigating home because the therapy might end in the meanwhile
    return this.patientService
      .getTherapy(route.routeConfig?.component !== HomeComponent)
      .pipe(catchError(() => of({ status: 'NOT_FOUND' })));
  }
}

import {Component, Input} from '@angular/core';

@Component({
    selector: 'mdnra-section-layout',
    templateUrl: './section-layout.component.html',
    styleUrls: ['./section-layout.component.scss']
})
export class SectionLayoutComponent {
    @Input()
    title = '';

    @Input()
    description = '';

    @Input()
    descriptionHTML = '';

    @Input()
    detailLink = '';

    @Input()
    detailLinkLabel = '';
}

import { Component, Input, OnInit } from '@angular/core';
import { MenuService } from 'app/shared/layout/menu/menu.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'mdnra-release-info',
  templateUrl: './release-info.component.html',
  styleUrls: ['./release-info.component.scss'],
})
export class ReleaseInfoComponent implements OnInit {
  @Input()
  isMenuHide: boolean = false;

  public version: string = environment.APP_VERSION;
  public isMenuVisible: boolean = false;

  constructor(private readonly menuService: MenuService) {}

  ngOnInit(): void {
    if (!this.isMenuHide) {
      this.menuService.isMenuVisibleSubject.subscribe((isVisible: boolean) => {
        this.isMenuVisible = isVisible;
      });
    }
  }
}

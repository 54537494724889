import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mdnra-goto-button',
  templateUrl: './goto-button.component.html',
  styleUrls: ['./goto-button.component.scss'],
})
export class GotoButtonComponent implements OnInit {
  @HostBinding('style.fontWeight') fontWeight: string = 'normal';

  @Input()
  name: string = '';

  @Input()
  link: string = '';

  @Input()
  weight: string = 'normal';

  constructor() {}

  ngOnInit(): void {
    this.fontWeight = this.weight;
  }
}

<mat-card class="carousel-item" [ngClass]="colorScheme" (click)="navigate()">
  <div [ngClass]="{ gradient: gradient }"></div>
  <div class="content" [ngClass]="{ 'with-gradient': gradient }">
    <img *ngIf="image; else iconBg" src="{{ image }}" />
    <div class="title">{{ title }}</div>
    <ng-template #iconBg>
      <div class="iconBackground"><i class="{{ iconBackground }}"></i></div>
    </ng-template>
    <div class="bottom-row">
      <div class="duration" *ngIf="durationTime">
        {{ "COMMON.MINUTES" | translate : { x: durationTime } }}
      </div>
      <div class="icon"><i class="far {{ icon }}"></i></div>
    </div>
  </div>
</mat-card>


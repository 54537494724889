import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { MessagingService } from 'app/core/services/messaging/messaging.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ResetBirthdayValidationModel } from './reset-birthday/reset-birthday-validation.model';
import { ResetPasswordValidationModel } from './reset-password/reset-password-validation.model';

type Step = 'birthdayStep' | 'passwordStep';

@Component({
  selector: 'mdnra-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss'],
})
export class ResetComponent implements OnInit {
  private step: BehaviorSubject<Step> = new BehaviorSubject<Step>(
    'birthdayStep'
  );
  public currentStep: Observable<Step> = this.step.asObservable();

  public resetData = {
    token: '',
    password: '',
    confirmPassword: '',
    birthday: '',
  };

  public errors: { [key: string]: string[] } = {};

  private stepNames: Step[] = ['birthdayStep', 'passwordStep'];

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly messagingService: MessagingService,
    private readonly translateService: TranslateService,
    private readonly authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.resetData.token = params.token;
    });
  }

  saveBirthdayStep(event: ResetBirthdayValidationModel) {
    this.resetData.birthday = event.birthday;
    this.step.next(this.stepNames[1]);
  }

  savePassStep(event: ResetPasswordValidationModel) {
    this.resetData.password = event.password;
    this.resetData.confirmPassword = event.confirmPassword;
    this.reset();
  }

  reset(): void {
    this.authenticationService
      .reset(
        this.resetData.token,
        this.resetData.birthday,
        this.resetData.password,
        this.resetData.confirmPassword
      )
      .subscribe({
        next: () => {
          this.router.navigate(['../login']);
          this.messagingService.pushInfo(
            this.translateService.instant('PASSWORD_RESET.SUCCESS')
          );
        },
        error: (error) => {
          this.errors = error.error?.fields;
        },
      });
  }
}

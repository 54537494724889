<div
  *ngIf="!openCompleteView; else completeView"
  class="d-flex flex-column h-100"
>
  <div mat-dialog-title>
    <button
      mat-button
      class="ps-0 fs-6 mb-2"
      color="primary"
      [mat-dialog-close]="false"
    >
      <mat-icon fontSet="fas" fontIcon="fa-long-arrow-left"></mat-icon>
      <span>
        {{ "COMMON.BACK" | translate }}
      </span>
    </button>
    <h2>{{ "HOME.ENTER_STEPS.DIALOG.TITLE" | translate }}</h2>
  </div>

  <div mat-dialog-content class="mh-100">
    <form id="steps" [formGroup]="stepsForm" (ngSubmit)="submit()">
      <p>
        {{ "HOME.ENTER_STEPS.DIALOG.DESCRIPTION" | translate }}
      </p>

      <p>
        {{ "HOME.ENTER_STEPS.DIALOG.QUESTION" | translate }}
      </p>
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>{{
          "HOME.ENTER_STEPS.DIALOG.STEPS_LABEL" | translate
        }}</mat-label>
        <input matInput formControlName="steps" autocomplete="off" />
        <mat-error *ngIf="stepsControl.invalid">{{
          "HOME.ENTER_STEPS.ERRORS.PATTERN" | translate
        }}</mat-error>
      </mat-form-field>

      <p>
        {{ "HOME.ENTER_STEPS.DIALOG.MINUTES_DESCRIPTION" | translate }}
      </p>
      <mat-form-field appearance="fill" subscriptSizing="dynamic" class="w-100">
        <mat-label>{{
          "HOME.ENTER_STEPS.DIALOG.MINUTES_LABEL" | translate
        }}</mat-label>
        <input matInput formControlName="minutes" autocomplete="off" />
        <mat-error *ngIf="minutesControl.invalid">{{
          "HOME.ENTER_STEPS.ERRORS.PATTERN" | translate
        }}</mat-error>
      </mat-form-field>
    </form>
  </div>

  <div
    mat-dialog-actions
    class="justify-content-between align-items-center p-4"
  >
    <div>
      <button
        mat-stroked-button
        [mat-dialog-close]="false"
        type="button"
        class="mdnra-button"
      >
        {{ "HOME.ENTER_STEPS.DIALOG.CANCEL_BUTTON" | translate }}
      </button>
    </div>
    <button
      mat-flat-button
      color="primary"
      class="mdnra-button"
      type="submit"
      form="steps"
      [disabled]="stepsForm.invalid || submittingSteps"
    >
      {{ "HOME.ENTER_STEPS.DIALOG.SAVE_BUTTON" | translate }}
    </button>
  </div>
  <mdnra-release-info [isMenuHide]="true"></mdnra-release-info>
</div>

<ng-template #completeView>
  <mdnra-complete-view
    [title]="
      maxStepsTherapyWeekReached
        ? 'HOME.ENTER_STEPS.STEPS_REACHED.TITLE'
        : 'HOME.ENTER_STEPS.MORE_LEAVES.TITLE'
    "
    [description]="
      maxStepsTherapyWeekReached
        ? 'HOME.ENTER_STEPS.STEPS_REACHED.DESCRIPTION'
        : 'HOME.ENTER_STEPS.MORE_LEAVES.DESCRIPTION'
    "
    [receivedPoints]="responseData!.leavesDelta"
    [totalPoints]="responseData!.leaves"
    (closePressed)="closeDialog()"
  >
  </mdnra-complete-view>
</ng-template>

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { skip } from 'rxjs/operators';

@Injectable()
export class MessagingService {
  #error = new BehaviorSubject<string | null>(null);
  #warning = new BehaviorSubject<string | null>(null);
  #info = new BehaviorSubject<string | null>(null);

  error$ = this.#error.pipe(skip(1));
  warning$ = this.#warning.pipe(skip(1));
  info$ = this.#info.pipe(skip(1));

  constructor(private readonly translateService: TranslateService) {}

  pushServerError(httpErrorResp: HttpErrorResponse): void {
    if (httpErrorResp.status === 504 || httpErrorResp.status === 0) {
      this.translateService.get('COMMON.NO_INTERNET').subscribe((e) => {
        this.#error.next(e);
      });
      return;
    }

    if (httpErrorResp.status === 403) {
      this.translateService.get('COMMON.FORBIDDEN').subscribe((e) => {
        this.#error.next(e);
      });
      return;
    }

    // handle error from server using own translations
    if (httpErrorResp.error?.fields) {
      const fields: [][] = Object.values(httpErrorResp.error.fields);
      for (const errors of fields) {
        errors.forEach((errCode: string) => {
          this.pushError(this.translateService.instant(`ERRORS.${errCode}`));
        });
      }
    }

    if (httpErrorResp.status <= 500 && httpErrorResp.status > 399) {
      return;
    }
    this.translateService.get(httpErrorResp.statusText).subscribe((e) => {
      this.#error.next(e);
    });
  }

  pushError(message: string): any {
    this.#error.next(message);
  }

  pushWarning(message: string): any {
    this.#warning.next(message);
  }

  pushInfo(message: string): any {
    this.#info.next(message);
  }

  getControlErrorMessage(controlErrors: ValidationErrors): string {
    const numberOfErrors = Object.keys(controlErrors).length;

    if (numberOfErrors === 0) {
      return '';
    }
    switch (Object.keys(controlErrors)[numberOfErrors - 1]) {
      case 'required':
        return this.translateService.instant('ERRORS.GENERAL_REQUIRED');
      case 'email':
        return this.translateService.instant(
          'ERRORS.GENERAL_USERNAME_INVALID_FORMAT'
        );
      case 'matDatepickerParse':
      case 'birthdayPattern':
      case 'birthdayRequired':
        return this.translateService.instant(
          'ERRORS.GENERAL_BIRTHDAY_INVALID_FORMAT'
        );
      default:
        return this.translateService.instant(
          `ERRORS.${Object.keys(controlErrors)[
            numberOfErrors - 1
          ].toLocaleUpperCase()}`
        );
    }
  }
}

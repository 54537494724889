import { Component, Input } from '@angular/core';

@Component({
  selector: 'mdnra-home-tree',
  templateUrl: './home-tree.component.html',
  styleUrls: ['./home-tree.component.scss'],
})
export class HomeTreeComponent {
  @Input()
  leaves: number = 0;

  @Input()
  level: number = 1;
}

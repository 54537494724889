import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'app/core/services/authentication/authentication.service';
import { MessagingService } from 'app/core/services/messaging/messaging.service';
import { ShareOnOtherDeviceService } from 'app/modules/more/share-on-other-device/share-on-other-device.service';
import { SessionStorageService } from 'app/shared/services/session-storage.service';

export const IS_SHARING_DEVICE_KEY: string = 'isSharingDevice';
export const OPT_REDIRECT_URL_KEY: string = 'optRedirectUrl';

@Component({
  selector: 'mdnra-share-auth',
  templateUrl: './share-auth.component.html',
})
export class ShareAuthComponent implements OnInit {
  private redirectUrl: string = '/login';
  private homeUrl: string = '/home';

  constructor(
    private readonly shareService: ShareOnOtherDeviceService,
    private readonly authService: AuthenticationService,
    private readonly sessionStorage: SessionStorageService,
    private readonly messagingService: MessagingService,
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((data) => {
      this.shareService.shareAuth(data.otpCode, data.secret).subscribe(
        (resp) => {
          this.sessionStorage.setItem(IS_SHARING_DEVICE_KEY, true);
          this.authService.authByToken(resp.token, this.homeUrl);
        },
        (error) => {
          this.messagingService.pushServerError(error);
          this.router.navigateByUrl(this.redirectUrl);
        }
      );
    });
  }
}
